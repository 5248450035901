import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  QueryList,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  FormGroupName,
  NgControl,
  UntypedFormGroup,
} from '@angular/forms';
import { assertTrue } from '@nexuzhealth/shared/util';
import { ValidationErrorsComponent } from 'ngx-valdemort';
import { BehaviorSubject, Subject } from 'rxjs';
import { startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { disabledChanges } from '../../../shared/form-helper.utils';

const CHILD_ERROR = 'input-group-child-error';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupComponent implements OnChanges, AfterContentInit, OnDestroy {
  @Input() label: string;
  @Input() required = false;
  @Input() noGap = false;
  @Input() hideCatchAllErrorMessage = true;
  @Input() direction: 'row' | 'column' = 'column';

  // Using @ContentChildren iso @ContentChild because atm @ContentChild also searches inside nested components.
  // This will be fixed in angular 14 - https://github.com/angular/angular/pull/46638
  @ContentChildren(ValidationErrorsComponent, { descendants: false })
  public errorsComponent: QueryList<ValidationErrorsComponent>;
  @ContentChildren(FormControlName, { descendants: true }) private childFormControlNames: QueryList<FormControlName>;
  @ContentChildren(FormControlDirective, { descendants: true })
  private childFormControlDirectives: QueryList<FormControlDirective>;

  disabled$$ = new BehaviorSubject(false);
  private destroy$$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Optional() public formGroupName: FormGroupName,
    @Optional() public formGroupDirective: FormGroupDirective
  ) {
    assertTrue(
      formGroupName || formGroupDirective,
      'nh-group cannot be used without formGroupName or [formGroup] directive'
    );
  }

  @HostBinding('class.has-group-specific-error') get hasGroupSpecificError() {
    return !!this.formGroup.errors && !this.formGroup.hasError(CHILD_ERROR);
  }

  get formGroup(): UntypedFormGroup {
    return this.formGroupName?.control ?? this.formGroupDirective.control;
  }

  // todo investigate, this was set for storybook - otherwise we get the following error: "Cannot set property
  //  formGroup of [object Object] which has only a getter"
  set formGroup(group: UntypedFormGroup) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label && !changes.label.isFirstChange()) {
      if (this.errorsComponent.length > 0) {
        this.errorsComponent.first.label = this.label;
      }
    }
  }

  ngAfterContentInit(): void {
    if (this.errorsComponent.length > 0) {
      if (!this.errorsComponent.first.label) {
        this.errorsComponent.first.label = this.label;
      }
      if (!this.hideCatchAllErrorMessage) {
        this.errorsComponent.first.control = this.formGroup;
      }
    }

    // this is necessary in case the ControlComponent was used in a component with OnPush ChangeDetection and its
    // status is changed asynchronously, e.g. through async validation
    this.formGroup.statusChanges.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      this.cdr.markForCheck();
    });

    disabledChanges(this.formGroup)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((disabled) => this.disabled$$.next(disabled));

    this.setupCatchAllErrorMessage();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  private setupCatchAllErrorMessage() {
    if (this.hideCatchAllErrorMessage) {
      return;
    }

    const invalidChildControls$$ = new BehaviorSubject([]);
    const childControlTouched$$ = new Subject<NgControl>();

    childControlTouched$$
      .pipe(withLatestFrom(invalidChildControls$$), takeUntil(this.destroy$$))
      .subscribe(([childControl, invalidChildControls]) => {
        if (childControl.invalid && invalidChildControls.indexOf(childControl.name) < 0) {
          invalidChildControls$$.next([...invalidChildControls, childControl.name]);
        }
      });

    const childControls: Array<NgControl> = [...this.childFormControlNames, ...this.childFormControlDirectives];
    childControls.forEach((childControl) => {
      // Unfortunately FormControl does not have a "touched" event or observable, so in order to
      // react to touched events, we have to piggy-back FormControl's markAsTouched
      const original = childControl.control.markAsTouched;
      childControl.control.markAsTouched = () => {
        childControlTouched$$.next(childControl);
        original.apply(childControl.control);
      };

      childControl.statusChanges
        .pipe(startWith(childControl.status), withLatestFrom(invalidChildControls$$), takeUntil(this.destroy$$))
        .subscribe(([, invalidChildControls]) => {
          // remove from invalid controls if control becomes valid
          if (childControl.valid) {
            const index = invalidChildControls.indexOf(childControl.name);
            if (index > -1) {
              const copy = [...invalidChildControls];
              copy.splice(index, 1);
              invalidChildControls$$.next(copy);
            }
            // add to invalid controls but only if it already has been touched (we don't want to show the generic error
            // message when the control hasn't been touched yet
          } else if (childControl.touched) {
            const index = invalidChildControls.indexOf(childControl.name);
            if (index < 0) {
              invalidChildControls$$.next([...invalidChildControls, childControl.name]);
            }
          }
        });
    });

    invalidChildControls$$.pipe(takeUntil(this.destroy$$)).subscribe((invalidChildControls) => {
      const nbrOfInvalidChildControls = invalidChildControls.length;
      const hasGroupSpecificErrors = !!this.formGroup.errors;

      if (nbrOfInvalidChildControls > 0 && !this.formGroup.hasError(CHILD_ERROR) && !hasGroupSpecificErrors) {
        // show generic error message if there exist child control errors, and there is no error on the parent level
        this.formGroup.setErrors({ [CHILD_ERROR]: true });
      } else if (nbrOfInvalidChildControls === 0 && this.formGroup.hasError(CHILD_ERROR)) {
        // remove generic error message when there does not exist child control errors
        const errors = { ...this.formGroup.errors };
        delete errors[CHILD_ERROR];
        this.formGroup.setErrors(errors);
      }
    });

    // possibly when the specific error is fixed, there is a child error to be shown
    this.formGroup.statusChanges
      .pipe(withLatestFrom(invalidChildControls$$), takeUntil(this.destroy$$))
      .subscribe(([, invalidChildControls]) => {
        const nbrOfInvalidChildControls = invalidChildControls.length;
        const hasGroupSpecificErrors = !!this.formGroup.errors;
        // show generic error message if there exist child control errors, and there is no error on the parent level
        if (!hasGroupSpecificErrors && nbrOfInvalidChildControls > 0) {
          this.formGroup.setErrors({ [CHILD_ERROR]: true });
        }
      });
  }
}
