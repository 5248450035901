import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Theme } from './theme';
import { AbstractThemeResolver } from './abstract-theme.resolver';
import { ThemeStore } from './theme.store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedThemeResolver extends AbstractThemeResolver {
  private readonly baseUrl = 'api/app/branding/v1';

  constructor(
    private http: HttpClient,
    protected override settings: SettingsService,
    protected override themeStore: ThemeStore
  ) {
    super(themeStore, settings);
  }

  getTheme(): Observable<Theme> {
    return this.http.get<{ branding: Theme }>(`${this.baseUrl}/applicable`).pipe(pluck('branding'));
  }
}
