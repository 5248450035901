import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectTemplatesComponent } from './ng-select-templates.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-select.ng-select--checkbox',
})
export class CustomizeNgSelectCheckboxesDirective implements AfterViewInit {
  private static componentRef: ComponentRef<NgSelectTemplatesComponent>;

  constructor(
    @Self() private ngSelect: NgSelectComponent,
    private resolver: ComponentFactoryResolver,
    private _vcr: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    const componentRef = this.getComponentRef();

    const ref = componentRef.instance.checkboxesRef;
    this.ngSelect.optionTemplate = ref;
  }

  private getComponentRef() {
    if (!CustomizeNgSelectCheckboxesDirective.componentRef) {
      const factory = this.resolver.resolveComponentFactory(NgSelectTemplatesComponent);
      CustomizeNgSelectCheckboxesDirective.componentRef = this._vcr.createComponent(factory);
    }

    return CustomizeNgSelectCheckboxesDirective.componentRef;
  }
}
