import { Component, Input, OnInit } from '@angular/core';
import { BlobService } from '@nexuzhealth/shared/tech/data-access-blob';
import { EidDetailsView } from '@nexuzhealth/shared/eid/data-access';
import { AssignedIdTypenameEnum } from '@nexuzhealth/shared/domain';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nxh-eid-detail',
  templateUrl: './eid-detail.component.html',
  styleUrls: ['./eid-detail.component.scss'],
})
export class EidDetailComponent implements OnInit {
  BE_INSS = AssignedIdTypenameEnum.BE_INSS;
  eidPicture$ = new Subject<string>();
  eidPictureLoading$ = new BehaviorSubject<boolean>(true);
  @Input() view: EidDetailsView;
  constructor(private blobService: BlobService) {}

  get gender() {
    return this.view?.userInfo?.gender?.name || this.view?.userInfo?.gender?.translationKey;
  }

  ngOnInit(): void {
    this.blobService
      .getBlob(this.view.userInfo.picture, false)
      .pipe(filter((pic) => !!pic))
      .subscribe({
        next: (blob) => {
          if (blob == null) {
            return;
          }
          const reader = new FileReader();
          reader.readAsDataURL(blob.body);
          reader.onload = () => {
            this.eidPictureLoading$.next(false);
            this.eidPicture$.next(reader.result as string);
          };
          reader.onerror = () => {
            this.eidPictureLoading$.next(false);
          };
          reader.onabort = () => {
            this.eidPictureLoading$.next(false);
          };
        },
        error: () => {
          this.eidPictureLoading$.next(false);
        },
      });
  }
}
