import { Pipe, PipeTransform } from '@angular/core';
import { Address, AddressUse, findAddressByUse } from '@nexuzhealth/shared/domain';

@Pipe({
  name: 'findAddress',
  pure: false,
})
export class FindAddressPipe implements PipeTransform {
  transform(addresses: Address[], use: AddressUse): Address | undefined {
    return findAddressByUse(addresses, use);
  }
}
