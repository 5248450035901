import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-checkbox-option',
  templateUrl: './checkbox-option.component.html',
  styleUrls: ['./checkbox-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxOptionComponent {
  @Input() label: string;
  @Input() search: string;
  @Input() checked = false;

  get id() {
    return `item-${Math.random()}`;
  }

  constructor() {}
}
