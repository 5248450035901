import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nxh-alert-description',
  templateUrl: './alert-description.component.html',
  styleUrls: ['./alert-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AlertDescriptionComponent {
  @Input() description?: string;
}
