import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReferenceTypeState } from '../shared/reference-types';
import { MediumStore } from './medium.store';

@Injectable({
  providedIn: 'root',
})
export class MediumQuery extends QueryEntity<ReferenceTypeState> {
  constructor(protected store: MediumStore) {
    super(store);
  }
}
