import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, TemplateRef } from '@angular/core';

export const TYPEAHEAD_CONFIG = new InjectionToken<TypeaheadConfig>('TypeaheadConfig');

export interface TypeaheadConfig {
  optionTemplateRef?: TemplateRef<any>;
  optionComponentRef?: ComponentType<any>;
  customTemplateRef?: TemplateRef<any>;
  noResultTemplateRef?: TemplateRef<any>;
  focusTemplateRef?: TemplateRef<any>;
  showNoResultMessage?: boolean;
  scrollable?: boolean;
}
