import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isActiveCertificate, MyCurrentCertificates } from '@nexuzhealth/shared/ehealth/domain';
import { CertificateState, CertificateStore } from './certificate.store';

@Injectable({ providedIn: 'root' })
export class CertificateQuery extends Query<CertificateState> {
  myActiveCertificates$: Observable<MyCurrentCertificates> = this.select().pipe(
    map((state) => {
      return {
        user: isActiveCertificate(state.user) ? state.user : null,
        organisation: isActiveCertificate(state.organisation) ? state.organisation : null,
      };
    })
  );
  myCurrentCertificates$: Observable<MyCurrentCertificates> = this.select().pipe(
    map((state) => ({
      user: state.user,
      organisation: state.organisation,
    }))
  );

  constructor(protected store: CertificateStore) {
    super(store);
  }
}
