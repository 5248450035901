import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  @Input() address: any;

  constructor() {}
}
