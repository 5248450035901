import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import {
  POPOVER_CONTENT,
  POPOVER_DATA,
  PopoverComponent,
  PopoverContent,
  PopoverRef,
} from '@nexuzhealth/shared/ui-toolkit/popover';
import { BubbleData } from '../detail-bubble.service';
import { resizeObservable } from './resizeObservable';

@Component({
  // no selector - this component is only create programmatically via DetailPopoverService
  templateUrl: './detail-bubble.component.html',
  styleUrls: ['./detail-bubble.component.scss'],
})
export class DetailBubbleComponent<T> implements OnInit, AfterViewInit, OnDestroy, PopoverComponent {
  @ViewChild('card') card: ElementRef<HTMLElement>;
  @ViewChild('header') header: ElementRef<HTMLElement>;
  @ViewChild('body') body: ElementRef<HTMLElement>;
  @ViewChild('arrowUp') arrowUp: ElementRef<HTMLElement>;
  @ViewChild('arrowRight') arrowRight: ElementRef<HTMLElement>;
  @ViewChild('arrowDown') arrowDown: ElementRef<HTMLElement>;
  @ViewChild('arrowLeft') arrowLeft: ElementRef<HTMLElement>;
  destroy$ = new Subject<void>();

  renderMethod: 'template' | 'component' | 'text' = 'component';

  popoverBoundingBoxMargins = { x: 32, y: 16 };
  context;
  titleRef: TemplateRef<any>;
  classnames!: string[];

  get popoverBoundingBox() {
    return this.card.nativeElement.offsetParent as HTMLElement;
  }

  @HostListener('mouseenter', ['$event.target'])
  onMouseenter(origin) {
    this.bubbleRef?.onMouseEnter(origin);
  }

  @HostListener('mouseleave')
  onMouseleave() {
    this.bubbleRef?.onMouseLeave();
  }

  constructor(
    private bubbleRef: PopoverRef,
    private renderer: Renderer2,
    @Inject(POPOVER_CONTENT) public content: PopoverContent,
    @Inject(POPOVER_DATA) public bubbleData: BubbleData,
    public injector: Injector,
    private i18n: I18NextPipe
  ) {
    const title = bubbleData.title;
    if (isTemplateRef(title)) {
      this.titleRef = title;
    }
  }

  ngOnInit(): void {
    this.classnames = getClassnames(this.bubbleData);

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      this.context = {
        close: this.bubbleRef.close.bind(this.bubbleRef),
      };
    }
  }

  ngAfterViewInit(): void {
    // assumption: window size and the popover bounding box (once defined) are static
    // => only monitor card size changes e.g. when loading async data
    const resizeObserver = resizeObservable(this.card.nativeElement).pipe(
      filter(() => this.popoverBoundingBox != null)
    );

    resizeObserver.pipe(first()).subscribe(() => this.setMaxHeight());
    resizeObserver.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateArrowOffsetFromCenter());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateArrowOffsetFromCenter(): void {
    // max 1 arrow is shown at all times
    // the location of hidden arrows does not matter, even if positioned badly
    // => we can disregard which arrow is active if we always update all in relation to the origin

    const originRect = this.bubbleData.origin.getBoundingClientRect();
    const cardRect = this.card.nativeElement.getBoundingClientRect();
    const boundingRect = this.popoverBoundingBox.getBoundingClientRect();

    const originCenterX = originRect.x + originRect.width / 2;
    const boundingStartX = originCenterX - boundingRect.width / 2 - boundingRect.left;
    const arrowMarginX = boundingStartX + cardRect.width / 2 + this.popoverBoundingBoxMargins.x / 2;

    this.renderer.setStyle(this.arrowUp.nativeElement, 'left', arrowMarginX + 'px');
    this.renderer.setStyle(this.arrowDown.nativeElement, 'left', arrowMarginX + 'px');

    const originCenterY = originRect.y + originRect.height / 2;
    const boundingStartY = originCenterY - boundingRect.height / 2 - boundingRect.top;
    const arrowMarginY = boundingStartY + cardRect.height / 2 - this.popoverBoundingBoxMargins.y / 2;

    this.renderer.setStyle(this.arrowRight.nativeElement, 'top', arrowMarginY + 'px');
    this.renderer.setStyle(this.arrowLeft.nativeElement, 'top', arrowMarginY + 'px');
  }

  setMaxHeight() {
    const headerRect = this.header.nativeElement.getBoundingClientRect();
    const boundingRect = this.popoverBoundingBox.getBoundingClientRect();

    const maxHeight = boundingRect.height - headerRect.height - this.popoverBoundingBoxMargins.y;
    this.renderer.setStyle(this.body.nativeElement, 'max-height', maxHeight + 'px');
  }

  close() {
    this.bubbleRef.close();
  }

  doAction(action) {
    if (action.disabled?.value) {
      return;
    }

    if (action.callback) {
      action.callback();
    } else {
      this.bubbleRef.close(action.actionName);
    }
  }

  doActionAndWait(action) {
    if (action.disabled?.value) {
      return;
    }
    this.bubbleRef.sendAction(action.actionName);
  }

  getToolTip(action) {
    return action.toolTip ?? this.i18n.transform(action.actionName);
  }
}

function getClassnames(bubbleData: BubbleData) {
  const classnames = [];
  const containerClass = bubbleData.containerClass;
  if (containerClass) {
    if (Array.isArray(containerClass)) {
      classnames.push(...containerClass);
    } else {
      classnames.push(containerClass);
    }
  }
  classnames.push(bubbleData.size || 'sm');
  return classnames;
}

function isTemplateRef(title: any): title is TemplateRef<any> {
  return title && typeof title !== 'string';
}
