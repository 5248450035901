import { LoadingSpinnerModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/loading-spinner';
import { AvatarModule } from '@nexuzhealth/shared/ui-toolkit/avatar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadModule } from '@nexuzhealth/shared/ui-toolkit/typeahead';
import { BoldPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/bold';
import { FormatPersonNamePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-person-name';
import { FormatAssignedIdPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-assigned-id';
import { BoldNumberPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/bold-number';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { NavItemPatientSearchComponent } from './components/nav-item-patient-search/nav-item-patient-search.component';

@NgModule({
  imports: [
    LoadingSpinnerModule,
    AvatarModule,
    CommonModule,
    IconsModule,
    SharedUtilI18nModule,
    NgbTooltipModule,
    TypeaheadModule,
    BoldPipeModule,
    FormatPersonNamePipeModule,
    FormatAssignedIdPipeModule,
    BoldNumberPipeModule,
  ],
  declarations: [NavItemPatientSearchComponent],
  exports: [NavItemPatientSearchComponent],
})
export class SharedPatientFeatureNavItemsModule {}
