import { AssignedId, AssignedIdTypenameEnum, OrganisationIdentifierTypenameEnum } from '@nexuzhealth/shared/domain';

export function formatAssignedId(
  assignedId?: AssignedId | string,
  type?: AssignedIdTypenameEnum | OrganisationIdentifierTypenameEnum
) {
  let value = '';
  if (typeof assignedId === 'undefined' || assignedId === null) {
    return '';
  } else if (typeof assignedId === 'string') {
    if (!type) return assignedId;
    value = assignedId;
  } else {
    type = assignedId.typeName;
    value = assignedId.value;
  }

  if (value === '') {
    return '';
  }

  switch (type) {
    case AssignedIdTypenameEnum.BE_INSS:
      return formatINSS(value);
    case AssignedIdTypenameEnum.BE_RIZIV:
      return formatRIZIV(value);
    case AssignedIdTypenameEnum.BE_NIHII_ORG:
      return formatRIZIV(value);
    case AssignedIdTypenameEnum.BE_EID:
      return formatEID(value);
    case AssignedIdTypenameEnum.BE_ISI:
      return formatISI(value);
    case AssignedIdTypenameEnum.BE_EHP:
      return formatBE_EHP(value);
    case AssignedIdTypenameEnum.BE_CBE:
      return formatBE_CBE(value);
    default:
      console.warn(`Cannot format assigned id of type ${type}`);
      return '';
  }
}

function formatINSS(inss: string) {
  return (
    inss.substring(0, 2) +
    '.' +
    inss.substring(2, 4) +
    '.' +
    inss.substring(4, 6) +
    '-' +
    inss.substring(6, 9) +
    '.' +
    inss.substring(9, 13)
  );
}

function formatRIZIV(riziv: string) {
  switch (riziv.length) {
    case 0:
      return '-';
    case 8: {
      const parts = [riziv.substring(0, 1), riziv.substring(1, 6), riziv.substring(6)];
      return parts.join('-');
    }
    case 11: {
      const parts = [riziv.substring(0, 1), riziv.substring(1, 6), riziv.substring(6, 8), riziv.substring(8)];
      return parts.join('-');
    }
    default:
      console.warn('Invalid riziv number ' + riziv);
      return riziv;
  }
}

function formatBE_EHP(beEHP: string) {
  return beEHP;
}

function formatBE_CBE(beCBE: string) {
  if (beCBE.length !== 10) {
    return beCBE;
  }
  const parts = [beCBE.substring(0, 4), beCBE.substring(4, 7), beCBE.substring(7)];
  return parts.join('.');
}

function formatEID(eid: string) {
  return eid;
}

function formatISI(isi: string) {
  return isi;
}
