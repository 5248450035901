import { AvatarModule } from '@nexuzhealth/shared/ui-toolkit/avatar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedSettingsFeatureFlagsUiModule } from '@nexuzhealth/shared/settings/feature-flags/ui';
import { BreadcrumbModule } from '@nexuzhealth/shared/ui-toolkit/breadcrumb';
import { NxhFormsModule } from '@nexuzhealth/shared/ui-toolkit/forms';
import { SharedUtilI18nextArrayByKeyPipeModule, SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedAuthenticationFeatureAuthorizationModule } from '@nexuzhealth/shared/authentication/feature-authorization';
import { SharedAuthenticationUiFormatRolesPipeModule } from '@nexuzhealth/shared/authentication/ui/format-roles-pipe';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FocusModule } from '@nexuzhealth/shared/ui-toolkit/focus';
import { FormatPersonNamePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-person-name';
import { InputModule } from '@nexuzhealth/shared/ui-toolkit/input';
import { PopoverModule } from '@nexuzhealth/shared/ui-toolkit/popover';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { SharedSettingsDataAccessSettingsModule } from '@nexuzhealth/shared/settings/data-access-settings';
import { CookieBarComponent } from './components/cookie-bar/cookie-bar.component';
import { NavItemExternalLinksComponent } from './components/nav/nav-item-external-links/nav-item-external-links.component';
import { NavItemHomeComponent } from './components/nav/nav-item-home/nav-item-home.component';
import { NavItemSimpleComponent } from './components/nav/nav-item-simple/nav-item-simple.component';
import { NavItemSupportBubbleComponent } from './components/nav/nav-item-support-bubble/nav-item-support-bubble.component';
import { NavItemUserComponent } from './components/nav/nav-item-user/nav-item-user.component';
import { NavbarComponent } from './components/nav/navbar/navbar.component';
import { ThemePickerComponent } from './components/theme-picker/theme-picker.component';
import { FatalErrorPageComponent } from './components/fatal-error-page/fatal-error-page.component';

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    RouterModule,
    SharedUtilI18nModule,
    IconsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbCollapseModule,
    BreadcrumbModule,
    NxhFormsModule,
    SharedUtilI18nextArrayByKeyPipeModule,
    SharedSettingsFeatureFlagsUiModule,
    SharedAuthenticationFeatureAuthorizationModule,
    InputModule,
    SharedAuthenticationUiFormatRolesPipeModule,
    SharedTechFeatureE2eModule,
    SharedSettingsDataAccessSettingsModule,
    FocusModule,
    FormatPersonNamePipeModule,
    PopoverModule,
    ScrollingModule,
    ButtonModule,
  ],
  declarations: [
    CookieBarComponent,
    NavbarComponent,
    NavItemExternalLinksComponent,
    NavItemHomeComponent,
    NavItemSimpleComponent,
    NavItemSupportBubbleComponent,
    NavItemUserComponent,
    ThemePickerComponent,
    FatalErrorPageComponent,
  ],
  exports: [
    NavbarComponent,
    NavItemSimpleComponent,
    NavItemExternalLinksComponent,
    NavItemUserComponent,
    NavItemSupportBubbleComponent,
    CookieBarComponent,
  ],
  providers: [NgbTooltipConfig],
})
export class SharedToolkitFeatureShellModule {}
