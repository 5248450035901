import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuffixDirective } from './suffix.directive';
import { PrefixDirective } from './prefix.directive';
import { InputComponent } from './input.component';

@NgModule({
  declarations: [InputComponent, SuffixDirective, PrefixDirective],
  imports: [CommonModule],
  exports: [InputComponent, SuffixDirective, PrefixDirective],
})
export class InputModule {}
