import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'nxh-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundComponent {
  @Input() title;
  @Input() description;
  @Input() reasons: string[] = [];
  @Input() icon: IconProp;
}
