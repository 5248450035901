<ng-container *ngFor="let certificate of certificate$ | async">
  <div *ngIf="certificate" class="certificate-bar" [ngClass]="certificate.status">
    <div class="message">
      <fa-icon icon="exclamation-circle"></fa-icon>
      <div class="message-title">
        {{
          certificate.certificateStatusMessage.title
            | i18next
              : {
                  date: (certificate.certificate.notAfter | nxhDate),
                  interpolation: { escapeValue: false }
                }
        }}
      </div>
      <div>{{ certificate.certificateStatusMessage.body | i18next }}</div>
    </div>
    <div>
      <button class="btn remind-me-button" type="button" (click)="remindMeLater(certificate)">
        {{ '_ehealth._certificate.remind-me-later' | i18next }}
      </button>
      <a class="btn more-info-button" type="button" [href]="certificate.moreInfoLink" [target]="'_blank'">
        {{ '_ehealth._certificate.more-info' | i18next }}
      </a>
    </div>
  </div>
</ng-container>
