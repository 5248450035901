import { Directive, ElementRef, HostBinding, inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: '[nxhSticker]', standalone: true })
export class StickerDirective implements OnInit {
  @HostBinding('class.sticker') get sticker() {
    return this.stickTopLeft || this.stickTopRight || this.stickBottomRight || this.stickBottomLeft;
  }
  @HostBinding('class.sticker--top-left') @Input() stickTopLeft = false;
  @HostBinding('class.sticker--top-right') @Input() stickTopRight = false;
  @HostBinding('class.sticker--bottom-right') @Input() stickBottomRight = false;
  @HostBinding('class.sticker--bottom-left') @Input() stickBottomLeft = false;

  private readonly host = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  ngOnInit(): void {
    if (!this.sticker) {
      return;
    }

    this.renderer.setStyle(this.host.nativeElement, 'position', 'absolute');

    if (this.stickTopLeft) {
      this.renderer.setStyle(this.host.nativeElement, 'top', 0);
      this.renderer.setStyle(this.host.nativeElement, 'left', 0);
    } else if (this.stickTopRight) {
      this.renderer.setStyle(this.host.nativeElement, 'top', 0);
      this.renderer.setStyle(this.host.nativeElement, 'right', 0);
    } else if (this.stickBottomRight) {
      this.renderer.setStyle(this.host.nativeElement, 'bottom', 0);
      this.renderer.setStyle(this.host.nativeElement, 'right', 0);
    } else if (this.stickBottomLeft) {
      this.renderer.setStyle(this.host.nativeElement, 'bottom', 0);
      this.renderer.setStyle(this.host.nativeElement, 'left', 0);
    }
    // additional styling done via host or host-context

    this.setStickerTargetIfInsideTable(this.host.nativeElement);
  }

  private setStickerTargetIfInsideTable(el: ElementRef) {
    const parentNode = this.renderer.parentNode(el);
    if (parentNode?.tagName === 'TD') {
      const tr = this.renderer.parentNode(parentNode);
      this.renderer.addClass(tr, 'sticker-target');
    }
  }
}
