import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';

export enum CustomIconSize {
  xs = '1rem',
  sm = '1.5rem',
  md = '2rem',
  lg = '2.5rem',
}

@Directive({ selector: '[nxhIconCustomSizing]' })
export class IconCustomSizingDirective {
  @Input('nxhIconCustomSizing') set size(value: CustomIconSize) {
    this.renderer.setStyle(this.hostElement.nativeElement, 'font-size', value);
  }

  private renderer = inject(Renderer2);
  private hostElement = inject(ElementRef);
}
