import { Injectable } from '@angular/core';

/**
 * Configuration for the PageLoaderComponent and TableLoaderComponent. Don't
 * expect full configuration (yet?) - the main reason for having this is that
 * some defaults in the tests are different from the defaults in the app (at
 * least until we find an easy solution for the "1 periodic timer(s) still in
 * the queue." problem).
 */
@Injectable({ providedIn: 'root' })
export class LoadingStatesConfig {
  warningTimeoutTime: number;
}
