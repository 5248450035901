import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Patient, ResourceName } from '@nexuzhealth/shared/domain';

export interface PatientState extends EntityState<Patient, ResourceName>, ActiveState<ResourceName> {
  pictures?: Record<string, string>;
  emergencySumehr: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient', idKey: 'name', resettable: true })
export class PatientStore extends EntityStore<PatientState> {
  constructor() {
    super({
      pictures: {},
      emergencySumehr: '',
    });
  }
}
