import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';

@Component({
  selector: 'nxh-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
  @Input() title: string;
  @Input() homeLogoSize: 'sm' | 'md' = 'sm';
  @Input() homeRouterLink: string[];
  @Input() hideBreadcrumb = false;
  patientsLink = this.settingsService.getComponentSettingValue('components.patient.link', '/patients');

  constructor(private settingsService: SettingsService) {}

  get isIOS() {
    return /iPad/.test(navigator.userAgent);
  }

  get isAndroid() {
    return /android/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
    if (this.isIOS || this.isAndroid) {
      document.body.classList.add('mobile-device');
    }
  }
}
