import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Injector,
  Input,
  OnChanges,
  Self,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { NgSelectTemplatesComponent } from './ng-select-templates.component';

/**
 * Directive to augment ng-select with custom templates. In future releases we might
 * opt to add templates for checkboxes. Also some of the templates might be moved to
 * AppComponent
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-select',
})
export class CustomizeNgSelectDefaultsDirective implements AfterViewInit, OnChanges {
  private componentRef: ComponentRef<NgSelectTemplatesComponent>;

  @Input() startSearchingAtLength = 2;

  constructor(
    @Self() private ngSelect: NgSelectComponent,
    private resolver: ComponentFactoryResolver,
    private _vcr: ViewContainerRef,
    private ngSelectConfig: NgSelectConfig,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.componentRef = this.getComponentRef();
    this.componentRef.instance.startSearchingAtLength = this.startSearchingAtLength;

    if (!this.ngSelect.notFoundTemplate) {
      if (this.ngSelect.typeahead) {
        this.ngSelect.notFoundTemplate = this.componentRef.instance.notFoundRef;
      } else {
        this.ngSelect.notFoundTemplate = this.componentRef.instance.noResultRef;
      }
    }

    if (
      !this.ngSelect.typeToSearchTemplate &&
      this.ngSelectConfig.typeToSearchText === this.ngSelect.typeToSearchText
    ) {
      this.ngSelect.typeToSearchTemplate = this.componentRef.instance.typeToSearchRef;
    }

    if (!this.ngSelect.optionTemplate) {
      this.ngSelect.optionTemplate = this.componentRef.instance.highlightSearchRef;
    }

    if (!this.ngSelect.tagTemplate) {
      this.ngSelect.tagTemplate = this.componentRef.instance.addTagRef;
    }

    if (!this.ngSelect.labelTemplate) {
      this.ngSelect.labelTemplate = this.componentRef.instance.labelRef;
    }

    if (!this.ngSelect.loadingTextTemplate) {
      this.ngSelect.loadingTextTemplate = this.componentRef.instance.loadingRef;
    }

    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.componentRef) {
      this.componentRef.instance.startSearchingAtLength = changes['startSearchingAtLength'].currentValue;
    }
  }

  private getComponentRef() {
    if (this.componentRef) return this.componentRef;
    const factory = this.resolver.resolveComponentFactory(NgSelectTemplatesComponent);
    return this._vcr.createComponent(factory, 0, this.injector);
  }
}
