<div class="eid-details" *ngIf="view">
  <aside>
    <nxh-avatar [fullName]="view.name" [pictureName]="view.userInfo.picture"></nxh-avatar>
  </aside>
  <div class="eid-details--content">
    <span class="eid-details--name">{{ view.name }}</span>
    <span class="eid-details--person-details"
      >{{ (view.userInfo.sub | formatAssignedId : BE_INSS) || ('_patient.no-insz' | i18next) }}
      <span class="separator">|</span> {{ view?.userInfo?.birthdate | nxhDate }} <span class="separator">|</span>
      <ng-container></ng-container>
      {{ gender | resolveAdministrativeGender | i18next }}</span
    >
    <span *ngIf="!!view.address">{{ view.address | formatAddress }}</span>
  </div>
</div>
