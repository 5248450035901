<div class="error-page">
  <div class="nh-page-wrapper">
    <div class="nh-page">
      <div class="nh-page__content nh-page__content--limited nh-page__content--centered">
        <div class="nh-page__content--body" [ngSwitch]="errorType$ | async">
          <div class="fatal-error-page__content" *ngSwitchCase="'invalid-licence'">
            <img src="/assets/theming-default/nxh-pro-logo.svg" />
            <h1 class="title">{{ '_licence-error-page.title' | i18next }}</h1>
            <p class="fatal-error__description">
              <a href="{{ productSelectorUrl }}">{{ '_licence-error-page.product-selector' | i18next }}</a>
            </p>
          </div>

          <div class="fatal-error-page__content" *ngSwitchDefault>
            <img src="/assets/theming-default/nxh-pro-logo.svg" />
            <h1 class="title">{{ '_fatal-error-page.title' | i18next }}</h1>
            <p class="fatal-error__description" [innerHtml]="'_fatal-error-page.description' | i18next"></p>
            <button nxh-button [outline]="true" (click)="reload()">
              <fa-icon [icon]="redo"></fa-icon>
              {{ '_fatal-error-page.cta' | i18next }}
            </button>
          </div>
          <div>
            <button nxh-button [buttonType]="'link'" (click)="logout()" *ngIf="auth.isAuthenticated$ | async">
              <fa-icon icon="sign-out"></fa-icon>
              <span>{{ 'sign-out' | i18next }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
