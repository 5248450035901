import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { EmptyComponent } from './empty/empty.component';
import { ErrorComponent } from './error/error.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingStatus } from './loading-states.model';

@Component({
  selector: 'nxh-loading-states',
  template: `
    <ng-container [ngSwitch]="loadingState">
      <ng-container *ngSwitchCase="'loading'">
        <ng-container [ngTemplateOutlet]="loadingTemplate" *ngIf="loadingTemplate; else defaultLoading" />
        <ng-template #defaultLoading>
          <nxh-loading />
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'empty'">
        <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="emptyTemplate; else defaultEmpty" />
        <ng-template #defaultEmpty>
          <nxh-empty />
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'error'">
        <ng-container [ngTemplateOutlet]="errorTemplate" *ngIf="errorTemplate; else defaultError" />
        <ng-template #defaultError>
          <nxh-error />
        </ng-template>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /*width: 100%;*/
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
      }

      :host.loaded {
        display: none;
      }
    `,
  ],
  standalone: true,
  imports: [
    EmptyComponent,
    ErrorComponent,
    LoadingComponent,
    NgIf,
    NgSwitchCase,
    NgSwitch,
    NgTemplateOutlet,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStatesComponent {
  @HostBinding('class') @Input() loadingState: LoadingStatus | null;
  @Input() emptyTemplate?: TemplateRef<any>;
  @Input() errorTemplate?: TemplateRef<any>;
  @Input() loadingTemplate?: TemplateRef<any>;
}
