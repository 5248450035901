import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { I18NextPipe } from 'angular-i18next';

import { AutoValidateDirective } from '@nexuzhealth/shared/ui-toolkit/validation';
import { LoadingButtonComponent } from '@nexuzhealth/shared/ui-toolkit/loading-button';

/**
 * @deprecated Use nh-submit-button instead: https://uitoolkit.dev.nexuzhealth.pro/validation
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'deprecated-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
  // eslint-disable-next-line
  @Input('showSpinner') _showSpinner = false;
  @Input() label = this.i18Next.transform('save');
  // eslint-disable-next-line
  @Input('disabled') _disabled: boolean | null = null;
  @Input() showIcon = true;
  @Input() showLabel = true;
  @Input() btnType = 'success';
  @Input() icon: IconProp = faCheck;
  @Input() id;
  @ViewChild('button', { static: true }) buttonRef: LoadingButtonComponent;

  faSpinner = faSpinner;

  constructor(
    @Optional() private autoValidateDirective: AutoValidateDirective | null,
    private i18Next: I18NextPipe,
    private cdr: ChangeDetectorRef
  ) {}

  get disabled() {
    const form = this.autoValidateDirective && this.autoValidateDirective.getForm();
    if (!form) {
      return this._disabled !== null ? this._disabled : this._showSpinner;
    } else {
      return form.disabled;
    }
  }

  get showSpinner() {
    const form = this.autoValidateDirective?.getForm();
    if (!form) {
      return this._showSpinner;
    } else {
      return form.disabled || this._showSpinner;
    }
  }

  @HostListener('mousedown', ['$event'])
  mousedown(event: MouseEvent) {
    event.preventDefault();
  }

  @HostListener('mouseup')
  focus() {
    this.buttonRef.focus();
  }

  startSpinning() {
    this._showSpinner = true;
    this.cdr.markForCheck();
  }

  stopSpinning() {
    this._showSpinner = false;
    this.cdr.markForCheck();
  }
}
