<div class="not-found">
  <div class="not-found__inner">
    <div class="not-found__content">
      <h1 class="not-found__title">{{ title }}</h1>
      <div>
        <p class="not-found__description">{{ description }}</p>
        <ul class="not-found__reasons">
          <li *ngFor="let reason of reasons">{{ reason }}</li>
        </ul>
        <p class="not-found__actions">
          <ng-content select=".not-found__actions"></ng-content>
        </p>
      </div>
    </div>
    <div class="not-found__icon-container">
      <fa-icon [icon]="icon"></fa-icon>
    </div>
  </div>
</div>
