import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { IconLoaderModule } from '@nexuzhealth/shared/ui-toolkit/icon-loader';
import { LoadingStatesConfig } from '@nexuzhealth/shared/util';
import { map, tap } from 'rxjs/operators';
import { concat, EMPTY, Observable, of, timer } from 'rxjs';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'nxh-loading',
  template: `
    <div class="loading-container" *ngIf="activeLabel$ | async as activeLabel">
      <nxh-icon-loader [size]="size" />
      <div class="loading-label" [ngSwitch]="activeLabel">
        <span *ngSwitchCase="'initial'">
          {{ label || ('_loading-states.loading' | i18next) }}
        </span>
        <span *ngSwitchCase="'warning'">
          {{ warningTimoutLabel || ('_loading-states.warning-timeout' | i18next) }}
        </span>
      </div>
    </div>
  `,
  styles: [
    `
      .loading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }

      .loading-label {
        color: var(--gray-600);
        font-weight: 400;
        font-size: 0.75rem;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18NextModule, IconLoaderModule, AsyncPipe, NgIf, NgSwitch, NgSwitchCase],
})
export class LoadingComponent implements OnInit {
  /**
   * Custom "loading state" message
   */
  @Input() label?: string;

  /**
   * Custom message that is shown after the warning timeout has passed
   */
  @Input() warningTimoutLabel = '_loading-states.warning-timeout';

  /**
   * Size of the loading spinner
   */
  @Input() size: 'xs' | 'lg' = 'xs';

  /**
   * Time before showing loading state. Set to 0 to disable
   */
  @Input() debounceTime = 0;

  /**
   * Time before a timeout warning kicks in.
   */
  private warningTimeoutTime = inject(LoadingStatesConfig, { optional: true })?.warningTimeoutTime ?? -1;

  activeLabel$!: Observable<'initial' | 'warning'>;

  ngOnInit(): void {
    const initialLabel$ = timer(this.debounceTime).pipe(map(() => 'initial' as const));
    const warningLabel$ =
      this.warningTimeoutTime !== -1 ? timer(this.warningTimeoutTime).pipe(map(() => 'warning' as const)) : EMPTY;
    this.activeLabel$ = concat(initialLabel$, warningLabel$);
  }
}
