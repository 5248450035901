import { CdkTableModule } from '@angular/cdk/table';
import { PageLoaderModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/page-loader';
import { EmptyStateModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/empty-state';
import { CardModule } from '@nexuzhealth/shared/ui-toolkit/card';
import { SelectsModule } from '@nexuzhealth/shared/ui-toolkit/selects';

import { SharedUiToolkitCancelButtonModule } from '@nexuzhealth/shared/ui-toolkit/cancel-button';
import { AvatarModule } from '@nexuzhealth/shared/ui-toolkit/avatar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedReferenceDataDataAccessModule } from '@nexuzhealth/shared/reference-data/data-access';
import { SharedReferenceDataFeatureAddressModule } from '@nexuzhealth/shared/reference-data/feature-address';
import { AlertModule } from '@nexuzhealth/shared/ui-toolkit/alert';
import { NxhFormsModule } from '@nexuzhealth/shared/ui-toolkit/forms';
import { TinyLoaderModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/tiny-loader';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { TimeoutStateModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/timeout-state';
import { SubmitButtonModule } from '@nexuzhealth/shared/ui-toolkit/submit-button';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FindAddressPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/find-address';
import { FindAssignedIdTypePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/find-assigned-id-type';
import { FormatAssignedIdPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-assigned-id';
import { FormatPersonNamePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/format-person-name';
import { SentenceCasePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/sentence-case';
import { CustomDatePipeModule } from '@nexuzhealth/shared/ui-toolkit/l10n/custom-date';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { BlurModule } from '@nexuzhealth/shared/ui-toolkit/blur';
import { SharedReferenceDataFeatureReferenceDataModule } from '@nexuzhealth/shared/reference-data/feature-reference-data';
import { DatePickerModule } from '@nexuzhealth/shared/ui-toolkit/date-picker';
import { ModalModule } from '@nexuzhealth/shared/ui-toolkit/modal';
import { TableModule } from '@nexuzhealth/shared/ui-toolkit/table';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { SharedSettingsFeatureFlagsUiModule } from '@nexuzhealth/shared/settings/feature-flags/ui';
import { NgxMaskDirective } from 'ngx-mask';
import { EidDetailComponent } from './components/eid-detail/eid-detail.component';
import { EidSyncModalComponent } from './components/eid-sync-modal/eid-sync-modal.component';
import { NavItemEidComponent } from './components/nav-item-eid/nav-item-eid.component';
import { SuggestionTableComponent } from './components/suggestion-table/suggestion-table.component';
import { EidFormComponent } from './components/upsert/eid-form/eid-form.component';
import { EidModalHeaderComponent } from './eid-modal-v2/eid-modal-header/eid-modal-header.component';
import { EidModalV2Component } from './eid-modal-v2/eid-modal.component';
import { MockEidReaderComponent } from './components/mock-eid-reader/mock-eid-reader.component';

@NgModule({
  imports: [
    CdkTableModule,
    PageLoaderModule,
    EmptyStateModule,
    SelectsModule,

    SharedUiToolkitCancelButtonModule,
    AvatarModule,
    TinyLoaderModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedReferenceDataDataAccessModule,
    IconsModule,
    SharedUtilI18nModule,
    SharedReferenceDataFeatureAddressModule,
    NxhFormsModule,
    AlertModule,
    TimeoutStateModule,
    SubmitButtonModule,
    SharedTechFeatureE2eModule,
    FindAddressPipeModule,
    FindAssignedIdTypePipeModule,
    FormatAssignedIdPipeModule,
    FormatPersonNamePipeModule,
    SentenceCasePipeModule,
    CustomDatePipeModule,
    NgbTooltipModule,
    NgxMaskDirective,
    BlurModule,
    TableModule,
    CardModule,
    SharedReferenceDataFeatureReferenceDataModule,
    DatePickerModule,
    ModalModule,
    ButtonModule,
    SharedSettingsFeatureFlagsUiModule,
  ],
  declarations: [
    EidModalV2Component,
    EidDetailComponent,
    EidFormComponent,
    NavItemEidComponent,
    SuggestionTableComponent,
    EidSyncModalComponent,
    EidModalHeaderComponent,
    MockEidReaderComponent,
  ],
  exports: [EidModalV2Component, EidDetailComponent, EidFormComponent, NavItemEidComponent],
})
export class SharedEidFeatureModule {}
