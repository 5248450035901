import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormHelper } from '../../../shared/form-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-fieldset',
  styleUrls: ['fieldset.component.scss'],
  templateUrl: './fieldset.component.html',
})
export class FieldsetComponent {
  @Input() disabled = false;
  submitting$: Observable<boolean>;

  constructor(private helper: FormHelper) {
    this.submitting$ = helper.submitting$;
  }
}
