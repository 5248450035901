import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Error } from '@nexuzhealth/shared/domain';
import { find } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        const kind = getKind(response);
        const translationKey = getTranslationKey(kind, response);
        const message = getMessage(response);
        const extraData = getExtraData(response);
        const fieldViolations = getFieldViolations(response);
        const traceId = getDebugInfoTraceId(response) || response.headers.get('X-Cloud-Trace-Context');

        const errorObject: Error = {
          traceId,
          kind,
          extraData,
          translationKey,
          message,
          fieldViolations,
          status: response.status,
          source: response.error,
        };

        return throwError(() => errorObject);
      })
    );
  }
}

function getKind(response: HttpErrorResponse) {
  const error = response.error;
  if (!error || !error.details) {
    // 'old' error format
    return null;
  }

  // Backend will always only send 1 protobuf struct and will notify when this changes becasue this will require structure changes aswel
  const values = find(error?.details, ['@type', 'type.googleapis.com/google.protobuf.Struct']);
  return values?.value?.kind || null;
}

function getTranslationKey(kind: string, response: HttpErrorResponse) {
  // if the error is unspecified the backend has not added one yet and we will show a generic error
  return !kind || kind === 'unspecified' ? `unspecified_error_${response.status}` : kind;
}

function getMessage(response: HttpErrorResponse) {
  // typically the response will contain an error object - for 'old' error responses we fallback to the response message
  return response?.error?.message || response.message;
}

function getExtraData(response: HttpErrorResponse) {
  const values = find(response.error?.details, ['@type', 'type.googleapis.com/google.protobuf.Struct']);
  return values?.value?.extraData || null;
}

function getFieldViolations(response: HttpErrorResponse): any[] {
  const values = find(response.error?.details, ['@type', 'type.googleapis.com/google.rpc.BadRequest']);
  return (values?.fieldViolations || null) as any[];
}

function getDebugInfoTraceId(response: HttpErrorResponse) {
  const values = find(response.error?.details, ['@type', 'type.googleapis.com/google.rpc.DebugInfo']);
  return values?.detail || null;
}
