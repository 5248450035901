<div class="nav__menu--right__item nav__menu--right__item__user" *ngIf="user$ | async as user">
  <div ngbDropdown class="hide-default-down-icon" #userDropdown="ngbDropdown">
    <button class="btn nav__menus__user__button" ngbDropdownToggle>
      <nxh-avatar class="user-avatar" [pictureName]="user.pictureBlobName" [fullName]="user.displayName"> </nxh-avatar>
      <div class="user-info" *ngIf="userContext$ | async as userContext">
        <span class="user-info__username">{{ user.displayName | formatPersonName }}</span>
        <span class="user-info__tenant" *ngIf="userContext?.tenant?.description">
          {{ userContext.tenant.description }}
          <span *ngIf="userContext.rolesActive"
            >- {{ userContext.rolesActive | formatRoles : 'ONLY_ASSIGNABLE' }}
          </span>
        </span>
      </div>
    </button>
    <div class="dropdownmenu" ngbDropdownMenu>
      <ng-container *ngIf="allUserContexts$ | async as availableUserContexts">
        <div class="list-item-title">
          {{ 'log-in-to' | i18next }}
          <nxh-input
            class="user-context-search form-control"
            *ngIf="(userContexts$ | async).length > 3"
            type="search"
            [formControl]="userContextSearch"
            [nxhFocus]="true"
            nxhDataTest="user-context-search-input"
            [placeholder]="'_login._tenant.search' | i18next"
          >
            <fa-icon icon="search" nxhSuffix></fa-icon>
          </nxh-input>
        </div>

        <cdk-virtual-scroll-viewport
          *ngIf="availableUserContexts.length; else emptyTemp"
          class="context-user__container"
          [ngStyle]="{ 'height.rem': (userContextVirtualScrollItemSize * availableUserContexts.length) / 16 }"
          [itemSize]="userContextVirtualScrollItemSize"
          [minBufferPx]="userContextVirtualScrollItemSize * 3"
          [maxBufferPx]="userContextVirtualScrollItemSize * 6"
        >
          <div
            class="list-item-context"
            [class.active]="(userContext$ | async).name === ctx.userContextName"
            *ngFor="let ctx of availableUserContexts"
            (click)="logInTo(ctx.userContextName); userDropdown.close()"
          >
            <div class="list-item-context-user">
              <div class="tenant">{{ ctx.tenantDescription }}</div>
              <div class="roles">{{ ctx.roles }}</div>
            </div>
            <div class="list-item-context-icon">
              <fa-icon [icon]="'check-circle'" *ngIf="(userContext$ | async).name === ctx.userContextName"></fa-icon>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>

        <ng-template #emptyTemp>
          <div class="empty-search">
            {{ '_login._tenant._search.empty' | i18next }}
          </div>
        </ng-template>
      </ng-container>

      <div class="list-item-title">
        {{ 'configuration' | i18next }}
      </div>
      <div class="list-item" *ngIf="userPortalLinkVisible">
        <a [href]="userPortalLink" target="_blank" class="btn" ngbDropdownItem>
          <fa-icon icon="user"></fa-icon> <span>{{ '_apps._user-portal.link' | i18next }}</span>
        </a>
      </div>
      <ng-container *ngIf="organizationPortalLinkVisible">
        <div
          class="list-item"
          *isAuthorized="{
            or: [
              AccessRole.organisationManagement,
              AccessRole.suborganisationAdmin,
              AccessRole.userManagement,
              AccessRole.financialSettings
            ]
          }"
        >
          <a [href]="organizationPortalLink" target="_blank" class="btn" ngbDropdownItem>
            <fa-icon icon="home"></fa-icon> <span>{{ '_apps._organization-portal.link' | i18next }}</span>
          </a>
        </div>
      </ng-container>
      <div class="list-item">
        <button
          (click)="isLanguageCollapsed = !isLanguageCollapsed"
          [attr.aria-expanded]="!isLanguageCollapsed"
          aria-controls="collapseLanguage"
          class="btn changeLanguageButton"
          [class.active]="!isLanguageCollapsed"
        >
          <div class="changeLanguageButtonIconAndLabel">
            <fa-icon icon="globe"></fa-icon>
            <span>{{ 'change-language' | i18next }}</span>
          </div>

          <fa-icon [icon]="isLanguageCollapsed ? 'angle-down' : 'angle-up'" class="isCollapsedIcon"></fa-icon>
        </button>
        <div id="collapseLanguage" class="language-collapse" [ngbCollapse]="isLanguageCollapsed">
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              id="languageNL"
              name="radio-stacked"
              [checked]="(preferredLanguage$ | async) === 'nl'"
              (change)="updatePreferredLanguage('nl')"
            />
            <label class="form-check-label nh-cursor-as-pointer" for="languageNL">
              {{ 'language-nl' | i18next }}
            </label>
          </div>
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              id="languageEN"
              name="radio-stacked"
              [checked]="(preferredLanguage$ | async) === 'en'"
              (change)="updatePreferredLanguage('en')"
            />
            <label class="form-check-label nh-cursor-as-pointer" for="languageEN">{{ 'language-en' | i18next }}</label>
          </div>
          <div class="form-check" *featureFlag="'featureflags/select-french'">
            <input
              type="radio"
              class="form-check-input"
              id="languageFR"
              name="radio-stacked"
              [checked]="(preferredLanguage$ | async) === 'fr'"
              (change)="updatePreferredLanguage('fr')"
            />
            <label class="form-check-label nh-cursor-as-pointer" for="languageFR">{{ 'language-fr' | i18next }}</label>
          </div>
        </div>
      </div>
      <div class="list-item">
        <button id="signOutButton" class="btn signOutButton" (click)="logout()">
          <fa-icon icon="sign-out"></fa-icon> <span>{{ 'sign-out' | i18next }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
