import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { LoadingStatesComponent } from '@nexuzhealth/shared/ui-toolkit/loading2';
import { CardComponent } from './components/card/card.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardModalHeaderComponent } from './components/card-modal-header/card-modal-header.component';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { CardBodyComponent } from './components/card-body/card-body.component';
import { CardControlsComponent } from './components/card-controls/card-controls.component';
import { CardBodySectionComponent } from './components/card-body-section/card-body-section.component';
import { CardFooterComponent } from './components/card-footer/card-footer.component';
import { CardBodySectionsComponent } from './components/card-body-sections/card-body-sections.component';

@NgModule({
  imports: [
    CommonModule,
    CardHeaderComponent,
    IconsModule,
    NgbCollapseModule,
    SharedTechFeatureE2eModule,
    LoadingStatesComponent,
  ],
  declarations: [
    CardComponent,
    CardModalHeaderComponent,
    CardTitleComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardControlsComponent,
    CardBodySectionComponent,
    CardBodySectionsComponent,
  ],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardModalHeaderComponent,
    CardTitleComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardControlsComponent,
    CardBodySectionComponent,
    CardBodySectionsComponent,
  ],
})
export class CardModule {}
