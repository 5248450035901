import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({
  resettable: true,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error('could not boot', err);
    // document.getElementById('root__error-page')?.style.display = 'block';
  });
