import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { PushNotification, PushNotificationConfig } from '@nexuzhealth/shared/tech/domain';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Subject, Subscription } from 'rxjs';

import { notification$ } from './push-notifications.symbols';
import { PUSH_NOTIFICATION_CONFIG } from './push-notifications.tokens';

/**
 * Limited to 6 active listeners across all browser tabs
 *
 * To test this locally:
 * - run backend + run cmd: 'gcloud beta emulators pubsub start'
 * - add MOARPR_ROOT to environment variables
 */
@Injectable({ providedIn: 'root' })
export class PushNotificationsApiService implements OnDestroy {
  private source: EventSource;
  private listenerSubscription: Subscription;
  private notificationSubj = new Subject<PushNotification>();
  public [notification$] = this.notificationSubj.asObservable();

  constructor(private zone: NgZone, @Inject(PUSH_NOTIFICATION_CONFIG) private config: PushNotificationConfig) {}

  startListening() {
    if (this.listenerSubscription) {
      this.stopListening();
    }

    this.listenerSubscription = this.config.authToken$.subscribe((token) => {
      this.source = this.createEventSource(token);

      this.source.onmessage = (msg) => this.onMessage(msg);
      this.source.onerror = () => this.onError();

      // this.source.addEventListener('comment', (e) => {
      //   // typically called each 30 secs, i.e. 'hearbeat'
      //   console.log('heartbeat', e);
      // });
    });
  }

  stopListening() {
    this.listenerSubscription?.unsubscribe();
    this.listenerSubscription = null;

    this.source?.close();
    this.source = null;
  }

  ngOnDestroy() {
    this.stopListening();
    this.notificationSubj.complete();
  }

  private createEventSource(token: string) {
    return new EventSourcePolyfill(this.config.url, {
      headers: { ...this.config.headers, Authorization: `Bearer ${token}` },
    });
  }

  private onMessage(msg: MessageEvent) {
    const notification = msg.data ? JSON.parse(msg.data) : {};

    this.zone.run(() => {
      this.notificationSubj.next(notification);
    });
  }

  private onError() {
    this.stopListening();

    if (this.config.retryOnError) {
      setTimeout(() => this.startListening(), this.config.retryTimeout);
    }
  }
}
