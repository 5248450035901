<!--note: workaround to set one individual radio input disabled:
https://github.com/angular/angular/issues/11763#issuecomment-268622631-->

<div class="form-check" *ngIf="formControl">
  <input
    #input
    type="radio"
    class="form-check-input"
    [id]="id"
    [value]="value"
    [name]="name"
    [formControl]="formControl"
    [attr.disabled]="disabled === true || null"
    (blur)="onBlur()"
  />
  <label class="form-check-label" [for]="id" *ngIf="!noLabel">
    <span *ngIf="label">{{ label }}</span>
    <ng-content></ng-content>
  </label>
  <ng-content select="[nxhOutsideLabel]"></ng-content>
</div>
