<ng-container [ngSwitch]="status$ | async">
  <div class="page-loading-indicator" *ngSwitchCase="'loading'">
    <div class="page-loading-indicator__container">
      <div class="page-loading-indicator__icon">
        <nxh-icon-loader></nxh-icon-loader>
      </div>
      <div class="page-loading-indicator__description">
        {{ timeoutWarning ? ('_loading-states.warning-timeout' | i18next) : loadingMessage }}
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'empty'">
    <nxh-empty-state icon="file-search" *ngIf="!emptyStateTemplate; else customEmpty">
      <div class="empty__title">{{ '_loading-states.no-items-found' | i18next }}</div>
      <div class="empty__description">
        {{ '_loading-states.no-items-found-for-query' | i18next }}
      </div>
    </nxh-empty-state>
  </div>

  <div class="page-loading-error" *ngSwitchCase="'error'">
    <nxh-timeout-state *ngIf="!errorStateTemplate; else customError">
      <div class="timeout__title">{{ 'general-timeout-error-title' | i18next }}</div>
      <div class="timeout__description">{{ 'general-timeout-error-description' | i18next }}.</div>
    </nxh-timeout-state>
  </div>
</ng-container>

<ng-template #customEmpty>
  <ng-container [ngTemplateOutlet]="emptyStateTemplate!"></ng-container>
</ng-template>

<ng-template #customError>
  <ng-container [ngTemplateOutlet]="errorStateTemplate!"></ng-container>
</ng-template>
