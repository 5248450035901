import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  EidApiService,
  EidDetailsView,
  EidPersonInfo,
  EidResponse,
  TokenResponse,
} from '@nexuzhealth/shared/eid/data-access';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { CountryQuery, NationalityQuery } from '@nexuzhealth/shared/reference-data/data-access';
import { from, fromEvent, Observable, race, Subject } from 'rxjs';
import { filter, finalize, map, mergeMap, takeUntil } from 'rxjs/operators';
import { SuggestedPatientsResponse } from '@nexuzhealth/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class EidService implements OnDestroy {
  private popup: Window;
  private destroy$ = new Subject<void>();
  private newWindow$ = new Subject<boolean>();
  private belgianNationality: string;
  private belgianCountry: string;

  constructor(
    private api: EidApiService,
    private settings: SettingsService,
    private preferencesQuery: UserPreferencesQuery,
    private nationalityQuery: NationalityQuery,
    private countryQuery: CountryQuery
  ) {
    //TODO: clean this up with eid modal refactor
    this.belgianNationality = this.nationalityQuery.getDefaultNationalityName();
    this.belgianCountry = this.countryQuery.getDefaultCountryName();
  }

  createLog(inss: string, cardNumber: string): Observable<any> {
    return this.api.createLog(inss, cardNumber);
  }

  retrieveEIDData(): Observable<EidResponse> {
    const options = 'modal=yes,alwaysRaised=yes,width=800,height=500';
    this.popup = window.open(this.eidUrl, 'Read eID', options);
    const didWindowClose = () => this.popup.closed;
    const windowClosed$ = conditionPoll(didWindowClose, 600000, 1000);
    this.newWindow$.next(true);
    const untilSubj = race([this.newWindow$, windowClosed$, this.destroy$]).pipe(finalize(() => this.popup.close()));
    return fromEvent(window, 'message').pipe(
      takeUntil(untilSubj),
      filter((event: MessageEvent<{ code: string; type: string }>) => {
        return event.source === this.popup && event.data?.type === 'eid-receiver';
      }),
      mergeMap((event) => {
        if (typeof (event.source as any).close === 'function') (event.source as any).close();
        return this.api.getToken(event.data.code, this.redirectUri, true).pipe(
          map((response: TokenResponse) => {
            return {
              view: this.getEidDetailsView(this.mapToUserInfo(response.userInfo)),
              matchingPatients: response.matchingPatients,
            };
          })
        );
      })
    );
  }

  getNoCardReadingReasons(): Observable<string[]> {
    return this.api.getNoCardReadingReasons()
  }

  private get eidUrl(): string {
    const endpoint = this.settings.zetesEidEndpoint;
    const clientId = this.settings.zetesClientId;
    const [nonce, state] = crypto.getRandomValues(new Uint32Array(2));
    const lang = this.mapLanguage(this.preferencesQuery.getPreferredLanguage());

    const params = new HttpParams()
      .append('response_type', 'code')
      .append('client_id', clientId)
      .append('scope', 'openid+address+profile')
      .append('redirect_uri', this.redirectUri)
      .append('nonce', nonce.toString())
      .append('state', state.toString())
      .append('ui_locales', lang)
      .append('acr_values', 'LoA-1')
      .append('skip_ocsp', 'true')
      .append('card_type', 'beid')
      .append('prompt', 'login')
      .append('skip_consent', '1');

    return `${endpoint}authorize?${params}`;
  }

  private mapLanguage(preferredLanguage: string): string {
    return ['en', 'fr', 'nl'].includes(preferredLanguage) ? preferredLanguage : 'en';
  }

  private get redirectUri(): string {
    const loc = window.location;
    return `${loc.protocol}//${loc.host}/assets/eid-receiver.html`;
  }

  mapToUserInfo(info: EidPersonInfo): EidPersonInfo {
    const address = info.address;
    if (address) {
      address.country = address.country || this.belgianCountry;
    }

    return {
      ...info,
      nationality: info.nationality || info.documentType === 'belgian_citizen' ? this.belgianNationality : null,
      birthdate: this.eidStringToDate(info.birthdate),
      validityDateBegin: this.eidStringToDate(info.validityDateBegin),
      validityDateEnd: this.eidStringToDate(info.validityDateEnd),
    };
  }

  getEidDetailsView(userInfo: EidPersonInfo): EidDetailsView {
    return {
      userInfo: userInfo,
      name: [userInfo.givenName, userInfo.middleName, userInfo.name]
        .filter((v) => !!v)
        .map((n) => n.toLocaleString().trim())
        .join(' '),
      birthDate: new Date(userInfo.birthdate),
      address: userInfo.address,
    };
  }

  eidStringToDate(eidString: string): string {
    const birthDateSplit = eidString?.split('/');
    return birthDateSplit?.length === 3
      ? new Date([birthDateSplit[2], birthDateSplit[1], birthDateSplit[0]].join('-')).toISOString()
      : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.popup?.close();
  }
}

function conditionPoll(fn: () => boolean, timeout, interval) {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = function (resolve, reject) {
    const result = fn();
    if (result) {
      resolve(result);
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    } else {
      reject(new Error('timed out for ' + fn));
    }
  };
  return from(new Promise(checkCondition));
}
