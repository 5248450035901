import { Injectable } from '@angular/core';
import { action, arrayUpsert, Store, StoreConfig } from '@datorama/akita';
import { OrganisationAccessRole, User, UserContext, UserSettingsBody } from '@nexuzhealth/shared/domain';

const key_user_context_name = 'nh.auth.user-context-name';

export interface AuthState {
  userContextName: string;
  user: User;
  userContexts: UserContext[];
  settings: UserSettingsBody[];
  organisationAccessRoles: OrganisationAccessRole[];
}

export function createInitialAuthState(): AuthState {
  return {
    userContextName: null,
    user: null,
    userContexts: null,
    settings: null,
    organisationAccessRoles: null,
    ...restoreLocal(),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialAuthState());
  }

  @action('setUser')
  setUser(user: User): void {
    this.update({ user });
  }

  @action('setUserContexts')
  setUserContexts(userContexts: UserContext[]): void {
    this.update({ userContexts });
  }

  @action('[API/User Auto Import] Set user settings')
  setUserSettings(settings: UserSettingsBody[]): void {
    this.update({ settings });
  }

  @action('setUserContext')
  setUserContextName(userContextName: string): void {
    this.update({ userContextName });
    storeLocal(key_user_context_name, userContextName);
  }

  @action('[API/User Auto Import] Set user auto import eHealthbox')
  setUserAutoImportEHealthBox(enableAutoImport: boolean): void {
    this.update((state) => ({
      ...state,
      settings: arrayUpsert(
        state.settings,
        'ehealth-box-automatic-import-enabled',
        { value: enableAutoImport.toString() },
        'settingId'
      ),
    }));
  }

  @action('setOrganisationRoles')
  setOrganisationRoles(organisationAccessRoles: OrganisationAccessRole[]): void {
    this.update({ organisationAccessRoles });
  }

  @action('logout')
  logout() {
    removeLocal(key_user_context_name);
    this.update(createInitialAuthState());
  }
}

function storeLocal(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeLocal(...keys: string[]) {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

function restoreLocal() {
  return {
    userContextName: loadLocal(key_user_context_name),
  };
}

function loadLocal(key: string) {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    removeLocal(key);
    return undefined;
  }
}
