<button
  nxh-button
  buttonType="subtle-button"
  buttonStatus="neutral"
  tabindex="-1"
  [disabled]="disabled || (submitting$ | async)"
>
  <span #ref><ng-content></ng-content></span>
  <ng-container *ngIf="!ref.hasChildNodes()">
    {{ label | i18next }}
  </ng-container>
</button>
