<ng-select
  bindValue="name"
  bindLabel="translationKey"
  [virtualScroll]="virtualScroll"
  [clearable]="clearable"
  [items]="items$ | async | i18nextOption : 'translationKey'"
  (change)="valueChange.emit($event)"
  [placeholder]="placeholder"
  #select
>
</ng-select>
