/**
 * To make this typesafe we need variadic kinds: https://github.com/microsoft/TypeScript/issues/5453
 */

export function preloadAll(...preloadables: Preloadable[]): () => Promise<Record<string, unknown>> {
  return async () => {
    let resultCollector = {};
    for (const preloadable of preloadables) {
      const preloadbleResult = await preloadable.preload.call(preloadable, resultCollector);
      resultCollector = {
        ...resultCollector,
        ...preloadbleResult,
      };
    }
    return resultCollector;
  };
}

/**
 * To make this typesafe we need variadic kinds: https://github.com/microsoft/TypeScript/issues/5453
 */
export interface Preloadable {
  preload(options: Record<string, unknown>): Promise<Record<string, unknown>>;
}
