import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Pipe({
  name: 'i18nextArrayByKey',
})
export class I18nextArrayByKeyPipe implements PipeTransform {
  constructor(private i18next: I18NextPipe) {}

  transform(
    value: any[], // This is not ideal but when working with cdk table the typing isn't passed properly of the nested properties, resulting in an error on the template
    translateField: string,
    joinArrayBy: string = ''
  ): string | string[] {
    const result = value.map((option) => this.translateOption(option, translateField)[translateField]);
    return joinArrayBy ? result.join(joinArrayBy) : result;
  }

  private translateOption(option: any, translateField: string) {
    const value = option[translateField];
    if (typeof value !== 'string') {
      console.warn(`Cannot translate invalid key: ${value}`);
      return option;
    }
    return { ...option, [translateField]: this.i18next.transform(value) };
  }
}
