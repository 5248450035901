import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { I18NextPipe } from 'angular-i18next';
import { sortBy } from 'lodash-es';
import { filter, map, take } from 'rxjs/operators';
import { ResourceName } from '@nexuzhealth/shared/domain';
import { COMMON_LANGUAGES, languageResourceNameToId } from '@nexuzhealth/shared/util';
import { LanguageReferenceState } from '../shared/reference-types';
import { LanguageStore } from './language.store';

export function isDutch(languageName: ResourceName): boolean {
  return languageResourceNameToId(languageName) === COMMON_LANGUAGES.dutch;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageQuery extends QueryEntity<LanguageReferenceState> {
  constructor(protected store: LanguageStore, private i18next: I18NextPipe) {
    super(store);
  }

  selectSupportedLanguages(sorted = false) {
    return this.selectMany(Object.values(COMMON_LANGUAGES)).pipe(
      filter((languages) => languages?.length > 0),
      map((languages) =>
        sorted ? sortBy(languages, (lang) => this.i18next.transform(lang.translationKey)) : languages
      ),
      take(1)
    );
  }
}
