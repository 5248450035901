import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ResolveAdministrativeGenderPipe,
  ResolveCountryPipe,
  ResolveCityPipe,
  ResolveHealthCareWorkerProfessionPipe,
  ResolveLanguagePipe,
  ResolveMaritalStatusPipe,
  ResolveNationalityPipe,
  ResolveProfessionPipe,
  ResolveMediumPipe,
  ResolveLanguageAsIsoCodePipe,
} from './component/pipes/resolveReference.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ResolveCountryPipe,
    ResolveCityPipe,
    ResolveNationalityPipe,
    ResolveLanguagePipe,
    ResolveLanguageAsIsoCodePipe,
    ResolveMaritalStatusPipe,
    ResolveProfessionPipe,
    ResolveHealthCareWorkerProfessionPipe,
    ResolveAdministrativeGenderPipe,
    ResolveMediumPipe,
  ],
  exports: [
    ResolveCountryPipe,
    ResolveCityPipe,
    ResolveNationalityPipe,
    ResolveLanguagePipe,
    ResolveLanguageAsIsoCodePipe,
    ResolveMaritalStatusPipe,
    ResolveProfessionPipe,
    ResolveHealthCareWorkerProfessionPipe,
    ResolveAdministrativeGenderPipe,
    ResolveMediumPipe,
  ],
})
export class SharedReferenceDataDataAccessModule {}
