<div class="form-group checkbox-group" [class.checkbox-group--compact]="compact">
  <label class="checkbox-group__label" [class.required]="required" [class.disabled]="disabledSubj | async"
    >{{ label }}
  </label>
  <div
    class="checkbox-group__container"
    [class.checkbox-group--row]="direction === 'row'"
    [class.checkbox-group--col]="direction === 'col'"
  >
    <ng-content select="nh-checkbox"></ng-content>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
