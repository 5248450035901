import { EntityState, QueryEntity } from '@datorama/akita';

export const LANGUAGE_ISO_CODE_SOURCE = 'ISO639-1';

export interface ReferenceType {
  name: string;
  translationKey: string;
  value?: string;
  freeText?: boolean;
}

export type CountryReference = ReferenceType;
export type NationalityReference = ReferenceType;
export type MediumReference = ReferenceType;
export type MaritalStatusReference = ReferenceType;
export type ProfessionReference = ReferenceType;
export type HealthCareWorkerProfessionReference = ReferenceType;
export type CityReference = ReferenceType;

export interface GenderCode {
  name: string;
  sendVersion: string;
  source: string;
  value: string;
}
export interface AdministrativeGenderReference extends ReferenceType {
  genderCodes: GenderCode[];
}

export interface LanguageReference extends ReferenceType {
  languageCodes: LanguageCode[];
}

export interface LanguageCode {
  name: string;
  source: string;
  value: string;
}

export type ReferenceQuery<T extends ReferenceType = ReferenceType> = QueryEntity<EntityState<T>, T>;
export type ReferenceTypeState = EntityState<ReferenceType, string>;
export type AdministrativeGenderReferenceState = EntityState<AdministrativeGenderReference, string>;
export type LanguageReferenceState = EntityState<LanguageReference, string>;
