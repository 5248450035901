<div class="nav__menu--right__item">
  <div class="autosuggest__container" #host>
    <div class="input-group" [class.nh-cursor-as-pointer]="collapsed$ | async" (click)="expandSearchField($event)">
      <button class="btn search-input-btn" [ngbTooltip]="'_search-patient.tooltip' | i18next">
        <fa-icon icon="search" size="lg"></fa-icon>
      </button>

      <input
        #searchField
        nxhTypeahead
        [placeholder]="'_search-patient.placeholder' | i18next"
        [@changeSize]="{ value: (collapsed$ | async) ? 'small' : 'large', params: { small: 0, large: 250 } }"
        (@changeSize.done)="expandAnimDone()"
        [ngClass]="{ 'ps-0 pe-0 invisible': collapsed$ | async }"
        (input)="term$.next(searchField.value)"
        [disable]="collapsed$ | async"
        (selectOption)="selectOption($event)"
        (allResultsOption)="allResults()"
        (advancedSearchingOption)="advancedSearch()"
        [searchMethod]="searchPatients"
        [origin]="host"
        [resultTemplate]="customOptionsTemplate"
        [noResultTemplate]="noResultsTemplate"
        [minSearchTermLength]="2"
        class="form-control bg-transparent"
      />

      <button
        class="btn btn-default bg-transparent"
        type="button"
        style="z-index: 901"
        (mousedown)="clear($event)"
        [ngClass]="{ 'w-0 p-0 invisible': collapsed$ | async }"
      >
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>

  <ng-template
    #customOptionsTemplate
    let-options="options"
    let-term="term"
    let-active="active"
    let-completed="completed"
    let-select="select"
    let-allResults="allResults"
  >
    <!-- Waiting state -->
    <div *ngIf="completed === false && term !== null" class="bg-white shadow w-100 rounded-bottom pb-1 pt-1">
      <nxh-loading-spinner></nxh-loading-spinner>
    </div>

    <div
      *ngIf="completed === true && term !== null && options.length > 0"
      class="bg-white shadow w-100 rounded-bottom pb-1 pt-1"
    >
      <ul class="list-inline mb-0">
        <li
          *ngFor="let option of this.options; let index = index"
          class="search-result__item"
          [class.active]="index === this.active"
          (click)="select(option)"
        >
          <nxh-avatar [pictureName]="option.value.blobPictureName" [fullName]="option.value.personName"></nxh-avatar>
          <div class="ps-2">
            <div class="patient-name" [innerHTML]="option.display | bold : getTerm()"></div>
            <div [ngSwitch]="getInss(option.value)">
              <div
                *ngSwitchDefault
                class="d-block text-muted patient-inns"
                [innerHTML]="getInss(option.value) | formatAssignedId | boldNumber : getTerm()"
              ></div>
              <div *ngSwitchCase="''" class="d-block text-muted patient-inns">
                {{ '_search-patient.inns-not-found' | i18next }}
              </div>
            </div>
          </div>
        </li>
        <li class="search-result__information search-result__information--footer">
          <div (click)="allResults()">
            <fa-icon icon="long-arrow-right"></fa-icon>
            {{ '_patient.show-all-results' | i18next }}
          </div>
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template
    let-term="term"
    #noResultsTemplate
    let-options="options"
    let-completed="completed"
    let-active="active"
    let-size="size"
    let-advancedSearch="advancedSearch"
  >
    <div
      *ngIf="this.completed === true || (term !== null && options.length === 0)"
      class="bg-white shadow w-100 rounded-bottom pb-1 pt-1"
    >
      <ul class="list-inline mb-0">
        <li class="search-result__information">
          <div>{{ '_patient.results-not-found%term' | i18next : { term: this.term$ | async } }}</div>
          <div class="search-result_information--notfound">{{ '_patient.results-not-found-message' | i18next }}</div>
        </li>
        <li class="search-result__information search-result__information--footer">
          <div (click)="advancedSearch()">
            <fa-icon icon="long-arrow-right"></fa-icon>
            {{ '_patient.advanced-searching' | i18next }}
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
