import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { EMPTY, fromEvent } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonDirective, ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { UiCardService } from '../../service/ui-card.service';

@Component({
  selector: 'nxh-card-header',
  standalone: true,
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf, IconsModule, ButtonModule, SharedTechFeatureE2eModule],
})
export class CardHeaderComponent implements OnChanges, OnInit, OnDestroy {
  @HostBinding('class')
  _hostClasses = 'card-header nxh-card-component';

  @HostBinding('class.nxh-card-header-dark')
  @Input()
  dark = false;

  @HostBinding('class.nxh-card-header-bottom-border')
  @Input()
  bottomBorder = false;

  @ContentChildren(ButtonDirective, {
    descendants: true,
    read: ElementRef,
  })
  protected iconButtons!: QueryList<ElementRef>;
  protected uiCardService: UiCardService = inject(UiCardService, { optional: true });

  private _hostElementRef: ElementRef = inject(ElementRef);
  private _renderer: Renderer2 = inject(Renderer2);
  private _destroyRef: DestroyRef = inject(DestroyRef);

  ngOnChanges(changes: SimpleChanges) {
    this.uiCardService.updateHeader(this);

    if (changes['dark']?.currentValue) {
      this.bottomBorder = true;
    }
  }

  ngOnInit(): void {
    const collapsibleState$ = this.uiCardService?.collapsible$.pipe(
      tap((collapsible: boolean) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !collapsible
          ? this._renderer.removeClass(this._hostElementRef.nativeElement, 'nh-cursor-as-pointer')
          : this._renderer.addClass(this._hostElementRef.nativeElement, 'nh-cursor-as-pointer');
      }),
      takeUntilDestroyed(this._destroyRef)
    );

    collapsibleState$
      .pipe(
        switchMap((collapsible: boolean) => {
          return collapsible
            ? fromEvent(this._hostElementRef.nativeElement, 'click').pipe(
                filter((event) => {
                  const source = event['target'];
                  // pseudocode
                  // If a card header is clickable, we only want to allow clicking the chevron button and the header div itself.
                  // all other buttons provided through content projection need to be prevented from performing a toggle collapse action
                  return source['id'] === 'collapse-btn' || source['type'] !== 'button';
                })
              )
            : EMPTY;
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => this.uiCardService.toggleCollapsed());

    this.uiCardService.updateHeader(this);
  }

  ngOnDestroy() {
    this.uiCardService.removeHeader();
  }
}
