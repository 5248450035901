import { AfterViewInit, Component, ElementRef, Inject, InjectionToken, Optional, Renderer2, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

export const ErrorMessageConfigToken = new InjectionToken<ComponentErrorMessageConfig>('ErrorMessageConfig');

/**
 * @deprecated Use <val-errors>
 */
@Component({
  selector: 'nxh-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements AfterViewInit {
  constructor(
    public control: NgControl,
    @Optional() @Inject(ErrorMessageConfigToken) public config: ComponentErrorMessageConfig = {},
    private renderer: Renderer2,
    @Self() private elementRef: ElementRef
  ) {}

  get errors() {
    return this.control.errors ? Object.keys(this.control.errors) : [];
  }

  hasCustomErrorMessage() {
    return !!this.config.customErrorMessage;
  }

  get customErrorMessage() {
    return this.config.customErrorMessage;
  }

  get firstErrorContext() {
    const errorValue = Object.values(this.control.errors)[0];
    const errorContext = typeof errorValue === 'boolean' ? {} : errorValue;
    return { ...errorContext, ...this.config.errorContext };
  }

  ngAfterViewInit(): void {
    // in case there was a CompactFormControlDirective on the input,
    if (this.config.hasCompactFormControlDirective && this.config.sourceElementRef) {
      const sourceRef = this.config.sourceElementRef;
      const sourceParent: HTMLElement = sourceRef.nativeElement.parentElement;
      this.renderer.appendChild(sourceParent, this.elementRef.nativeElement);
    }
  }

  firstError() {
    // note: this should actually be changed to const prefix = '_errors.errorName' but for backwards compatibility reasons
    // we keep it like this for the moment
    const errorName = this.getErrorName();
    const prefix = this.config.prefix || 'errors';
    return `${prefix}-${errorName}`;
  }

  private getErrorName() {
    const controlErrors = this.control.errors;
    const errorName = Object.keys(controlErrors)[0];
    const errorMap = this.config.errorMap || {};
    return errorMap[errorName] || errorName;
  }
}

export interface ComponentErrorMessageConfig {
  prefix?: string;
  errorMap?: { [errorName: string]: string };
  errorContext?: any;
  customErrorMessage?: string;
  hasCompactFormControlDirective?: boolean;
  sourceElementRef?: ElementRef;
}
