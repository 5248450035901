import { Injectable } from '@angular/core';
import { UserApiService } from '../api/user-api.service';
import { AuthQuery } from '../state/auth-query.service';
import { AuthStore } from '../state/auth-store.service';
import { SessionService } from './abstract-session.service';

@Injectable()
export class KwsSessionService extends SessionService {
  constructor(protected userApi: UserApiService, protected authStore: AuthStore, protected authQuery: AuthQuery) {
    super(authStore, userApi, authQuery);
    const firstContext = window.bridge.contexts()[0];
    this.useUserContextName(firstContext);
  }

  clear() {
    throw new Error('Calling the clear() function in kws-session.service.ts should never happen.');
  }

  setRedirectAfterLogin() {
    throw new Error('Calling the setRedirectAfterLogin() function in kws-session.service.ts should never happen.');
  }

  getAndClearRedirectAfterLogin(): string {
    throw new Error(
      'Calling the getAndClearRedirectAfterLogin() function in kws-session.service.ts should never happen.'
    );
  }
}
