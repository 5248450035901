import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemSettings } from '../shared/settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsApiService {
  readonly backendUrl = '/assets/daf/';

  private http: HttpClient;

  // Don't inject HttpClient, it will load all interceptors, which in turn need settings
  // By injecting HttpBackend, we bypass all interceptors
  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  loadSystemSettings(fileName: string): Observable<SystemSettings> {
    return this.http.get<SystemSettings>(`${this.backendUrl}${fileName}`);
  }
}
