<div ngbDropdown #dropdown="ngbDropdown" class="hide-default-down-icon" [placement]="placement">
  <div class="d-flex align-items-center">
    <ng-container [ngSwitch]="!!selectedItem">
      <fa-stack *ngSwitchCase="true">
        <fa-icon [icon]="['fas', 'circle']" stackItemSize="2x" class="background"></fa-icon>
        <fa-icon [icon]="selectedItem?.icon" stackItemSize="1x" class="foreground"></fa-icon>
      </fa-stack>
      <fa-stack *ngSwitchDefault>
        <fa-icon [icon]="faCircle" stackItemSize="2x" class="background"></fa-icon>
        <fa-icon [icon]="faQuestion" stackItemSize="1x" class="foreground"></fa-icon>
      </fa-stack>
    </ng-container>
    <button
      type="button"
      [attr.tabindex]="tabindex"
      class="btn btn-link confidentiality-select__text"
      ngbDropdownToggle
      #toggleButton
      [disabled]="isDisabled"
      nxhDataTest="confidentiality-btn"
    >
      <ng-container [ngSwitch]="!!selectedItem">
        <span *ngSwitchCase="true" class="selected-item_label">{{ selectedItem.label | i18next }}</span>
        <span *ngSwitchDefault>{{ 'confidentiality' | i18next | titlecase }}</span>
      </ng-container>
      <ng-container *ngIf="!isDisabled">
        <ng-container [ngSwitch]="dropdown.isOpen()">
          <fa-icon *ngSwitchCase="true" [icon]="faChevronUp"></fa-icon>
          <fa-icon *ngSwitchDefault [icon]="faChevronDown"></fa-icon>
        </ng-container>
      </ng-container>
    </button>
  </div>
  <div ngbDropdownMenu>
    <button
      type="button"
      class="btn dropdown-item"
      (click)="selectItem(item)"
      ngbDropdownItem
      *ngFor="let item of items; let index = index"
      [nxhDataTest]="'confidentiality-item-' + index"
    >
      <fa-icon [icon]="item.icon"></fa-icon>
      <span>{{ item.label | i18next }}</span>
    </button>
  </div>
</div>
