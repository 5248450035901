import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { TimeoutListenerService } from './timeout-listener.service';
import {
  default_timeout_error_millis,
  default_timeout_interval_millis,
  default_timeout_warning_millis,
  TimeoutInfo,
  timeouts,
  timeout_info_header,
} from './timeout-utils';

/**
 * HttpInterceptor that times out the current request after a certain time. Default
 * time can be overwritten by each request, like so:
 *
 * return this.http.post(url, invoice, { headers: timeoutHeaders({ errorMillis: 5000 }) });
 *
 */
@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
  private defaultErrorMillis = default_timeout_error_millis;

  constructor(private timeoutListener: TimeoutListenerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutInfo = this.getTimeoutInfo(request);
    request = removeTimeoutHeaders(request);

    if (timeoutInfo.errorMillis !== -1) {
      return next.handle(request).pipe(timeout(timeoutInfo.errorMillis));
    } else {
      return next.handle(request);
    }
  }

  private getTimeoutInfo(request: HttpRequest<any>) {
    let info = { errorMillis: this.defaultErrorMillis };
    const customInfo = request.headers.get(timeout_info_header);
    if (customInfo) {
      info = { ...info, ...JSON.parse(customInfo) };
    }

    return info;
  }
}

// remove unhandled (by backend) headers
function removeTimeoutHeaders(request: HttpRequest<any>) {
  const headers = request.headers.delete(timeout_info_header);
  return request.clone({ headers });
}
