import { AddressComponentModel } from '@nexuzhealth/shared/street-management/domain';
import { ResourceName } from './common';

export interface Address {
  name?: ResourceName;
  sendVersion?: string;
  use?: AddressUse;
  careEnvironment?: string;
  extraLines?: string;
  street?: string;
  // todo will this work?
  number?: string;
  box?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  comment?: string;
  patientName?: string;
  source?: string;
  labels?: string[];
  primary?: boolean;
  hiddenFields?: string[];

  addressComponent?: AddressComponentModel;
}

export type AddressUse =
  | 'Use_UNSPECIFIED'
  | 'Use_OFFICIAL'
  | 'Use_EXTRA_RESIDENTIAL'
  | 'Use_WORK'
  | 'Use_POSTAL'
  | 'Use_CARE'
  | 'Use_OTHER';

export function findAddressByUse(addresses: Address[], use: AddressUse): Address | undefined {
  if (!addresses) {
    return undefined;
  }

  return addresses.find((address) => address.use === use);
}
