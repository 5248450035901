import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { LanguageReference, LanguageReferenceState } from '../shared/reference-types';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'language', idKey: 'name' })
export class LanguageStore extends EntityStore<LanguageReferenceState, LanguageReference> {
  constructor() {
    super();
  }
}
