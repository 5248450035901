import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceName } from '@nexuzhealth/shared/domain';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

export interface AdministrativeLink {
  name: ResourceName;
  sendVersion: string;
  tenantName: string;
  status: 'UNSPECIFIED' | 'ACTIVE' | 'ARCHIVED';
  statusTime: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdministrativeRelationApiService {
  constructor(private httpClient: HttpClient) {}

  private readonly baseUrl = 'api/pm/patient/v1';

  getAdministrativeRelations(patientName: ResourceName): Observable<AdministrativeLink[]> {
    return this.httpClient
      .get<{ data: AdministrativeLink[] }>(`${this.baseUrl}/${patientName}/administrativerelations/tenant`)
      .pipe(pluck('data'));
  }

  updateAdministrativeRelation(administrativeLink: AdministrativeLink): Observable<AdministrativeLink> {
    return this.httpClient
      .patch<{ data: AdministrativeLink }>(`${this.baseUrl}/${administrativeLink.name}`, {
        ...administrativeLink,
      })
      .pipe(pluck('data'));
  }
}
