import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VerifyIdResponse } from '../shared/id-support-model';

@Injectable({
  providedIn: 'root',
})
export class IdSupportApiService {
  private readonly baseUrl = 'api/ehealth/idsupport/v1';

  constructor(private http: HttpClient) {}

  verifyId(idNumber: string, cardNumber: string): Observable<VerifyIdResponse> {
    const body = {
      idCard: {
        idNumber: idNumber,
        cardNumber: cardNumber,
      },
    };

    return this.http.post<VerifyIdResponse>(`${this.baseUrl}/verify`, body);
  }
}
