import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  isDevMode,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FocusService } from '@nexuzhealth/shared/ui-toolkit/focus';
import { LoadingStatesConfig } from '@nexuzhealth/shared/util';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounce, delay, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export enum PageLoaderStatus {
  loading = 'loading',
  empty = 'empty',
  error = 'error',
  loaded = 'loaded',
}

export function isLoaded(status?: PageLoaderStatus | null) {
  return status === PageLoaderStatus.loaded || status === PageLoaderStatus.empty || status === PageLoaderStatus.error;
}

@Component({
  selector: 'nxh-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLoaderComponent implements OnInit, OnChanges, OnDestroy {
  status$: Observable<PageLoaderStatus>;
  @Input() emptyStateTemplate?: TemplateRef<any>;
  @Input() errorStateTemplate?: TemplateRef<any>;
  @Input() loading: boolean | null;
  @Input() data: unknown;
  @Input() loaded: number | null;
  @Input() error: unknown;
  @Input() state?: PageLoaderStatus | null;
  @Input() loadingMessage = this.i18next.transform('_loading-states.loading');
  timeoutWarning = false;

  /**
   * Time before showing loading state. Set to 0 to disable
   */
  @Input() debounceTime = 300;

  /**
   * Time before a timeout warning kicks in.
   */
  @Input() warningTimeoutTime = -1;

  /**
   * Identifies control that should get focus after page is loaded. Simply putting
   * nxhFocus on such a control won't work, as controls that are hidden cannot
   * receice focus.
   */
  @Input() setFocusAfterLoad: string;

  private statusSubj = new BehaviorSubject<PageLoaderStatus>(null);
  private destroySubj = new Subject<void>();
  active$ = this.statusSubj.pipe(
    distinctUntilChanged(),
    map(
      (status) =>
        status === null ||
        status === PageLoaderStatus.loading ||
        status === PageLoaderStatus.empty ||
        status === PageLoaderStatus.error
    )
  );

  loading$ = this.statusSubj.pipe(
    distinctUntilChanged(),
    map((status) => status === null || status === PageLoaderStatus.loading)
  );

  @HostBinding('class.nxh-page-loader--active')
  activeClass$ = this.statusSubj.pipe(
    map(
      (status) =>
        status === PageLoaderStatus.loading || status === PageLoaderStatus.empty || status === PageLoaderStatus.error
    )
  );

  constructor(
    private i18next: I18NextPipe,
    private focusService: FocusService,
    private cdr: ChangeDetectorRef,
    @Optional() config: LoadingStatesConfig
  ) {
    this.warningTimeoutTime = config.warningTimeoutTime ?? -1;
  }

  get status() {
    return this.statusSubj.getValue();
  }

  get active() {
    return this.isActive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const state = changes['state'];
    if (state) {
      this.statusSubj.next(state.currentValue);
    }

    if (this.usingInputApi()) {
      const status: PageLoaderStatus = this.calcStatusFromInputApi();
      setTimeout(() => {
        this.statusSubj.next(status);
      });
    }
  }

  ngOnInit() {
    this.status$ = this.statusSubj.asObservable().pipe(
      tap(() => (this.timeoutWarning = false)),
      debounce((status) => {
        return status === PageLoaderStatus.loading ? timer(this.debounceTime) : timer(0);
      }),
      distinctUntilChanged(),
      takeUntil(this.destroySubj)
    );

    this.setupFocus();
    this.setupTimeouts();
  }

  private setupFocus() {
    if (this.setFocusAfterLoad) {
      this.status$
        .pipe(
          filter((active) => active === PageLoaderStatus.loaded),
          delay(300)
        )
        .subscribe((active) => {
          this.focusService.focus(this.setFocusAfterLoad);
        });
    }
  }

  private setupTimeouts() {
    if (this.warningTimeoutTime !== -1) {
      const busy$ = new Subject<void>();
      const loading$ = this.statusSubj.pipe(map((status) => status === PageLoaderStatus.loading));

      loading$
        .pipe(
          filter((loading) => !!loading),
          switchMap(() => timer(this.warningTimeoutTime).pipe(takeUntil(busy$)))
        )
        .subscribe(() => {
          this.timeoutWarning = true;
          this.cdr.detectChanges();
        });

      this.status$.pipe(filter((status) => status !== null && status !== PageLoaderStatus.loading)).subscribe(() => {
        busy$.next();
        busy$.complete();
      });
    }
  }

  private usingInputApi(): boolean {
    const inputs: (keyof this)[] = ['loading', 'data', 'error'];
    return inputs.some((key) => typeof this[key] !== 'undefined');
  }

  startLoading() {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, startLoading is not supported");
    }
    this.statusSubj.next(PageLoaderStatus.loading);
  }

  setError(err: Error) {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, setErrors is not supported");
    }
    this.statusSubj.next(PageLoaderStatus.error);
  }

  setLoaded(length: number) {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, setLoaded is not supported");
    }
    this.statusSubj.next(length === 0 ? PageLoaderStatus.empty : PageLoaderStatus.loaded);
  }

  isLoaded() {
    const status = this.statusSubj.getValue();
    return isLoaded(status);
  }

  isActive() {
    const status = this.statusSubj.getValue();
    return (
      status === PageLoaderStatus.loading || status === PageLoaderStatus.empty || status === PageLoaderStatus.error
    );
  }

  selectActive$: Observable<boolean> = this.statusSubj.pipe(
    map(
      (status) =>
        status === PageLoaderStatus.loading || status === PageLoaderStatus.empty || status === PageLoaderStatus.error
    )
  );

  canShowLoading() {
    const status = this.statusSubj.getValue();
    return status === PageLoaderStatus.empty || status === PageLoaderStatus.error;
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  private calcStatusFromInputApi() {
    if (this.loading) {
      return PageLoaderStatus.loading;
    }
    if (this.error) {
      return PageLoaderStatus.error;
    }
    if (this.loaded > 0 || this.hasData()) {
      return PageLoaderStatus.loaded;
    }
    return PageLoaderStatus.empty;
  }

  private hasData() {
    if (typeof this.data === 'undefined') {
      return false;
    }
    if (Array.isArray(this.data)) {
      return this.data.length > 0;
    }
    return true;
  }
}
