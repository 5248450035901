import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'nxh-loading-option',
  templateUrl: 'loading-option.component.html',
  styleUrls: ['loading-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOptionComponent {
  constructor(@Optional() private ngSelect: NgSelectComponent) {}

  get loadingText() {
    return this.ngSelect?.loadingText;
  }
}
