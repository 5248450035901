import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, of } from 'rxjs';
import { FeatureFlagService } from './state/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagResolver {
  constructor(private featureFlagService: FeatureFlagService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const flagNames = route.data?.flagNames ?? [];
    this.featureFlagService.loadMany(...flagNames).subscribe();
    // non-blocking resolver
    return of(true);
  }
}

/**
 * Blocking feature flag resolver. Will only return once the feature flags have been fetched. It merely returns true
 * denoting that the function has finished its attempt to load the feature flags . To check the actual value of the
 * feature flags, use FeatureFlagQuery.
 */
export function loadFeatureFlags(route: ActivatedRouteSnapshot) {
  const flagNames = route.data?.flagNames ?? [];
  return inject(FeatureFlagService)
    .loadMany(...flagNames)
    .pipe(
      catchError(() => {
        return of(true);
      })
    );
}
