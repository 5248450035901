<button
  #button
  class="btn btn-{{ type }}"
  type="submit"
  [nxhButtonLoading]="submitting$ | async"
  [disabled]="disabled"
  [attr.form]="formId"
  nxhDataTest="submit-button"
>
  <div class="content-container" #ref><ng-content></ng-content></div>
  <ng-container *ngIf="!ref.hasChildNodes()">
    <fa-icon [icon]="icon"></fa-icon><span>{{ label | i18next }}</span>
  </ng-container>
</button>
