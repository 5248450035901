import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ResourceName } from '@nexuzhealth/shared/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumValue } from '../shared/enum-category';
import { EnumCategoryState, EnumCategoryStore } from './enum-category-store.service';

@Injectable({
  providedIn: 'root',
})
export class EnumCategoryQuery extends QueryEntity<EnumCategoryState> {
  constructor(protected store: EnumCategoryStore) {
    super(store);
  }

  selectEnumValues(categoryName: ResourceName): Observable<EnumValue[]> {
    return this.selectEntity(categoryName).pipe(map((category) => category?.values ?? []));
  }

  getEnumValues(categoryName: string): EnumValue[] {
    const entity = this.getEntity(categoryName);
    return entity ? entity.values : [];
  }

  getEnumValue(categoryName: string, enumValueName: ResourceName) {
    return this.getEnumValues(categoryName).find((value) => value.name === enumValueName);
  }
}
