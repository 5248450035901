import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormHelperDirective } from '@nexuzhealth/shared/ui-toolkit/forms';

@Component({
  selector: 'nxh-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFooterComponent {
  @HostBinding('class')
  _hostClasses = 'card-footer nxh-card-component';

  @Input()
  @HostBinding('class.nxh-card-footer-dark')
  dark = true;

  @Input() connectNxhForm!: FormHelperDirective;
}
