import { escapeRegExp } from 'lodash-es';

export function formatBold(value: string, config: string | { queryText?: string; position: 'start' | 'global' }) {
  if (!value || !config || typeof value !== 'string') {
    return value;
  }

  if (typeof config === 'string') {
    return setBold(value, config);
  }

  if (!config.queryText) {
    return value;
  }

  return setBold(value, config.queryText, config.position);
}

function setBold(value: string, queryText: string, position: 'start' | 'global' = 'global') {
  const sanitizedQueryText = escapeRegExp(queryText);
  // cf. https://stackoverflow.com/questions/55241693/apply-bold-text-on-part-of-string-angular
  const caseInsensitiveRegex =
    position === 'start' ? new RegExp(`(^${sanitizedQueryText})`, 'i') : new RegExp(`(${sanitizedQueryText})`, 'gi');
  return value.replace(caseInsensitiveRegex, '<span class="fw-bold">$1</span>');
}
