import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { sortBy } from 'lodash-es';

@Pipe({
  name: 'i18nextOption',
})
export class I18nextOptionPipe<T> implements PipeTransform {
  constructor(private i18next: I18NextPipe) {}

  transform<K extends keyof T>(value: T | T[], translateField: K, sort = false) {
    if (!value) {
      return value;
    }

    if (value instanceof Array) {
      const result = value.map((option) => this.translateOption(option, translateField));
      return sort ? sortBy([...result], translateField) : result;
    } else {
      return this.translateOption(value, translateField);
    }
  }

  private translateOption<K extends keyof T>(option: T, translateField: K) {
    const value = option[translateField];
    if (typeof value !== 'string') {
      console.warn(`Cannot translate invalid key: ${value}`);
      return option;
    }
    return { ...option, [translateField]: this.i18next.transform(value) };
  }
}
