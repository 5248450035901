<form class="eid-block highlight-block highlight-block--primary" [formGroup]="form" nhForm #f="nhForm">
  <div class="eid-block__section">
    <p>
      {{ intro }}
    </p>
    <p class="eid-buttons">
      <button
        class="btn btn-primary"
        [disabled]="(eidStateSubj | async) === 'loading' || form.disabled"
        (click)="openEidPopup()"
      >
        <fa-icon [icon]="idCardIcon"></fa-icon>
        <span class="no-capitalization">{{ 'read-eid-card' | i18next }}</span>
      </button>
      <button
        nxh-button
        [disabled]="(eidStateSubj | async) === 'loading' || form.disabled"
        (click)="openEidPopup(true)"
        *featureFlag="'featureflags/mock-eid-supported'"
      >
        <fa-icon [icon]="idCardIcon"></fa-icon>
        <span class="no-capitalization">{{ '_eid._mock-reader.open' | i18next }}</span>
      </button>
    </p>
    <ng-container [ngSwitch]="eidStateSubj | async">
      <p *ngSwitchDefault>
        {{ '_eid._modal.insert-eid' | i18next }}
      </p>
      <ng-container *ngSwitchCase="'loading'">
        <nxh-tiny-loader [loading]="true" [loadingMessage]="'_eid.loading-data' | i18next"></nxh-tiny-loader>
        <p>
          {{ '_eid._modal.insert-eid' | i18next }}
          {{ 'cannot-see-window' | i18next | sentenceCase }}
          <span class="btn-link" (click)="openEidPopup()">{{ 'try-again' | i18next | sentenceCase }}</span>
        </p>
      </ng-container>
      <div *ngSwitchCase="'loaded'">
        <div *ngIf="detailViewSubj | async as view">
          <p class="text-success" *ngIf="ssinMatch(view)">
            <fa-icon icon="check-circle"></fa-icon>&nbsp;{{ '_eid._success.loading-data' | i18next }}
          </p>
          <ng-content select="[ssin-no-match]" *ngIf="!ssinMatch(view)"></ng-content>
          <nxh-eid-detail [view]="view"></nxh-eid-detail>
        </div>
      </div>
      <p *ngSwitchCase="'error'" class="text-danger">
        <fa-icon icon="times-circle"></fa-icon>&nbsp;{{ '_eid._error.loading-data' | i18next | sentenceCase }}
      </p>
    </ng-container>
  </div>
  <ng-container *ngIf="manual">
    <div class="split-line">
      <span>{{ 'or' | i18next | uppercase }}</span>
    </div>
    <div class="eid-block__section">
      <p class="title-limited">{{ '_informed-consent.manual-patientdata-entry' | i18next }}</p>
      <div class="nh-row">
        <div class="form-group nh-col-6">
          <nh-control>
            <label class="no-capitalization"> {{ 'eid-number' | i18next }}</label>
            <input
              class="form-control"
              name="eid"
              id="eid"
              type="text"
              formControlName="eid"
              mask="A00-0000000-00 || A00000000 || A000000000"
              [showMaskTyped]="false"
            />
            <val-errors>
              <ng-template valError="invalidEid">{{ '_personal.eid-invalid' | i18next }}</ng-template>
            </val-errors>
          </nh-control>
        </div>
        <div class="form-group nh-col-6">
          <nh-control>
            <label class="no-capitalization"> {{ 'isi-plus-number' | i18next }}</label>
            <input
              class="form-control"
              name="isi"
              id="isi"
              type="text"
              formControlName="isi"
              mask="0 000 000 000"
              nxhDataTest="isi"
            />
            <val-errors>
              <ng-template valError="invalidIsi">{{ '_personal.isi-plus-invalid' | i18next }}</ng-template>
            </val-errors>
          </nh-control>
        </div>
      </div>
      <val-errors [control]="form">
        <ng-template valError="eid-or-isi-required">
          <div class="nh-row">
            {{ eidOrIsiErrorMessage | sentenceCase }}
          </div>
        </ng-template>
      </val-errors>

      <nh-control [label]="'_eid.no-card-reason' | i18next" [required]="true">
        <ng-select
          [items]="reasonOptions$ | async"
          formControlName="reasonNoCardReading"
          bindLabel="description"
          bindValue="name"
          [appendTo]="'body'"
          nxhDataTest="reasonNoCardReading"
        ></ng-select>
        <val-errors></val-errors>
      </nh-control>

      <nh-control *ngIf="!!form.get('reasonFreeText')">
        <input formControlName="reasonFreeText" [placeholder]="'_eid._reason-free-text.placeholder' | i18next" />
      </nh-control>
    </div>
  </ng-container>
</form>
