<div class="shortscreen-warning">
  <div class="shortscreen-warning__content">
    <div class="shortscreen-warning__logo">
      <img src="/assets/theming-default/nxh-pro-logo.svg" />
    </div>
    <div class="shortscreen-warning__illustration" *ngIf="isIOS || isAndroid">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 912.8 794.6">
        <style type="text/css">
          .st0 {
            fill: none;
            stroke: #0c1827;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
          .st1 {
            fill: none;
            stroke: #d12f30;
            stroke-width: 15;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
          .st2 {
            fill: none;
            stroke: #02875b;
            stroke-width: 15;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
          }
        </style>
        <g>
          <g>
            <polygon class="st0" points="806.5,608.6 423.5,608.6 423.5,349.6 807.5,349.6 		" />
            <path
              class="st0"
              d="M505.5,643.6h-89c-32-1-34-33-34-33v-266c0-29,29-31,29-31h404c30,1,33,32,33,32v263c0,36-31,37-31,37h-105"
            />
            <line class="st0" x1="404.5" y1="471.6" x2="404.5" y2="488.6" />
            <path
              class="st0"
              d="M550,743.1l18.5-32c39.5-8.5,70-33,70-33c7-5.5,16.5-6.5,16.5-6.5c45.5-4,36-21.5,36-21.5c-14-34-78-8-78-8
              c-67.5-16.5-87,14-87,14"
            />
            <path class="st0" d="M445.5,706.1c20-24.5,27.5-46,27.5-46" />
            <path class="st0" d="M541,715.6c-34.5-38-79-33.5-79-33.5" />
          </g>
          <g>
            <path class="st0" d="M145.5,258.6v-107c0-28,29-31,29-31h271c27,0,32,26,32,26v145" />
            <polyline class="st0" points="440.5,292.6 440.5,161.6 180.5,161.6 180.5,544.6 360.5,544.6 		" />
            <path class="st0" d="M144.5,461.6v95c1,29,32,32,32,32H359" />
            <path class="st0" d="M415.5,54.6c188.7-13.3,165,166,165,166l21-20" />
            <line class="st0" x1="558.5" y1="201.6" x2="580.5" y2="220.6" />
            <path class="st0" d="M83,524.4c24-20.2,47.2-28.5,47.2-28.5" />
            <path class="st0" d="M75.5,429.1c33,27,33.7,76.5,33.7,76.5" />
            <path
              class="st0"
              d="M47.7,418.6l30.7-16.5c12-43.5,33.7-69.7,33.7-69.7c6-9.7,6-22.5,6-22.5c2.2-43.5,25.5-30,25.5-30
              c21,8.2,13.5,47.2,13.5,47.2c-1.5,15.7-8.2,30.7-8.2,30.7c15.7,66-15.7,86.2-15.7,86.2"
            />
          </g>
          <line class="st1" x1="267.5" y1="304.6" x2="356" y2="393.1" />
          <line class="st1" x1="356" y1="304.6" x2="267.5" y2="393.1" />
          <polyline class="st2" points="575,459.8 609.3,522.9 663.5,423.1 	" />
        </g>
      </svg>
    </div>
    <div class="shortscreen-warning__title">{{ '_shortscreen-warning.title' | i18next }}</div>
    <div class="shortscreen-warning__description">
      {{ '_shortscreen-warning.message' | i18next }}
    </div>
  </div>
</div>
<div class="nav__container nav-2">
  <nav class="nav__main-nav">
    <nxh-nav-item-home [logoSize]="homeLogoSize" [homeLink]="homeRouterLink"></nxh-nav-item-home>
    <div class="nav__title" *ngIf="title">{{ title }}</div>
    <div class="nav__menus">
      <ul class="nav__menu--left">
        <ng-content select=".left"></ng-content>
      </ul>
      <div class="nav__menu--right">
        <ng-content select=".right"></ng-content>
      </div>
    </div>
  </nav>
  <nav class="nav__breadcrumbs" *ngIf="!hideBreadcrumb">
    <nxh-breadcrumb></nxh-breadcrumb>
  </nav>
</div>
