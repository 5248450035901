import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-nav-item-simple',
  templateUrl: './nav-item-simple.component.html',
  styleUrls: ['./nav-item-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemSimpleComponent {
  @Input() navItem: NavbarItem;
}

export interface NavbarItem {
  label: string;
  url: string;
  text?: string;
  target?: NavTarget;
  featureFlag?: string;
}

export enum NavTarget {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}
