import { Pipe, PipeTransform, SecurityContext, VERSION } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Converts newlines to br. Also converts < and > to &lt; and &gt;
 */
@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, sanitizeBeforehand?: boolean): string {
    if (typeof value !== 'string') {
      return value;
    }
    let result: any;
    const textParsed = value
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/(?:\r\n|\r|\n)/g, '<br />');

    if (!VERSION || VERSION.major === '2') {
      result = this.sanitizer.bypassSecurityTrustHtml(textParsed);
    } else if (sanitizeBeforehand) {
      result = this.sanitizer.sanitize(SecurityContext.HTML, textParsed);
    } else {
      result = textParsed;
    }

    return result;
  }
}
