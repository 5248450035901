import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, Renderer2 } from '@angular/core';
import { FormGroupDirective, FormGroupName } from '@angular/forms';
import { GroupComponent } from './group.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-input-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss', './input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputGroupComponent extends GroupComponent {
  constructor(
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    @Optional() public formGroupName: FormGroupName,
    @Optional() public formGroupDirective: FormGroupDirective
  ) {
    super(renderer, cdr, formGroupName, formGroupDirective);
    this.hideCatchAllErrorMessage = false;
  }
}
