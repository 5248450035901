import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, Patient } from '@nexuzhealth/shared/domain';
import { PatientApiService } from '@nexuzhealth/shared/patient/data-access';
import { PopoverRef } from '@nexuzhealth/shared/ui-toolkit/popover';
import { TypeaheadOption } from '@nexuzhealth/shared/ui-toolkit/typeahead';
import { uiToolkitAnimations } from '@nexuzhealth/shared/ui-toolkit/animations';
import { AssignedIdService, formatPersonName } from '@nexuzhealth/shared/util';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nxh-nav-item-patient-search',
  templateUrl: './nav-item-patient-search.component.html',
  styleUrls: ['./nav-item-patient-search.component.scss'],
  animations: [uiToolkitAnimations.changeSize],
})
export class NavItemPatientSearchComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() origin: ElementRef;
  @ViewChild('searchField', { static: true }) searchField: ElementRef<HTMLInputElement>;
  collapsed$ = new BehaviorSubject(true);
  term$ = new BehaviorSubject(null);
  focusPopoverRef: PopoverRef;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.collapsed$.next(true);
      this.searchField.nativeElement.value = '';
    }
  }

  searchPatients = (term) =>
    this.patientApi.fetchPatients({ searchString: this.getSearchValue(term) }, '', 'BASIC', 4).pipe(
      map((result) => {
        return result.data;
      }),
      map((patients) =>
        patients.map((patient) => {
          const option: TypeaheadOption<Patient> = {
            value: patient,
            display: formatPersonName(patient.personName),
          };
          return option;
        })
      )
    );

  constructor(
    private patientApi: PatientApiService,
    private router: Router,
    private assignedIdService: AssignedIdService,
    private _elementRef: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.collapsed$
      .asObservable()
      .pipe(
        filter((collapsed) => !collapsed),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.searchField.nativeElement.focus();
        });
      });

    this.term$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.focusPopoverRef) {
        this.focusPopoverRef.close();
        this.focusPopoverRef = null;
      }
    });
  }

  expandSearchField($event) {
    $event.preventDefault();
    this.collapsed$.next(false);
  }

  public clear($event) {
    $event.preventDefault();
    this.searchField.nativeElement.value = '';
    this.searchField.nativeElement.focus();
  }

  expandAnimDone() {
    const isExpanded = !this.collapsed$.getValue();
    if (isExpanded) {
      // todo wip
      // this.showRecentSearches(this.origin, this.focusTemplate);
    }
  }

  getSearchValue(searchField: string) {
    return searchField.replace(new RegExp('(\\.|-|,|:)', 'g'), '');
  }

  selectOption(option: TypeaheadOption<Patient>) {
    if (option) {
      const patient = option.value;
      const route = ['/patients', patient.name];
      if (!this.authService.isAuthorized(AccessRole.clinical)) {
        route.push('administrative-dossier');
      }
      this.router.navigate(route);
    } else {
      this.router.navigate(['/patients/list'], { queryParams: { term: '', advanced: true } });
    }
  }

  getTerm() {
    if (!this.term$.getValue()) {
      return null;
    }

    return this.term$.getValue().toString();
  }

  allResults() {
    this.router.navigate(['/patients/list'], { queryParams: { term: this.getTerm() } });
  }

  advancedSearch() {
    this.router.navigate(['/patients/list'], { queryParams: { term: '', advanced: true } });
  }

  getInss(patient: Patient) {
    return this.assignedIdService.getInss(patient.assignedIds);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
