import { Observable } from 'rxjs';

export function resizeObservable(elem: HTMLElement): Observable<ResizeObserverEntry[]> {
  return new Observable<ResizeObserverEntry[]>((subscriber) => {
    const observer = new ResizeObserver((entries) => {
      subscriber.next(entries);
    });

    observer.observe(elem);

    return () => observer.unobserve(elem);
  });
}
