import { Facet as InsurabilityFacet } from '@nexuzhealth/shared/finance/data-access-insurability';
import { ComponentSettings } from '@nexuzhealth/shared/settings/data-access-settings';

export const componentsConfig: ComponentSettings = {
  components: {
    'eid-modal-component': {
      doVerifyId: true,
    },
    'patient-page': {
      loadOpenContact: true,
    },
    patient: {
      'search-full': {
        displayedColumns: ['patient', 'inss', 'phoneNumber', 'birthDay', 'home-address', 'actions'],
        simpleSearchEnabled: true,
        advancedSearchEnabled: true,
      },
    },
    'patient-search-result-table-INSS': {
      displayedColumns: ['givenNames', 'lastName', 'ssin', 'genderName', 'birthDate'],
      createAddress: false,
    },
    'patient-search-result-table-PHONETIC': {
      displayedColumns: [
        'givenNames',
        'lastName',
        'ssin',
        'genderName',
        'birthDate',
        'birthPlace',
        'residentialAddress',
      ],
      createAddress: false,
    },
    'health-element-page': {
      showValidationStatus: false,
      showValidatedBy: true,
      'health-elements-title': '_ehrbase._health-element-overview.title-gp',
      motivationsRequired: false,
    },
    'administrative-dossier': {
      insurability: {
        facets: [
          InsurabilityFacet.insurability,
          InsurabilityFacet.carePath,
          InsurabilityFacet.referencePharmacy,
          InsurabilityFacet.chronicCondition,
          InsurabilityFacet.palliative,
          InsurabilityFacet.hospital,
          InsurabilityFacet.generalsituation,
        ],
        multiplePeriods: false,
        titleType: 'short',
        thirdPayerVisible: true,
      },
    },
    'therapy-history-page': {
      showValidationStatus: true,
    },
    sumehr: {
      canExport: true,
    },
    sidebar: {
      summary: {
        displayedWidgets: [
          'diagnosis-and-treatment',
          'antecedents',
          'chronic-medication',
          'product-abuse',
          'vaccination',
          'care-plan',
          'parameters',
        ],
      },
    },
    parameters: {
      showObservationFunctionality: false,
    },
    messages: {
      defaultOutlets: {
        sidebar: ['summary'],
      },
    },
  },
  services: {
    healthElementQuery: {
      onlyShowValidatedHealthElements: true,
    },
  },
  //Shared routes defined in documents-routing.module.ts
  routeconfig: {
    documents: {
      routes: [
        'internal',
        'ehealth-hub',
        'journal',
        ':documentName',
        'new',
        'referral-letter',
        'custom',
        'medical-certificate',
        'physiotherapy-prescription',
        'nursing-prescription',
        'handicare-request',
        'medical-imaging-request',
        'e-form',
        'e-form-covid-19',
      ],
    },
  },
};
