<div class="icon-pill-content-container">
  <ng-content select="fa-icon"></ng-content>

  <span class="pill-content">
    <ng-content></ng-content>
  </span>
</div>

<div class="pill-buttons">
  <ng-content select="button"></ng-content>
  <button
    #edit
    nxh-button
    [buttonType]="'icon'"
    [ngbTooltip]="editTooltip"
    [disableTooltip]="!editTooltip"
    (click)="editClick.emit()"
    *ngIf="editable"
  >
    <fa-icon icon="pen"></fa-icon>
  </button>
  <button
    #close
    nxh-button
    [buttonType]="'icon'"
    [ngbTooltip]="closeTooltip"
    [disableTooltip]="!closeTooltip"
    (click)="closeClick.emit()"
    *ngIf="closeable"
  >
    <fa-icon icon="times"></fa-icon>
  </button>
</div>
