import { Store, StoreConfig } from '@datorama/akita';
import { Certificate } from '@nexuzhealth/shared/ehealth/domain';
import { Injectable } from '@angular/core';

export interface CertificateState {
  user?: Certificate;
  organisation?: Certificate;
}

export function createInitialState(): CertificateState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'certificate' })
export class CertificateStore extends Store<CertificateState> {
  constructor() {
    super(createInitialState());
  }
}
