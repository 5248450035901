/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, HostBinding, Input } from '@angular/core';
import { TableStylingConfig } from './table-styling.model';

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    'table[cdk-table]',
  standalone: true,
})
export class TableStylingDirective {
  @Input() tableStyling?: Partial<TableStylingConfig>;
  @Input('tableStyling.bordered') bordered = false;
  @Input('tableStyling.clickable') clickable = false;
  @Input('tableStyling.hoverable') hoverable = false;
  @Input('tableStyling.striped') striped = false;
  @Input('tableStyling.rowspan') rowspan = false;
  @Input('tableStyling.small') small = false;
  @Input('tableStyling.large') large = false;

  @HostBinding('class.table') isTable = true;

  @HostBinding('class.table-bordered') get isBordered() {
    return this.tableStyling?.bordered || this.bordered;
  }

  @HostBinding('class.table--clickable') get isClickable() {
    return this.tableStyling?.clickable || this.clickable;
  }

  @HostBinding('class.table--striped') get isStriped() {
    return this.tableStyling?.striped || this.striped;
  }

  @HostBinding('class.table--hoverable') get isHoverable() {
    return this.tableStyling?.hoverable || this.hoverable;
  }

  @HostBinding('class.table--rowspan') get isRowspan() {
    return this.tableStyling?.rowspan || this.rowspan;
  }

  @HostBinding('class.table--small') get isSmall() {
    return this.tableStyling?.small || this.small;
  }

  @HostBinding('class.table--large') get isLarge() {
    return this.tableStyling?.large || this.large;
  }
}
