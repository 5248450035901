export enum AssignedIdTypenameEnum {
  BE_INSS = 'enums/common-identifier-type/values/be-inss',
  BE_RIZIV = 'enums/common-identifier-type/values/be-nihii',
  BE_NIHII_ORG = 'enums/common-identifier-type/values/be-nihii-org',
  // DEPRECATED: can be removed with GP-4823
  BE_EID = 'enums/common-identifier-type/values/be-eidcardnb',
  // DEPRECATED: can be removed with GP-4823
  BE_ISI = 'enums/common-identifier-type/values/be-isicardnb',
  BE_MUTUALITY = 'enums/common-identifier-type/values/be-mutuality',
  BE_EHP = 'enums/common-identifier-type/values/be-ehp',
  BE_CBE = 'enums/common-identifier-type/values/be-cbe',
  BE_QUALIFICATION = 'enums/common-identifier-type/values/be-qualification',
  BE_EHBX_QUALITY = 'enums/common-identifier-type/values/ehealth-box-quality',
  LINKED_PATIENT_ID = 'enums/common-identifier-type/values/linked-patient-id',
}

export enum OrganisationIdentifierTypenameEnum {
  BE_VACCINNET_ORG = 'enums/common-identifier-type/values/be-vaccinnet-org',
  OAUTH2 = 'enums/common-identifier-type/values/google-oauth2',
  AUTH0XXX = 'enums/common-identifier-type/values/auth0XXX',
  AUTH0 = 'enums/common-identifier-type/values/auth0',
  NH_LOGIN = 'enums/common-identifier-type/values/nh-login',
}

export enum AssignedIdSourceEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  EID = 'EID',
  CoBRHA = 'CoBRHA',
  RIZIV_DOWNLOAD = 'RIZIVDOWNLOAD',
  sumEHR = 'sumEHR',
  CONSULTRN_PERSONSERVICE = 'CONSULTRN_PERSONSERVICE',
  CONSULTRN_BIS = 'CONSULTRN_BIS',
  IDSUPPORT = 'IDSUPPORT',
  SSINHISTORY = 'SSINHISTORY',
}

export enum AssignedIdSourceTypeEnum {
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUBJECT = 'SUBJECT',
  UNSPECIFIED = 'SOURCE_TYPE_UNSPECIFIED',
}

export interface AssignedId {
  typeName: AssignedIdTypenameEnum | OrganisationIdentifierTypenameEnum;
  value: string;
  source?: AssignedIdSourceEnum;
  sourceType?: AssignedIdSourceTypeEnum;

  endTime?: string;
  name?: string;
  sendVersion?: string;
  startTime?: string;

  confirmSource?: AssignedIdSourceEnum;
  confirmTime?: string;
}
