import { Component, Inject, InjectionToken } from '@angular/core';

import { TypeaheadOption } from './typeahead-option.model';

export const TYPEAHEAD_OPTION_CONFIG = new InjectionToken<string>('TypeaheadOptionConfig');

@Component({
  selector: 'nxh-typeahead-option',
  template: ` <span [innerHTML]="optionConfig?.option?.display | bold : optionConfig?.term"></span> `,
})
export class TypeaheadOptionComponent {
  constructor(@Inject(TYPEAHEAD_OPTION_CONFIG) public optionConfig: TypeaheadOptionConfig) {}
}

export interface TypeaheadOptionConfig {
  option: TypeaheadOption<any>;
  active: boolean;
  term: string;
}
