import { ModuleWithProviders, NgModule } from '@angular/core';

import { PartialDatePipe } from './partial-date.pipe';

@NgModule({
  exports: [PartialDatePipe],
  declarations: [PartialDatePipe],
})
export class PartialDatePipeModule {
  static forRoot(): ModuleWithProviders<PartialDatePipeModule> {
    return {
      ngModule: PartialDatePipeModule,
      providers: [PartialDatePipe],
    };
  }
}
