import { Injectable } from '@angular/core';
import {
  AssignedId,
  AssignedIdSourceEnum,
  AssignedIdSourceTypeEnum,
  AssignedIdTypenameEnum,
} from '@nexuzhealth/shared/domain';

function getAssignedIdFactory(type: AssignedIdTypenameEnum) {
  return (assignedIds: AssignedId[]) => {
    // TODO: can be removed with GP-4825
    if (type === AssignedIdTypenameEnum.BE_EID || type === AssignedIdTypenameEnum.BE_ISI) {
      console.warn('EID and ISI+ identifiers are deprecated (GDRP related issue)');
    }

    return assignedIds.find((v) => v.typeName === type);
  };
}

@Injectable({
  providedIn: 'root',
})
export class AssignedIdService {
  getBE_CBE = getAssignedIdFactory(AssignedIdTypenameEnum.BE_CBE);
  getBE_EHP = getAssignedIdFactory(AssignedIdTypenameEnum.BE_EHP);
  getRiziv = getAssignedIdFactory(AssignedIdTypenameEnum.BE_RIZIV);
  getNihiiOrg = getAssignedIdFactory(AssignedIdTypenameEnum.BE_NIHII_ORG);
  getInss = getAssignedIdFactory(AssignedIdTypenameEnum.BE_INSS);
  // DEPRECATED: can be removed with GP-4825
  getEid = getAssignedIdFactory(AssignedIdTypenameEnum.BE_EID);
  // DEPRECATED: can be removed with GP-4825
  getIsi = getAssignedIdFactory(AssignedIdTypenameEnum.BE_ISI);
  getMutuality = getAssignedIdFactory(AssignedIdTypenameEnum.BE_MUTUALITY);

  createInss(inss: string, original?: AssignedId): AssignedId {
    if (original) {
      return { ...original, value: inss };
    }

    return {
      typeName: AssignedIdTypenameEnum.BE_INSS,
      value: inss,
      source: AssignedIdSourceEnum.UNSPECIFIED,
      sourceType: AssignedIdSourceTypeEnum.SUBJECT,
    };
  }

  // todo after minilab -> refactor to take INNS into account
  upsertAssignedId(value: string, typeName: AssignedIdTypenameEnum, assignedIds: AssignedId[] | null = []) {
    const index = assignedIds?.findIndex((v) => v.typeName === typeName) ?? -1;

    if (index >= 0) {
      const copy = [...assignedIds];
      copy[index] = this.createAssignedId(value, typeName, assignedIds[index]);
      return copy;
    } else {
      const copy = assignedIds ? [...assignedIds] : [];
      copy.push(this.createAssignedId(value, typeName));
      return copy;
    }
  }

  upsertInss(inss: string, assignedIds: AssignedId[] | null = []) {
    return this.upsertAssignedId(inss, AssignedIdTypenameEnum.BE_INSS, assignedIds);
  }

  private createAssignedId(value: string, typeName: AssignedIdTypenameEnum, original?: AssignedId): AssignedId {
    if (original) {
      return { ...original, value: value, confirmSource: null, confirmTime: null };
    }

    return {
      typeName,
      value,
      sourceType: AssignedIdSourceTypeEnum.ADMINISTRATOR,
    };
  }
}
