import { Injectable } from '@angular/core';
import { action, Store, StoreConfig } from '@datorama/akita';

export interface FormState {
  outlets: { [outletName: string]: { [formName: string]: boolean } };
  isModalDirty: boolean;
}

export function createInitialFormState(): FormState {
  return { outlets: { primary: {} }, isModalDirty: false };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form', resettable: false })
export class FormStore extends Store<FormState> {
  constructor() {
    super(createInitialFormState());
  }

  @action('setIsDirty')
  setIsDirty(formName: string, isDirty: boolean) {
    this.setFormDirty(formName, isDirty);
  }

  @action('setFormDirty')
  setFormDirty(formName: string, isDirty: boolean, outletName = 'primary') {
    this.update((state) => {
      return {
        ...state,
        outlets: {
          ...state.outlets,
          [outletName]: { ...state.outlets[outletName], [formName]: isDirty },
        },
      };
    });
  }

  @action('setModalDirty')
  setModalDirty(isModalDirty: boolean) {
    this.update({ isModalDirty });
  }

  @action('markModalDirty')
  markModalDirty() {
    this.update({ isModalDirty: true });
  }

  @action('markModalPristine')
  markModalPristine() {
    this.update({ isModalDirty: false });
  }

  @action('destroy')
  destroy() {
    this.destroyOutlet('primary');
  }

  @action('destroyContext')
  destroyOutlet(outletName = 'primary') {
    this.update((state) => {
      return { isModalDirty: false, outlets: { ...state.outlets, [outletName]: {} } };
    });
  }

  @action('destroyForm')
  destroyForm(outletName: string, formName: string) {
    this.update((state) => {
      const copy = { ...state.outlets[outletName] };
      delete copy[formName];
      return { isModalDirty: false, outlets: { ...state.outlets, [outletName]: copy } };
    });
  }

  @action('destroyAll')
  destroyAll() {
    this.update(() => {
      return { isModalDirty: false, outlets: { primary: {} } };
    });
  }
}
