import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AkitaNgDevtools, DEVTOOLS_OPTIONS } from '@datorama/akita-ngdevtools';
import {
  AuthQuery,
  AuthService,
  SharedAuthenticationDataAccessAuthModule,
} from '@nexuzhealth/shared/authentication/data-access-auth';
import { SharedAuthenticationModule } from '@nexuzhealth/shared/authentication/feature-authentication';
import { SharedAuthenticationFeatureAuthorizationModule } from '@nexuzhealth/shared/authentication/feature-authorization';
import { EnvironmentVariablesService } from '@nexuzhealth/shared/domain';
import { SharedEhealthFeatureCertificateBarModule } from '@nexuzhealth/shared/ehealth/feature-certificate-bar';
import { SharedEidFeatureModule } from '@nexuzhealth/shared/eid/feature';
import { SharedPatientFeatureNavItemsModule } from '@nexuzhealth/shared/patient/feature-nav-items';
import { ComponentSettingsToken, SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import {
  UserPreferencesQuery,
  UserPreferencesService,
} from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { FEATURE_FLAG_DATASOURCE, FEATURE_FLAG_QUERY } from '@nexuzhealth/shared/settings/feature-flags/ui';
import { PUSH_NOTIFICATION_CONFIG } from '@nexuzhealth/shared/tech/data-access-push-notifications';
import { PushNotificationConfig } from '@nexuzhealth/shared/tech/domain';
import { SharedToolkitFeatureShellModule } from '@nexuzhealth/shared/toolkit/feature-shell';
import { NxhFormsModule } from '@nexuzhealth/shared/ui-toolkit/forms';
import {
  HttpErrorInterceptor,
  HttpTimeoutInterceptor,
  I18nextConfigService,
  preloadAll,
} from '@nexuzhealth/shared/util';
import { SharedToolkitFeatureThemingModule } from '@nexuzhealth/shared/toolkit/feature-theming';
import { PartialDatePipeModule } from '@nexuzhealth/shared/ui-toolkit/l10n/partial-date';
import { ToastrModule } from 'ngx-toastr';
/* eslint-disable-next-line no-restricted-imports -- only allowed here to configure date-picker */
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, frLocale, nlLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { FULLCALENDAR_LICENSE_KEY_TOKEN } from '@nexuzhealth/shared/ui-toolkit/calendar';
import { provideNgxMask } from 'ngx-mask';
import { componentsConfig } from '../components-config';
import { environment } from '../environments/environment';
import { AppShellComponent } from './app-shell/app-shell.component';
import { AppComponent } from './app.component';
import { routes } from './app.routes';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');
// for ngx-datepicker
defineLocale('de', deLocale);
defineLocale('nl', nlLocale);
defineLocale('fr', frLocale);

@NgModule({
  // once we have a solution for ReferenceResolveService we can merge these 2 components
  declarations: [AppComponent, AppShellComponent],
  imports: [
    BrowserAnimationsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    SharedAuthenticationModule.forRoot(environment['servicePorts']),
    SharedToolkitFeatureShellModule,
    RouterModule.forRoot(routes, { bindToComponentInputs: true }),
    SharedAuthenticationFeatureAuthorizationModule,
    SharedEidFeatureModule,
    SharedPatientFeatureNavItemsModule,
    NxhFormsModule,
    SharedEhealthFeatureCertificateBarModule,
    SharedToolkitFeatureThemingModule,
    PartialDatePipeModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    IconsModule.forRoot(),
    SharedAuthenticationDataAccessAuthModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadAll,
      deps: [SettingsService, UserPreferencesService, I18nextConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTimeoutInterceptor,
      multi: true,
    },
    {
      provide: EnvironmentVariablesService,
      useValue: environment,
    },
    { provide: ComponentSettingsToken, useValue: componentsConfig },
    {
      provide: LOCALE_ID,
      useFactory: (userPreferencesQuery: UserPreferencesQuery) => {
        return userPreferencesQuery.getPreferredLanguage();
      },
      deps: [UserPreferencesQuery], //some service handling global settings
    },
    {
      provide: DEVTOOLS_OPTIONS,
      useValue: { actionsBlacklist: ['@@INIT'] },
    },
    {
      provide: PUSH_NOTIFICATION_CONFIG,
      useFactory: (
        authQuery: AuthQuery,
        settings: SettingsService,
        userPrefs: UserPreferencesQuery,
        authService: AuthService
      ) =>
        <PushNotificationConfig>{
          authToken$: authService.getTokenSilently$(),
          url: settings.pushNotificationsUrl,
          headers: {
            'x-client-id': settings.authConfig.clientId,
            'x-client-version': settings.authConfig.clientVersion,
            'x-user-language': userPrefs.getPreferredLanguage(),
            'x-client-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'x-user-context-name': authQuery.getUserContextName(),
          },
          retryOnError: true,
          retryTimeout: 200,
        },
      deps: [AuthQuery, SettingsService, UserPreferencesQuery, AuthService],
    },
    {
      provide: FEATURE_FLAG_QUERY,
      useClass: FeatureFlagQuery,
    },
    {
      provide: FEATURE_FLAG_DATASOURCE,
      useClass: FeatureFlagService,
    },
    {
      provide: FULLCALENDAR_LICENSE_KEY_TOKEN,
      useFactory: (settingsService: SettingsService) => settingsService.fullcalendarLicenseKey,
      deps: [SettingsService],
    },
    provideNgxMask(),
    // {
    //   // GP uses efact v1 api. The service is provided on this level,
    //   // because httpClient acted weird when put in the finance modules in libs/moapr-gp
    //   provide: BaseEfactApiService,
    //   useClass: EfactV1ApiService,
    // },
  ],
})
export class AppModule {}
