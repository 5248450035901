import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  createUrlTreeFromSnapshot,
  Route,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { RoleExpr } from '@nexuzhealth/shared/domain';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { Observable, of } from 'rxjs';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';

const pageNotAuthorizedRoute: Route = {
  path: 'not-authorized',
  component: PageNotAuthorizedComponent,
  data: {
    breadcrumb: '_page-not-authorized.breadcrumb',
  },
};

export const isAuthorizedGuardFactory: (roleExprFactory: RoleExprFactory) => CanActivateFn = (
  roleExprFactory: RoleExprFactory
) => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const featureFlagService = inject(FeatureFlagService);
    const featureFlagQuery = inject(FeatureFlagQuery);
    const injector = inject(EnvironmentInjector);

    const roleExpr$ = roleExprFactory(featureFlagService, featureFlagQuery);

    return roleExpr$.pipe(
      switchMap((roleExpr) =>
        runInInjectionContext(
          injector,
          () => isAuthorizedGuard(roleExpr)(route, state) as Observable<boolean | UrlTree>
        )
      )
    );
  };
};

export const isAuthorizedGuard: (roleExpr: RoleExpr) => CanActivateFn = (roleExpr: RoleExpr) => {
  return (route: ActivatedRouteSnapshot) => {
    const authService = inject(AuthService);

    const authorized$ = of(roleExpr).pipe(
      mergeMap((_roleExpr) => authService.selectAuthorized(_roleExpr).pipe(take(1)))
    );
    return authorized$.pipe(
      map((authorized) => {
        if (authorized) {
          return true;
        }
        const parentRouteConfig = route.parent?.routeConfig;

        // this can only happen when calling the isAuthorizedGuard from collectTabs()!
        if (!parentRouteConfig?.children) {
          return false;
        }

        if (!parentRouteConfig.children.includes(pageNotAuthorizedRoute)) {
          parentRouteConfig.children.unshift(pageNotAuthorizedRoute);
        }

        return createUrlTreeFromSnapshot(route, ['..', 'not-authorized']);
      })
    );
  };
};

export type RoleExprFactory = (
  featureFlagService: FeatureFlagService,
  featureFlagQuery: FeatureFlagQuery
) => Observable<RoleExpr>;
