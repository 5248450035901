import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, InjectionToken, Optional } from '@angular/core';
import { Config, SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { Observable } from 'rxjs';
import { DeepReadonly } from 'utility-types';

export const SERVICE_PORTS = new InjectionToken<ServicePort[]>('Service ports');
export interface ServicePort {
  prefix: string;
  port: string;
}

export abstract class AuthInterceptor implements HttpInterceptor {
  private timeZone: string;
  protected authConfig: DeepReadonly<Config>;

  constructor(
    protected settings: SettingsService,
    protected userPrefQuery: UserPreferencesQuery,
    @Optional() @Inject(SERVICE_PORTS) protected servicePorts: ServicePort[]
  ) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.authConfig = settings.authConfig;
  }

  abstract returnNext(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const regex = /^api\/(.*)$/;
    const matches = request.url.match(regex);
    if (matches === null) {
      // Not an API call
      return next.handle(request);
    }

    const uri = matches[1];

    const serviceConfig = this.servicePorts ? this.servicePorts.find((value) => uri.startsWith(value.prefix)) : null;
    const port = serviceConfig ? serviceConfig.port : this.authConfig.port;

    let url = this.authConfig.endpoint;
    if (port || this.authConfig.port) {
      url += ':' + (port || this.authConfig.port);
    }
    request = request.clone({ url: `${url}/${uri}` });
    if (this.isPublic(uri)) {
      return next.handle(this.setHeaders(request));
    }

    return this.returnNext(request, next);
  }

  private isPublic(uri: string) {
    return this.authConfig.publicRoutes.includes(uri);
  }

  protected setHeaders(request: HttpRequest<unknown>, token?: string, userContextName?: string) {
    let headers = request.headers
      .set('x-client-id', this.authConfig.clientId)
      .set('x-client-version', this.authConfig.clientVersion)
      .set('x-user-language', this.userPrefQuery.getPreferredLanguage());

    if (this.timeZone) {
      headers = headers.set('x-client-timezone', this.timeZone);
    }
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (userContextName) {
      headers = headers.set('x-user-context-name', userContextName);
    }
    return request.clone({ headers });
  }
}
