import { Injectable, Injector } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EidModalV2Component } from '../eid-modal-v2/eid-modal.component';

@Injectable({
  providedIn: 'root',
})
export class EidModalService {
  constructor(private modalService: NgbModal) {}

  openEidModal(injector: Injector): NgbModalRef {
    return this.modalService.open(EidModalV2Component, { injector, size: 'xl' });
  }
}
