import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { IconLoaderModule } from '@nexuzhealth/shared/ui-toolkit/icon-loader';
import { EmptyStateModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/empty-state';
import { TimeoutStateModule } from '@nexuzhealth/shared/ui-toolkit/loading-states/timeout-state';
import { PageLoaderComponent } from './page-loader.component';

@NgModule({
  imports: [CommonModule, IconLoaderModule, EmptyStateModule, SharedUtilI18nModule, TimeoutStateModule],
  exports: [PageLoaderComponent],
  declarations: [PageLoaderComponent],
})
export class PageLoaderModule {}
