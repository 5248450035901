<div class="nav__menu--right__item">
  <div ngbDropdown class="hide-default-down-icon nav__menus__external-links">
    <button class="btn" [ngbTooltip]="'_external-links.tooltip' | i18next" ngbDropdownToggle>
      <fa-icon icon="arrow-up-right-from-square" size="lg"></fa-icon>
    </button>
    <div ngbDropdownMenu>
      <a ngbDropdownItem (click)="navigateToFamhp()">{{ '_medication.adverse-effect' | i18next }}</a>
      <a href="https://www.zorg-en-gezondheid.be/basisvaccinatieschema" target="_blank" ngbDropdownItem>{{
        '_medication._vaccinations.base-scheme-fl' | i18next
      }}</a>
      <a
        href="https://www.vaccination-info.be/contre-quelles-maladies-et-quand-se-faire-vacciner/"
        target="_blank"
        ngbDropdownItem
        >{{ '_medication._vaccinations.base-scheme-wa' | i18next }}</a
      >
    </div>
  </div>
</div>
