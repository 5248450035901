import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address, AssignedIdTypenameEnum, findAddressByUse, Patient } from '@nexuzhealth/shared/domain';
import { PatientSuggestion } from '@nexuzhealth/shared/patient/data-access';

interface ArTr {
  activeTherapeuticRelation?: boolean;
  activeAdministrativeRelation?: boolean;
}
type ExtendedPatient = Patient & ArTr;

@Component({
  selector: 'nxh-suggestion-table',
  templateUrl: './suggestion-table.component.html',
  styleUrls: ['./suggestion-table.component.scss'],
})
export class SuggestionTableComponent {
  @Input() patients: Array<ExtendedPatient>;
  @Input() columsToExclude: Set<string>;
  @Output() pickedPatient = new EventEmitter();
  readonly SsinType = AssignedIdTypenameEnum.BE_INSS;
  activeId = null;

  displayedColumns = ['patient', 'inss', 'birthDay', 'gender', 'home-address', 'adm-relation', 'relation'];

  constructor() {}

  selectRow(patient: Patient, index: number) {
    this.activeId = this.activeId === index ? null : index;

    if (this.activeId === null) {
      this.pickedPatient.emit(null);
      return;
    }
    this.pickedPatient.emit(patient);
    return;
  }

  getDisplayedColumns() {
    return this.displayedColumns.filter((name) => {
      return !this.columsToExclude.has(name);
    });
  }

  officialAddress(addresses: Address[]) {
    return findAddressByUse(addresses, 'Use_OFFICIAL');
  }

  shouldHideColumn(patient: Patient, columnName: string): boolean {
    if (patient.hiddenFields?.find((value) => value === columnName) !== undefined) {
      return true;
    }
    const patientOfficialAddress = this.officialAddress(patient.addresses);
    if (patientOfficialAddress !== undefined) {
      return patientOfficialAddress.hiddenFields?.find((value) => value === columnName) !== undefined;
    }
    return false;
  }
}
