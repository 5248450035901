import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth-query.service';
import { AuthService } from './abstract-auth.service';
import { AuthInterceptor, SERVICE_PORTS, ServicePort } from './abstract-auth.interceptor';

@Injectable()
export class MoaprAuthInterceptor extends AuthInterceptor {
  constructor(
    private auth: AuthService,
    protected settings: SettingsService,
    private authQuery: AuthQuery,
    protected userPrefQuery: UserPreferencesQuery,
    @Optional() @Inject(SERVICE_PORTS) protected servicePorts: ServicePort[]
  ) {
    super(settings, userPrefQuery, servicePorts);
  }

  returnNext(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.auth.getTokenSilently$().pipe(
      mergeMap((token) => {
        const userContextName = this.authQuery.getUserContextName();
        return next.handle(this.setHeaders(request, token, userContextName));
      }),
      catchError((err) => throwError(err)) // TODO: force logout?
    );
  }
}
