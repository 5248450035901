<div class="title-part">
  <ng-content select="nxh-card-title"></ng-content>
</div>
<div class="controls-part">
  <ng-content select="nxh-card-controls"></ng-content>
  <button
    id="collapse-btn"
    nxh-button
    buttonType="icon"
    buttonStatus="neutral"
    nxhDataTest="card-header-chevron-collapse-button"
    tabindex="-1"
    *ngIf="uiCardService.collapsible$ | async"
  >
    <fa-icon [icon]="(uiCardService.collapsed$ | async) ? 'chevron-down' : 'chevron-up'" size="lg"></fa-icon>
  </button>
</div>
