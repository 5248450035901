import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'nxh-page-not-authorized',
  templateUrl: './page-not-authorized.component.html',
  styleUrls: ['./page-not-authorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotAuthorizedComponent {
  faEyeSlash = faEyeSlash;

  constructor(private route: ActivatedRoute) {}

  goBack() {
    history.back();
  }
}
