import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { SimplePopoverComponent } from './simple-popover.component';

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [SimplePopoverComponent],
})
export class PopoverModule {}
