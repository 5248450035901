import { ElementRef } from '@angular/core';

/**
 * Calculates colspan spanning from fromColName till tilColName.
 */
export function calcColspan(displayedCols: string[], fromColName: string, tilColName: string = null) {
  const fromIndex = fromColName ? displayedCols.indexOf(fromColName) : 0;
  const tilIndex = tilColName ? displayedCols.indexOf(tilColName) : displayedCols.length;
  return tilIndex - fromIndex;
}

/**
 * Marks a row as active that contains the given origin, i.e. typically the element that was clicked upon
 */
export function markRowAsActive(origin: HTMLElement | ElementRef<any>, activeClass = 'active'): () => any | void {
  const nativeElement = origin instanceof MouseEvent ? origin.srcElement : origin?.['nativeElement'] ?? origin;
  const tr = findEl(nativeElement, 'TR');
  if (!tr) {
    return null;
  }

  const table = findEl(tr.parentElement, 'TABLE');
  if (!table) {
    return null;
  }

  const existingActive = table.querySelector(`tr.${activeClass}`);
  if (existingActive) {
    existingActive.classList.remove(activeClass);
  }

  tr.classList.add(activeClass);
  return () => tr.classList.remove(activeClass);
}

function findEl(el: HTMLElement, nodeName) {
  if (!el) {
    return null;
  }

  if (el.nodeName === nodeName) {
    return el;
  }

  return findEl(el.parentElement, nodeName);
}
