<ng-container [ngSwitch]="status$ | async">
  <div class="tiny-loading">
    <div class="page-loading-indicator" *ngSwitchCase="'loading'">
      <div class="page-loading-indicator__container">
        <fa-icon icon="spinner" [spin]="true"></fa-icon><label>{{ loadingMessage }}</label>
      </div>
    </div>

    <div *ngSwitchCase="'empty'">
      <div class="empty__title" *ngIf="!emptyStateTemplate; else customEmpty">
        {{ emptyMessage }}
      </div>
    </div>

    <div class="page-loading-error" *ngSwitchCase="'error'">
      <div class="timeout__title" *ngIf="!errorStateTemplate; else customError">
        <fa-icon icon="times-circle"></fa-icon> <label>{{ errorMessage }}</label>
      </div>
    </div>

    <div class="page-loading-error" *ngSwitchCase="'warning'">
      <div class="warning__title" *ngIf="!warningStateTemplate; else customWarning">
        <fa-icon icon="exclamation-circle"></fa-icon> <label>{{ 'general-timeout-warning-title' | i18next }}</label>
      </div>
    </div>

    <div class="page-loading-error" *ngSwitchCase="'success'">
      <div class="success__title" *ngIf="!successStateTemplate; else customSuccess">
        <fa-icon icon="check-circle"></fa-icon> <label>{{ 'general-timeout-success-title' | i18next }}</label>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #customEmpty>
  <ng-container [ngTemplateOutlet]="emptyStateTemplate"></ng-container>
</ng-template>

<ng-template #customWarning>
  <div class="warning__title">
    <fa-icon icon="exclamation-circle"></fa-icon>
    <label><ng-container [ngTemplateOutlet]="warningStateTemplate"></ng-container></label>
  </div>
</ng-template>

<ng-template #customSuccess>
  <div class="success__title">
    <fa-icon icon="check-circle"></fa-icon>
    <label><ng-container [ngTemplateOutlet]="successStateTemplate"></ng-container></label>
  </div>
</ng-template>

<ng-template #customError>
  <div class="timeout__title">
    <fa-icon icon="times-circle"></fa-icon>
    <label><ng-container [ngTemplateOutlet]="errorStateTemplate"></ng-container></label>
  </div>
</ng-template>
