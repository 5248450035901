<div class="table-responsive">
  <table cdk-table [dataSource]="patients" class="table table--clickable table--striped">
    <ng-container cdkColumnDef="patient">
      <th cdk-header-cell *cdkHeaderCellDef>{{ 'patient' | i18next }}</th>
      <td class="align-items-center" cdk-cell *cdkCellDef="let patient">
        <div class="patient-container">
          <span class="patient-name"> {{ patient.personName | formatPersonName }} </span>
        </div>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="inss">
      <th cdk-header-cell *cdkHeaderCellDef>{{ 'inss' | i18next }}</th>
      <td cdk-cell *cdkCellDef="let patient">
        <nxh-blur class="blur" pattern="insz" *ngIf="shouldHideColumn(patient, 'patient.identifier.inss'); else inss" />
        <ng-template #inss>
          {{ patient.assignedIds | findAssignedIdType : SsinType | formatAssignedId }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="gender">
      <th cdk-header-cell *cdkHeaderCellDef>{{ '_personal.gender' | i18next }}</th>
      <td class="align-items-center" cdk-cell *cdkCellDef="let patient">
        <div class="patient-container">
          <span class="patient-name"> {{ patient.administrativeGender | resolveAdministrativeGender }} </span>
        </div>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="birthDay">
      <th cdk-header-cell *cdkHeaderCellDef>{{ 'birthdate' | i18next }}</th>
      <td cdk-cell *cdkCellDef="let patient">
        {{ patient.birthDate?.value | nxhDate : 'shortDate' : 'Europe/Brussels' }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="home-address">
      <th cdk-header-cell *cdkHeaderCellDef>{{ '_address.home' | i18next }}</th>
      <td cdk-cell *cdkCellDef="let patient">
        <nxh-blur class="blur" pattern="boxNumber" *ngIf="shouldHideColumn(patient, 'address.box')" />
        <nxh-blur class="blur" pattern="streetNumber" *ngIf="shouldHideColumn(patient, 'address.number')" />
        <nxh-blur class="blur" size="sm" *ngIf="shouldHideColumn(patient, 'address.street')" />
        {{ officialAddress(patient.addresses) | formatAddress }}
      </td>
    </ng-container>

    <ng-container cdkColumnDef="adm-relation">
      <th cdk-header-cell *cdkHeaderCellDef>{{ '_patient.administrative-relation' | i18next }}</th>
      <td cdk-cell *cdkCellDef="let patient" class="nxh-align-center">
        <fa-icon
          [class.text-success]="patient.activeAdministrativeRelation"
          [class.text-danger]="!patient.activeAdministrativeRelation"
          [icon]="patient.activeAdministrativeRelation ? 'check' : 'times'"
        ></fa-icon>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="relation">
      <th cdk-header-cell *cdkHeaderCellDef>{{ '_patient.relation' | i18next }}</th>
      <td cdk-cell *cdkCellDef="let patient" class="nxh-align-center">
        <fa-icon
          [class.text-success]="patient.activeTherapeuticRelation"
          [class.text-danger]="!patient.activeTherapeuticRelation"
          [icon]="patient.activeTherapeuticRelation ? 'check' : 'times'"
        ></fa-icon>
      </td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="getDisplayedColumns()"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: getDisplayedColumns(); let index = index"
      [class.active]="index === activeId"
      (click)="selectRow(row, index)"
    ></tr>
  </table>
</div>
