import { Injectable } from '@angular/core';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { AuthQuery } from '../state/auth-query.service';

@Injectable({ providedIn: 'root' })
export class LinkService {
  #userContextName: string;

  get userContextName(): string {
    if (!this.#userContextName) {
      this.#userContextName = this.authQuery.getUserContextName();
    }
    return this.#userContextName;
  }

  constructor(private authQuery: AuthQuery, private settings: SettingsService) {}

  organizationPortalLink(uri?: string) {
    const endpoint = this.settings.getSystemSettingValue<string>('organizationPortalEndpoint');
    if (!endpoint) {
      return '';
    }
    const url = uri ? new URL(uri, endpoint) : new URL(endpoint);
    url.search = this.addUserContext(url.searchParams);
    return url.toString();
  }

  userPortalLink(uri?: string) {
    const endpoint = this.settings.getSystemSettingValue<string>('userPortalEndpoint');
    if (!endpoint) {
      return '';
    }
    const url = uri ? new URL(uri, endpoint) : new URL(endpoint);
    url.search = this.addUserContext(url.searchParams);
    return url.toString();
  }

  private addUserContext(params: URLSearchParams): string {
    params.set('userContextName', this.userContextName);
    return params.toString();
  }
}
