import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KwsAuthInterceptor } from './shared/kws-auth.interceptor';
import { MoaprAuthInterceptor } from './shared/moapr-auth.interceptor';
import { SessionService } from './shared/abstract-session.service';
import { KwsSessionService } from './shared/kws-session.service';
import { MoaprSessionService } from './shared/moapr-session.service';
import { AuthService } from './shared/abstract-auth.service';
import { KwsAuthService } from './shared/kws-auth.service';
import { MoaprAuthService } from './shared/moapr-auth.service';

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule],
})
export class SharedAuthenticationDataAccessAuthModule {
  static forRoot(): ModuleWithProviders<SharedAuthenticationDataAccessAuthModule> {
    return {
      ngModule: SharedAuthenticationDataAccessAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: window.bridge ? KwsAuthInterceptor : MoaprAuthInterceptor,
          multi: true,
        },
        { provide: SessionService, useClass: window.bridge ? KwsSessionService : MoaprSessionService },
        { provide: AuthService, useClass: window.bridge ? KwsAuthService : MoaprAuthService },
      ],
    };
  }
}
