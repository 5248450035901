import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @deprecated Use nxh-section instead. See {@link SectionComponent}
 */
@Component({
  selector: 'nxh-card-body-section',
  templateUrl: './card-body-section.component.html',
  styleUrls: ['./card-body-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBodySectionComponent {
  @Input() title: string | null = null;
  @Input() noCapitalization = false;

  constructor() {}
}
