import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CardHeaderComponent } from '../components/card-header/card-header.component';

@Injectable()
export class UiCardService {
  private compactSubject = new BehaviorSubject<boolean>(false);
  compact$: Observable<boolean> = this.compactSubject.asObservable();

  private scrollableSubject = new BehaviorSubject<boolean>(false);
  scrollable$: Observable<boolean> = this.scrollableSubject.asObservable();

  private scrollModeSubject = new BehaviorSubject<ScrollMode>('none');
  scrollMode$: Observable<ScrollMode> = this.scrollModeSubject.asObservable();

  private collapsibleSubject = new BehaviorSubject<boolean>(false);
  collapsible$: Observable<boolean> = this.collapsibleSubject.asObservable();

  private collapsedSubject = new BehaviorSubject<boolean>(false);
  collapsed$: Observable<boolean> = this.collapsedSubject.asObservable();

  private headerSubject = new BehaviorSubject<CardHeaderComponent | null>(null);
  cardBodyNoTopPadding$: Observable<boolean> = this.headerSubject.pipe(
    map((value) => !!value && !value.dark && !value.bottomBorder)
  );

  setCompact(compact: boolean): void {
    this.compactSubject.next(compact);
  }

  setScrollable(scrollable: boolean): void {
    this.scrollableSubject.next(scrollable);
  }

  setScrollMode(scrollMode: ScrollMode): void {
    this.scrollModeSubject.next(scrollMode);
  }

  setCollapsible(collapsible: boolean): void {
    this.collapsibleSubject.next(collapsible);
  }

  setCollapsed(collapsed: boolean): void {
    this.collapsedSubject.next(collapsed);
  }

  toggleCollapsed(): void {
    this.collapsedSubject.next(!this.collapsedSubject.getValue());
  }

  updateHeader(cardHeaderComponent: CardHeaderComponent) {
    this.headerSubject.next(cardHeaderComponent);
  }

  removeHeader() {
    this.headerSubject.next(null);
  }
}

export type ScrollMode = 'none' | 'body' | 'custom';
