import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared/ui-toolkit/focus';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { PREFIX, PrefixDirective } from './prefix.directive';
import { SUFFIX, SuffixDirective } from './suffix.directive';

@Component({
  selector: 'nxh-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    { provide: FOCUSSABLE, useExisting: forwardRef(() => InputComponent) },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements AfterContentInit, OnDestroy, ControlValueAccessor, Focusable {
  @Input() type = 'text'; // TODO maybe we can just provide the required types we want as we already have a data-picker, password and more components
  @Input() placeholder = '';

  @ViewChild('nativeInput', { static: true }) private _nativeInputElement: ElementRef;

  @ContentChildren(PREFIX, { descendants: true }) _prefixChildren: QueryList<PrefixDirective>;
  @ContentChildren(SUFFIX, { descendants: true }) _suffixChildren: QueryList<SuffixDirective>;

  @HostBinding('class.form-control')
  isFormControl = true;

  value: string;
  hasPrefix$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasSuffix$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  onTouch: (_?: any) => void;

  private _destroy$ = new Subject<void>();
  private onChange: (_: string | number | null) => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterContentInit() {
    merge(this._prefixChildren.changes, this._suffixChildren.changes)
      .pipe(startWith(of([])), takeUntil(this._destroy$))
      .subscribe(() => {
        this.checkPrefixAndSuffixExistence();
        this.changeDetectorRef.markForCheck();
      });

    this.setPrefixSuffixColor();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  valueChange(event) {
    this.value = event.target.value;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    this.changeDetectorRef.markForCheck();
  }

  setFocus() {
    this.elementRef.nativeElement.focus();
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', isDisabled.toString());
    this.renderer.setProperty(this._nativeInputElement.nativeElement, 'disabled', isDisabled);
  }

  private checkPrefixAndSuffixExistence() {
    this.hasPrefix$.next(!!this._prefixChildren.length);
    this.hasSuffix$.next(!!this._suffixChildren.length);
  }

  private setPrefixSuffixColor() {
    if (this._prefixChildren.length || this._suffixChildren.length) {
      [...this._prefixChildren.toArray(), ...this._suffixChildren.toArray()].forEach(
        (preSuf: PrefixDirective | SuffixDirective) => {
          this.renderer.setStyle(preSuf.elementRef.nativeElement, 'color', 'var(--disabledColor)');
        }
      );
    }
  }
}
