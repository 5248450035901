import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { key_user_preferences } from '../state/preferences-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesApiService {
  private http: HttpClient;

  // Don't inject HttpClient, it will load all interceptors, which in turn need settings
  // By injecting HttpBackend, we bypass all interceptors
  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getDefaultUserLanguage(): Observable<string> {
    const storedUserPreference = localStorage.getItem(key_user_preferences);
    const preferences = storedUserPreference ? JSON.parse(storedUserPreference) : null;
    if (preferences != null && preferences.preferredLanguage !== '') {
      return of(preferences.preferredLanguage);
    }

    return this.http
      .get<{ preferredLanguage: string }>('/assets/preferences/default-user-language.json')
      .pipe(map(({ preferredLanguage }) => preferredLanguage));
  }
}
