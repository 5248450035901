import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SuggestedPatientsResponse } from '@nexuzhealth/shared/domain';
import { map } from 'rxjs/operators';
import { EidPersonInfo, getTranslationKey, NoCardReadingReason } from './eid-view-model';

export interface TokenResponse {
  userInfo: EidPersonInfo;
  accessToken?: string;
  eidReadingName?: string;
  matchingPatients?: SuggestedPatientsResponse;
}

@Injectable({
  providedIn: 'root',
})
export class EidApiService {
  public static prefix = 'api/pm/id/v1/';

  constructor(private http: HttpClient) {}

  getToken(code: string, redirectUri: string, searchMatch: boolean = false): Observable<TokenResponse> {
    const url = `${EidApiService.prefix}eid-token?code=${code}&redirectUri=${redirectUri}&searchMatch=${searchMatch}`;
    return this.http.get<TokenResponse>(url).pipe(
      map((resp: any) => {
        if (resp.matchingPatients) {
          resp.matchingPatients.suggestions = resp.matchingPatients.suggestions?.data;
        }
        return resp;
      })
    );
  }

  getNoCardReadingReasons(): Observable<string[]> {
    const url = `${EidApiService.prefix}no-card-reading-reasons`;
    return this.http.get<TokenResponse>(url).pipe(map((resp: any) => resp.reasons));
  }

  getNoCardReadingReasonsV2(usecase: string): Observable<NoCardReadingReason[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('usecase', usecase);
    const url = `${EidApiService.prefix}reading-type-reasons`;
    return this.http.get<{ reasons: NoCardReadingReason[] }>(url, { params }).pipe(
      map((resp: any) => {
        const reasons = resp.reasons.map((reason) => {
          reason.mediums = reason.mediums.map((medium) => {
            medium.i18n = getTranslationKey(medium.mediumType, medium.mediumTypeReason);
            return medium;
          });
          return reason;
        });
        return reasons;
      })
    );
  }

  createLog(inss: string, cardNumber): Observable<any> {
    const url = EidApiService.prefix + 'log';
    return this.http.post(url, {
      inss: inss,
      cardnumber: cardNumber,
    });
  }
}
