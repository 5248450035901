import { Injectable } from '@angular/core';
import { action, Store, StoreConfig } from '@datorama/akita';
import { UserPreferences } from '@nexuzhealth/shared/domain';
import { CookieService } from './cookie.service';

export const key_user_preferences = 'nh.user.preferences';

export interface UserPreferencesState {
  preferredLanguage: string;
}

export function createInitialUserPreferencesState(): UserPreferencesState {
  return {
    preferredLanguage: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-preferences' })
export class UserPreferencesStore extends Store<UserPreferencesState> {
  constructor(private cookieService: CookieService) {
    super(createInitialUserPreferencesState());
  }

  @action('setPreferredLanguage')
  setPreferredLanguage(preferredLanguage: string): void {
    this.update({ preferredLanguage });
    if (this.cookieService.canSetCookies()) {
      storeLocal(preferredLanguage);
    }
  }
}

function storeLocal(value: string) {
  const userPreferences = { preferredLanguage: value } as UserPreferences;
  localStorage.setItem(key_user_preferences, JSON.stringify(userPreferences));
}
