import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CountryReference, NationalityReference, ReferenceType, ReferenceTypeState } from '../shared/reference-types';
import { NationalityStore } from './nationality.store';

@Injectable({
  providedIn: 'root',
})
export class NationalityQuery extends QueryEntity<ReferenceTypeState, ReferenceType> {
  private cache = new Map<string, CountryReference>();
  private readonly nationalityTranslationPrefix = '_country.';
  private readonly nationalityTranslationDefault = 'be';

  constructor(protected store: NationalityStore) {
    super(store);
  }

  private getFromTranslationKey(key: string): NationalityReference | undefined {
    if (!this.cache.has(key)) {
      const value = this.getAll().find((country) => country.translationKey.toLocaleLowerCase() === key);
      if (value) {
        this.cache.set(key, value);
      }
    }
    return this.cache.get(key);
  }

  getDefaultNationalityName(): string | undefined {
    const key = this.nationalityTranslationPrefix + this.nationalityTranslationDefault;
    return this.getFromTranslationKey(key)?.name;
  }

  selectBelgian(): Observable<CountryReference | undefined> {
    return this.selectAll().pipe(
      distinctUntilChanged(),
      map(() => this.getFromTranslationKey(this.nationalityTranslationPrefix + 'be')),
      distinctUntilChanged()
    );
  }

  getBelgian(): CountryReference | undefined {
    return this.getFromTranslationKey(this.nationalityTranslationPrefix + 'be');
  }
}
