import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FeatureFlag } from './feature-flag.model';

export type FeatureFlagState = EntityState<FeatureFlag>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'feature-flag', idKey: 'name' })
export class FeatureFlagStore extends EntityStore<FeatureFlagState> {
  constructor() {
    super();
  }
}
