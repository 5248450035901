import { Pipe, PipeTransform } from '@angular/core';
import { generateInitialsFromName } from '@nexuzhealth/shared/util';
import { PersonName } from '@nexuzhealth/shared/domain';

@Pipe({
  name: 'formatInitials',
})
export class FormatInitialsPipe implements PipeTransform {
  transform(personName: PersonName | string): string {
    return generateInitialsFromName(personName);
  }
}
