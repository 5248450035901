import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove, arrayUpdate, resetStores } from '@datorama/akita';
import { AuthQuery } from '@nexuzhealth/shared/authentication/data-access-auth';
import { EidPersonInfo } from '@nexuzhealth/shared/eid/data-access';
import {
  AddressWithContactPoints,
  ContactPointChanges,
  Error,
  Patient,
  PatientAddress,
  ResourceName,
  SuggestedPatientsResponse,
  TherapeuticRelation,
} from '@nexuzhealth/shared/domain';
import { BlobService } from '@nexuzhealth/shared/tech/data-access-blob';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AdministrativeLink, AdministrativeRelationApiService } from '../api/administrative-relation-api.service';
import { MergePatients, PatientApiService, RegisterPatientRequest, Source, View } from '../api/patient-api.service';
import { PatientQuery } from '../state/patient.query';
import { PatientStore } from '../state/patient.store';

const identifyByName = ({ name }: { name?: string }) => {
  return (item) => item.name === name;
};

export interface CreateTherapeuticRelation {
  moaprTr: TherapeuticRelation;
  ehealthRelationCreated;
  ehealthError: Error;
}

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private patientApi: PatientApiService,
    private administrativeRelationApi: AdministrativeRelationApiService,
    private patientStore: PatientStore,
    private patientQuery: PatientQuery,
    private blobService: BlobService,
    private authQuery: AuthQuery
  ) {}

  getPatient(patientName: ResourceName, view: View = null) {
    return this.patientApi.getPatient(patientName, view);
  }

  loadPatient(patientName: ResourceName, setActive = false, loadInBackground = false, view: View = null) {
    if (loadInBackground === false) {
      this.patientStore.setLoading(true);
    }
    return this.patientApi.getPatient(patientName, view).pipe(
      tap((patient) => {
        this.patientStore.upsert(patient.name, patient);
        if (loadInBackground === false) {
          this.patientStore.setLoading(false);
        }
        if (setActive === true) {
          this.patientStore.setActive(patientName);
        }
      }),
      catchError((err) => {
        if (err.kind === 'pm_therapeuticrelation_not-found') {
          console.error('no TR', err);
          return throwError(err);
        }
        return throwError(err);
      })
    );
  }

  loadPatientDetails(patientName = this.patientQuery.getActiveId()) {
    if (this.patientQuery.hasDetails(patientName)) {
      return of();
    }

    return this.patientApi.getPatient(patientName).pipe(
      tap((patient) => this.patientStore.upsert(patient.name, patient)),
      catchError((err) =>
        throwError(`Error while fetching patient with name ${patientName}. Does the patient exist?`, err)
      )
    );
  }

  /**
   * Creates a therapeutic relation and/or an administrative relation. This is a convenience endpoint.
   */
  registerPatient(patientName: ResourceName, params: RegisterPatientRequest): Observable<CreateTherapeuticRelation> {
    return this.patientApi.registerPatient(patientName, params);
  }

  getTr(patientName: ResourceName) {
    return this.patientApi.getTr(patientName);
  }

  createTr(patientName: ResourceName, motivation: string) {
    return this.patientApi.createTr(patientName, motivation);
  }

  createAdministrativeRelation(patientName: ResourceName) {
    return this.patientApi.createAdministrativeRelation(patientName);
  }

  /**
   * Get's the current Administrative Relation and archives it
   */
  archiveAdministrativeRelation(patientName: ResourceName): Observable<AdministrativeLink> {
    const tenant = this.authQuery.getUserContext().tenant;
    if (typeof tenant === 'undefined') {
      throw new Error('no active user');
    }
    return this.administrativeRelationApi.getAdministrativeRelations(patientName).pipe(
      map((links) => {
        const tenantLink = links.find((link) => link.tenantName === tenant.name);
        if (typeof tenantLink === 'undefined') {
          // todo: create a front-end kind for this? This should not happen
          throw new Error('invalid state, no administrative relation with patient');
        }
        return tenantLink;
      }),
      map((link) => {
        return {
          ...link,
          status: 'ARCHIVED',
          statusTime: new Date().toISOString(),
        } as AdministrativeLink;
      }),
      switchMap((link) => {
        return this.administrativeRelationApi.updateAdministrativeRelation(link);
      })
    );
  }

  clearPatient() {
    this.patientStore.setActive(null);
    resetStores({
      exclude: [
        'tenant',
        'session',
        'user',
        'auth',
        'enum-category',
        'user-preferences',
        'config',
        'mud',
        'profession',
        'health-care-worker-profession',
        'country',
        'city',
        'maritalStatus',
        'medium-preference',
        'nationality',
        'language',
        'adminstrative-gender',
        'message',
        'contact-types',
        'codesets',
        'disciplines',
        'recent-patients',
        'feature-flag',
        'theme',
        'picture',
        'certificate',
        'organisation-message-settings',
        'logger',
      ],
    });
  }

  mergePatients(mergePatient: MergePatients) {
    return this.patientApi.mergePatients(mergePatient);
  }

  savePatient(patient: Patient, source: Source) {
    return this.patientApi.savePatient(patient, source).pipe(
      map((p) => ({ deathDate: null, ...p })), // H, because grpc removes null values from response, and as akita does not update missing fields, we make sure null fields are added.
      tap((p) => {
        this.patientStore.update(patient.name, p);
      })
    );
  }

  createPatient(
    patient: Patient,
    createEhealthTherapeuticRelation: boolean,
    identifiers,
    reasonNoCardReading
  ): Observable<Patient> {
    return this.patientApi
      .createPatient(patient, createEhealthTherapeuticRelation, identifiers, reasonNoCardReading)
      .pipe(tap((p) => this.patientStore.add(p)));
  }

  createBisPatient(patient) {
    return this.patientApi.createBisPatient(patient).pipe(tap((p) => this.patientStore.add(p)));
  }

  loadAddresses(patientName: string): Observable<PatientAddress[]> {
    return this.patientApi
      .getAddresses(patientName)
      .pipe(tap((addresses) => this.patientStore.update(patientName, (patient) => ({ ...patient, addresses }))));
  }

  reloadAddress(patientName: ResourceName, patientAddressName: ResourceName): Observable<PatientAddress> {
    return this.patientApi.getAddress(patientAddressName).pipe(
      tap((patientAddress) =>
        this.patientStore.update(patientName, (patient) => ({
          addresses: arrayUpdate(patient.addresses, identifyByName(patientAddress), patientAddress),
        }))
      )
    );
  }

  addAddress(patientName: ResourceName, address: PatientAddress) {
    return this.patientApi.addAddress(patientName, address).pipe(
      tap((savedAddress) => {
        this.patientStore.update(patientName, (patient) => ({
          addresses: arrayAdd(patient.addresses, savedAddress),
        }));
      })
    );
  }

  saveAddress(patientName: ResourceName, address: PatientAddress) {
    return this.patientApi.saveAddress(patientName, address).pipe(
      tap((savedAddress) => {
        // We need to have empty values, because undefined (or null?) will not change the value in arrayUpdate()
        const newAddress = {
          name: savedAddress.name,
          sendVersion: savedAddress.sendVersion || '',
          use: savedAddress.use || '',
          extraLines: savedAddress.extraLines || '',
          street: savedAddress.street || '',
          number: savedAddress.number || '',
          box: savedAddress.box || '',
          postalCode: savedAddress.postalCode || '',
          city: savedAddress.city || '',
          country: savedAddress.country || '',
          comment: savedAddress.comment || '',
          patientName: savedAddress.patientName || '',
        };
        this.patientStore.update(patientName, (patient) => ({
          addresses: arrayUpdate(patient.addresses, identifyByName(savedAddress), newAddress),
        }));
      })
    );
  }

  getInssStatus(patientName: ResourceName) {
    return this.patientApi.getInssStatus(patientName);
  }

  checkData(patientName: ResourceName) {
    return this.patientApi.checkData(patientName);
  }

  checkEidData(patient: any, eidData: any) {
    return this.patientApi.checkEidData(patient, eidData);
  }

  // todo dit wordt niet gebruikt?
  deleteAddress(patientName: ResourceName, address: PatientAddress): any {
    return this.patientApi.deleteAddress(address).pipe(
      tap(() =>
        this.patientStore.update(patientName, (patient) => ({
          // we need a predicate because of our 'name' (iso 'id') id fields
          addresses: arrayRemove(patient.addresses, identifyByName(address)),
        }))
      )
    );
  }

  deleteAddressWithContact(address: PatientAddress): any {
    const patientName = this.patientQuery.getActiveId();
    return this.patientApi.deleteAddressWithContact(address).pipe(
      tap(() =>
        this.patientStore.update(patientName, (patient) => ({
          addresses: arrayRemove(patient.addresses, identifyByName(address)),
          contactPoints: arrayRemove(patient.contactPoints, identifyByName(address)),
        }))
      )
    );
  }

  updateContactPoints(patientName: ResourceName, changes: ContactPointChanges): any {
    return this.patientApi.updateContactPoints(patientName, changes).pipe(
      tap((contactPoints) => {
        contactPoints.forEach((cp) => {
          this.patientStore.update(patientName, (patient) => {
            const existing = patient.contactPoints.find(identifyByName(cp));
            return {
              contactPoints: existing
                ? arrayUpdate(patient.contactPoints, identifyByName(cp), cp)
                : arrayAdd(patient.contactPoints, cp),
            };
          });
        });
        changes.toDelete.forEach((cp) => {
          this.patientStore.update(patientName, (patient) => {
            return {
              contactPoints: arrayRemove(patient.contactPoints, identifyByName(cp)),
            };
          });
        });
      })
    );
  }

  getImportList(pageSize: number, pageToken: string, filters: Record<string, unknown>, options?: { view; type }) {
    const tenant = encodeURI(this.authQuery.getUserContext().tenant.name);
    return this.patientApi.getImportList(tenant, pageSize, pageToken, filters, options);
  }

  getImportErrors(importName) {
    return this.patientApi.getImportErrors(importName);
  }

  searchSuggested(p: EidPersonInfo): Observable<SuggestedPatientsResponse> {
    return this.patientApi.searchPatientsWithSuggestions(p);
  }

  saveImg(patient: Patient, source: Source, imgUrl: string): any {
    return this.savePatient({ ...patient, blobPictureName: imgUrl }, source);
  }

  // todo: write test
  batchUpsertAddressWithContactPoints(patientName: string, data: AddressWithContactPoints, updateMask: string) {
    return this.patientApi.batchUpsertAddressesWithContactPoints(patientName, data, updateMask).pipe(
      tap((result) => {
        this.patientStore.update(result.patient, (patient) => {
          const addresses = [...patient.addresses] || [];
          const contactPoints = [...patient.contactPoints] || [];

          result.addresses.forEach((address) => {
            const idx = addresses.findIndex(({ name }) => name === address.name);
            if (idx === -1) {
              addresses.push(address);
            } else {
              addresses[idx] = address;
            }
          });
          const newPrimaryAddress = result.addresses.find((address) => address.primary);
          addresses.forEach((oldAddress, index) => {
            if (newPrimaryAddress && oldAddress.primary && oldAddress.name !== newPrimaryAddress.name) {
              const updatedAddress = { ...oldAddress, primary: false };
              addresses[index] = updatedAddress;
            }
          });
          result.contactPoints.forEach((contactPoint) => {
            const idx = contactPoints.findIndex(({ name }) => name === contactPoint.name);
            if (idx === -1) {
              contactPoints.push(contactPoint);
            } else {
              contactPoints[idx] = contactPoint;
            }
          });
          result.deletedContactPoints.forEach((deletedContactPointName) => {
            const idx = contactPoints.findIndex(({ name }) => name === deletedContactPointName);
            contactPoints.splice(idx, 1);
          });

          return {
            ...patient,
            addresses,
            contactPoints,
          };
        });
      })
    );
  }
}
