import { Component } from '@angular/core';
import { faTelescope } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'nxh-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  telescope = faTelescope;

  goBack() {
    history.back();
  }
}
