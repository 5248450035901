import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private closeAllSubj = new Subject<void>();
  closeAll$ = this.closeAllSubj.asObservable();

  closeAll() {
    this.closeAllSubj.next();
  }
}
