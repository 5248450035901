<button
  nxh-button
  buttonType="subtle-button"
  buttonStatus="neutral"
  [disabled]="disabled"
  tabindex="-1"
  #button
  (mousedown)="preventLayoutShift($event)"
  (mouseup)="focus()"
  [id]="id + '-button'"
>
  <span #ref><ng-content></ng-content></span>
  <ng-container *ngIf="!ref.hasChildNodes()">
    {{ label | i18next }}
  </ng-container>
</button>
