import { Pipe, PipeTransform } from '@angular/core';
import { AuthQuery, isAuthorized } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, NavItem, NavItemBlock, OrganisationAccessRole } from '@nexuzhealth/shared/domain';

@Pipe({
  name: 'filterUnauthorized',
})
export class FilterUnauthorizedPipe implements PipeTransform {
  constructor(private authQuery: AuthQuery) {}
  transform(items: Array<NavItem | NavItemBlock> | null, hideParentNavItemIfChildrenFilteredOut = false): any {
    if (items === null) return [];
    const roles = this.authQuery.getAllRoles();
    return filterNavItems(items, roles, hideParentNavItemIfChildrenFilteredOut);
  }
}

function filterNavItems(
  items: Array<NavItem | NavItemBlock>,
  roles: Array<OrganisationAccessRole | AccessRole>,
  hideParentNavItemIfChildrenFilteredOut
) {
  return items
    .filter((item) => {
      return isAuthorized(item.roleExpr, roles);
    })
    .map((item) => {
      if (item.items?.length > 0) {
        item = item.items
          ? { ...item, items: filterNavItems(item.items, roles, hideParentNavItemIfChildrenFilteredOut) }
          : item;
        return hideParentNavItemIfChildrenFilteredOut ? nullIfItemsFilteredOut(item) : item;
      } else {
        return item;
      }
    })
    .filter((item) => {
      // filter out all items of which its children have been filtered out.
      return item !== null;
    });
}

function nullIfItemsFilteredOut(item: NavItem | NavItemBlock) {
  const allChildrenFilteredOut = item.items.length === 0;
  return allChildrenFilteredOut ? null : item;
}
