import { AfterViewInit, Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ReferenceQuery, ReferenceType } from '@nexuzhealth/shared/reference-data/data-access';
import { NgSelectComponent } from '@ng-select/ng-select';
import { I18NextPipe } from 'angular-i18next';
import { sortBy } from 'lodash-es';
import { map } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line
export abstract class SelectReferenceDataBaseComponent<T extends ReferenceType>
  implements ControlValueAccessor, AfterViewInit
{
  @Input() clearable = true;
  @Input() placeholder = '';
  @Input() virtualScroll = false;

  @Output() valueChange = new EventEmitter<any>();

  onTouch: any;
  onChange: any;
  @ViewChild('select', { static: true }) select!: NgSelectComponent;

  items$ = this.query.selectAll().pipe(
    map((items) => this.filter(items)),
    map((items) => this.sort(items))
  );

  constructor(private query: ReferenceQuery<T>, protected i18next: I18NextPipe) {}

  /**
   * Overwrite this method if you want to additionaly filter the reference-data
   */
  protected filter(items: T[]): T[] {
    return items;
  }

  protected sort(items: T[]): T[] {
    items = items.map((item) => ({ ...item, label: this.i18next.transform(item.translationKey) }));
    return sortBy(items, 'label');
  }

  ngAfterViewInit(): void {
    this.select.registerOnTouched(this.onTouch);
    this.select.registerOnChange(this.onChange);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.select.setDisabledState(isDisabled);
  }

  writeValue(obj: any): void {
    this.select.writeValue(obj);
  }
}
