import { AbstractControl, ValidationErrors } from '@angular/forms';

export function eidValidator(control: AbstractControl): ValidationErrors | null {
  const eid = control.value;
  if (!eid) return null;
  if (!/^[0-9]{12}$/.test(eid) && !/^[a-zA-Z]{1}[0-9]{9}$/.test(eid) && !/^[0-9]{9}$/.test(eid)) {
    return { invalidEid: true };
  }
  return null;
}

export function isiValidator(control: AbstractControl): ValidationErrors | null {
  const isi = control.value;
  if (!isi) return null;

  if (!isi.match(/^[5-9]{1}[0-9]{9}$/)) return { invalidIsi: true };

  const baseNumber = parseInt(isi.substring(0, 8), 10);
  const ctrlNumber = parseInt(isi.substring(8, 10), 10);

  if (baseNumber % 97 === ctrlNumber) return null;

  return { invalidIsi: true };
}
