import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTitleComponent {
  @Input() title: string | null = null;
  @Input() subTitle: string | null = null;
  @Input() surTitle: string | null = null;
}
