import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  //currently unused because themepicker is unused
  getThemeSettings() {
    if (localStorage.getItem('theme')) {
      const themeWrapper = document.querySelector('body');
      const theme = JSON.parse(localStorage.getItem('theme'));
      themeWrapper.style.setProperty('--primaryColor', theme.globalPrimaryColor);
      themeWrapper.style.setProperty('--primaryColorDark', theme.globalPrimaryColorDark);
      themeWrapper.style.setProperty('--primaryColorWashed', theme.globalPrimaryColorWashed);
      themeWrapper.style.setProperty('--bodyBGColor', theme.globalBodyBGColor);
      themeWrapper.style.setProperty('--bodyTextColor', theme.globalBodyTextColor);
      themeWrapper.style.setProperty('--headerBGColor', theme.globalHeaderBGColor);
      themeWrapper.style.setProperty('--headerBorderColor', theme.globalHeaderBorderColor);
      themeWrapper.style.setProperty('--headerNavItemColor', theme.globalHeaderNavItemColor);
      themeWrapper.style.setProperty('--sidebarNavActiveColor', theme.globalSidebarNavActiveColor);
      themeWrapper.style.setProperty('--patientbarBGColor', theme.globalPatientbarBGColor);
      themeWrapper.style.setProperty('--patientbarTextColor', theme.globalPatientbarTextColor);
      themeWrapper.style.setProperty('--headerBoxShadowColor', theme.globalHeaderBoxShadowColor);
      themeWrapper.style.setProperty('--sidebarBGColor', theme.globalSidebarBGColor);
      themeWrapper.style.setProperty('--cardBGColor', theme.globalCardBGColor);
      themeWrapper.style.setProperty('--gray-100', theme.globalGray100);
      themeWrapper.style.setProperty('--gray-200', theme.globalGray200);
      themeWrapper.style.setProperty('--gray-300', theme.globalGray300);
      themeWrapper.style.setProperty('--gray-400', theme.globalGray400);
      themeWrapper.style.setProperty('--gray-500', theme.globalGray500);
      themeWrapper.style.setProperty('--gray-600', theme.globalGray600);
      themeWrapper.style.setProperty('--gray-700', theme.globalGray700);
      themeWrapper.style.setProperty('--gray-800', theme.globalGray800);
      themeWrapper.style.setProperty('--cardBoxShadowColor', theme.globalCardBoxShadowColor);
    }
  }
}
