import { HttpParams } from '@angular/common/http';
import { isDate } from 'lodash-es';
import { map } from 'rxjs/operators';

/**
 * @deprecated use Record<'data', T> or {data: T}
 */
export interface Envelope<T> {
  data: T;
}

export interface SearchResult<T> {
  data: T[];
  pageSize?: number;
  totalSize?: number;
  pageNumber?: number;
}

//
/**
 * @deprecated use map(x => x.data) or pluck('data') reason being that this actually is not safe, since envelope
 *             can only contain 'data' as key.
 * @param attrField
 */
export function unpack<T>(attrField = 'data') {
  return map((env: Envelope<T>) => env[attrField]);
}

export function setViewFields<T>(...mappers) {
  return map((data: T) => {
    if (data instanceof Array) {
      return data.map((item) => mappers.reduce((prev, mapper) => mapper(prev), item));
    } else {
      return mappers.reduce((prev, mapper) => mapper(prev), data);
    }
  });
}

// PS: do not store in the store, dates get deserialized
export function setDates<T>(...fieldNames: (keyof T)[]) {
  const convertDate = (str: string | null) => {
    const date = new Date(str);
    // eslint-disable-next-line
    // @ts-ignore
    if (isNaN(date)) {
      return null;
    }
    return date;
  };

  return (item: T) =>
    fieldNames.reduce((prev, fieldName) => ({ ...prev, [fieldName]: convertDate(prev[fieldName as string]) }), item);
}

export function getHttpParams(searchParams: { [paramName: string]: any } = {}): HttpParams {
  return Object.keys(searchParams).reduce((params, paramName) => {
    const value = searchParams[paramName];
    if (!value) {
      return params;
    }

    if (Array.isArray(value)) {
      return value.reduce((_params: HttpParams, _value) => {
        return _params.append(paramName, _value);
      }, params);
    }

    if (isDate(value)) {
      return params.set(paramName, value.toJSON());
    }

    return params.set(paramName, value);
  }, new HttpParams());
}
