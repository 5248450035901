import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { PopoverContent } from '@nexuzhealth/shared/ui-toolkit/popover';
import { DetailBubbleService } from '@nexuzhealth/shared/ui-toolkit/detail';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nxh-nav-item-support-bubble',
  templateUrl: './nav-item-support-bubble.component.html',
  styleUrls: ['./nav-item-support-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemSupportBubbleComponent implements OnDestroy {
  @ViewChild('root') root: ElementRef<HTMLElement>;

  private destroy$ = new Subject<void>();
  active$ = new BehaviorSubject(false);

  constructor(private bubble: DetailBubbleService, private i18n: I18NextPipe) {}

  async open() {
    this.active$.next(true);

    const { SupportBubbleComponent } = await import('../support-bubble/support-bubble.component');
    const bubbleRef = this.bubble.show(
      SupportBubbleComponent as unknown as PopoverContent,
      this.root.nativeElement as HTMLElement,
      {
        title: this.i18n.transform('_shell.help-center'),
      }
    );

    bubbleRef.closed$.pipe(takeUntil(this.destroy$)).subscribe(() => this.active$.next(false));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
