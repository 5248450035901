import { And, Condition, Not, Or } from '@nexuzhealth/shared/domain';

export function evalCondition<E>(cond: Condition<E> | E, elements: Array<E>): boolean {
  if (isCondition(cond)) {
    if (isAnd(cond)) {
      return cond.and.every((child) => evalCondition(child, elements));
    } else if (isOr(cond)) {
      return cond.or.some((child) => evalCondition(child, elements));
    } else {
      return evalCondition(cond.not, elements) === false;
    }
  } else {
    return elements.indexOf(cond) > -1;
  }
}

function isCondition<E>(cond: Condition<E> | E): cond is Condition<E> {
  return (<Or<E>>cond).or !== undefined || (<And<E>>cond).and !== undefined || (<Not<E>>cond).not !== undefined;
}

function isAnd<E>(cond: Condition<E>): cond is And<E> {
  return (<And<E>>cond).and !== undefined;
}

function isOr<E>(cond: Condition<E>): cond is Or<E> {
  return (<Or<E>>cond).or !== undefined;
}

function isNot<E>(cond: Condition<E>): cond is Not<E> {
  return (<Not<E>>cond).not !== undefined;
}
