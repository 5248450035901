import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
  imports: [CommonModule, IconsModule, SharedUtilI18nModule],
  exports: [LoadingSpinnerComponent],
  declarations: [LoadingSpinnerComponent],
})
export class LoadingSpinnerModule {}
