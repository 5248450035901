import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReferenceTypeState } from '../shared/reference-types';
import { MaritalStatusStore } from './maritalstatus.store';

@Injectable({
  providedIn: 'root',
})
export class MaritalStatusQuery extends QueryEntity<ReferenceTypeState> {
  constructor(protected store: MaritalStatusStore) {
    super(store);
  }
}
