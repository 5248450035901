import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { AuthInterceptor, SERVICE_PORTS, ServicePort } from './abstract-auth.interceptor';

@Injectable()
export class KwsAuthInterceptor extends AuthInterceptor {
  constructor(
    protected settings: SettingsService,
    protected userPrefQuery: UserPreferencesQuery,
    @Optional() @Inject(SERVICE_PORTS) protected servicePorts: ServicePort[]
  ) {
    super(settings, userPrefQuery, servicePorts);
  }

  returnNext(request: HttpRequest<unknown>, next: HttpHandler) {
    const firstContext = window.bridge.contexts()[0];

    return next.handle(this.setHeaders(request, window.bridge.token(firstContext), firstContext));
  }
}
