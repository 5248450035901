import { Component, Input, OnInit } from '@angular/core';
import { AuthQuery, AuthService, LinkService } from '@nexuzhealth/shared/authentication/data-access-auth';
import {
  UserPreferencesQuery,
  UserPreferencesService,
} from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { AccessRole, User, UserContext } from '@nexuzhealth/shared/domain';
import { filterNullOrUndefined, I18nextArrayByKeyPipe } from '@nexuzhealth/shared/util';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { FormatRolesPipe } from '@nexuzhealth/shared/authentication/ui/format-roles-pipe';

@Component({
  selector: 'nxh-nav-item-user',
  templateUrl: './nav-item-user.component.html',
  styleUrls: ['./nav-item-user.component.scss'],
})
export class NavItemUserComponent implements OnInit {
  @Input() userPortalLinkVisible = false;
  @Input() organizationPortalLinkVisible = false;

  AccessRole = AccessRole;
  userPortalLink: string;
  organizationPortalLink: string;
  isLanguageCollapsed = true;
  userContextSearch!: UntypedFormControl;
  readonly userContextVirtualScrollItemSize = 48; // item size in pixels

  userContext$!: Observable<UserContext>;
  user$!: Observable<User>;
  allUserContexts$: Observable<UserContextView[]>;
  preferredLanguage$: Observable<string>;
  userContexts$: Observable<UserContext[]>;

  constructor(
    private linkService: LinkService,
    private userPreferences: UserPreferencesQuery,
    private userPreferencesService: UserPreferencesService,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private i18nextArrayByKeyPipe: I18nextArrayByKeyPipe,
    private formatRolesPipe: FormatRolesPipe
  ) {}

  ngOnInit(): void {
    this.userContextSearch = new UntypedFormControl('');

    this.user$ = this.authQuery.selectUser();
    this.userPortalLink = this.linkService.userPortalLink();
    this.organizationPortalLink = this.linkService.organizationPortalLink();
    this.preferredLanguage$ = this.userPreferences.preferredLanguage$;

    this.userContexts$ = this.authQuery.selectUserContexts().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.userContext$ = this.authQuery
      .selectUserContext()
      .pipe(filterNullOrUndefined, shareReplay({ bufferSize: 1, refCount: true }));

    this.allUserContexts$ = this.userContextSearch.valueChanges.pipe(
      startWith(''),
      withLatestFrom(this.userContexts$),
      map(([searchTerm, contexts]) => {
        const userContextsView: UserContextView[] = this.mapContextsToUserContextsView(contexts);

        const searchedUserContextsView =
          searchTerm && searchTerm.length >= 2
            ? userContextsView.filter((userContextView) =>
                userContextView.tenantDescription.toLowerCase().startsWith(searchTerm.toLowerCase())
              )
            : userContextsView;

        return searchedUserContextsView.sort((a, b) => {
          if (a.tenantName === b.tenantName) {
            return a.roles.localeCompare(b.roles);
          }
          return a.tenantDescription.localeCompare(b.tenantDescription);
        });
      })
    );
  }

  updatePreferredLanguage(language: string) {
    this.userPreferencesService.setPreferredLanguage(language);
    window.location.reload();
  }

  logout() {
    this.authService.logout();
  }

  logInTo(userContextName: string) {
    this.authService.switchUserContext(userContextName);
  }

  private mapContextsToUserContextsView(contexts: UserContext[]): UserContextView[] {
    return contexts.map<UserContextView>((context) => ({
      userContextName: context.name,
      tenantName: context.tenant.name,
      tenantDescription: context.tenant.description,
      roles: this.formatRolesPipe.transform(context.rolesActive, 'ONLY_ASSIGNABLE'),
    }));
  }
}

interface UserContextView {
  userContextName: string;
  tenantName: string;
  tenantDescription: string;
  roles: string;
}
