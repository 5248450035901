<div class="card card--modal card--modal--warning">
  <div class="card-header">
    <div class="card-header__title">{{ '_patient.sync-modal-eid-title' | i18next }}</div>
    <div class="card-header__controls">
      <button nxh-button buttonType="icon" (click)="dismiss()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="card-body__container">
      <div class="nh-row">
        <div class="nh-col">
          <h3 class="form-subtitle">{{ '_patient.sync-modal-current' | i18next }}</h3>
          <div class="data-list">
            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.firstname' | i18next }}</div>
              <div class="data-list__value">{{ patient.personName.given }}</div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.patient-name' | i18next }}</div>
              <div class="data-list__value">{{ patient.personName.family }}</div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.inss' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ patient.assignedIds | findAssignedIdType : inssFormatType | formatAssignedId }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.administrative-gender' | i18next }}</div>
              <div class="data-list__value">{{ patient.administrativeGender | resolveAdministrativeGender }}</div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.birthdate' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ patient.birthDate?.value | nxhDate : 'shortDate' : 'Europe/Brussels' }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_address.domicile' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ patient.addresses | findAddress : 'Use_OFFICIAL' | formatAddress }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nh-col">
          <h3 class="form-subtitle">{{ '_patient.sync-modal-eid' | i18next }}</h3>
          <div class="data-list">
            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.firstname' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ eidData.givenName }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.patient-name' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ eidData.name }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.inss' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ eidData.sub | formatAssignedId : inssFormatType }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.administrative-gender' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ eidData.gender?.name | resolveAdministrativeGender }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_personal.birthdate' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ convertDate(eidData.birthdate) | nxhDate : 'shortDate' : 'Europe/Brussels' }}</span>
              </div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">{{ '_address.domicile' | i18next }}</div>
              <div class="data-list__value">
                <span>{{ eidData.address | formatAddress }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="card-footer__controls">
      <button nxh-button buttonType="subtle-button" buttonStatus="neutral" tabindex="-1" (click)="dismiss()">
        {{ 'cancel' | i18next }}
      </button>
      <deprecated-submit-button
        [showSpinner]="loading$ | async"
        [icon]="faSave"
        [label]="'_patient.sync-modal-confirm' | i18next"
        (click)="submit()"
      ></deprecated-submit-button>
    </div>
  </div>
</div>
