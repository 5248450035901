import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EMPTY } from 'rxjs';
import { IconRegistryService } from './icon-registry.service';
import { IconCustomSizingDirective } from './custom-sizing.directive';

/**
 * IconsModule sets up Fontawesome, i.e. while exposing all Fontawesome goodies, it also loads all necessary icons (through
 * its forRoot() method). When using icons in your module, it is recommended to import IconsModule iso Fontawesome module.
 * When used in tests, you *have to* import IconsModule.forRoot(), otherwise your tests will fail:
 *
 * From 0.5 on FontAwesome throws an Error when an icon is used that hasn't been registered. Typically this happens in
 * the root component, but this is problematic for tests. Therefore we have provided a wrapper for the FontAwesomeModule,
 * doing the registration in that wrapper. Should you need to import the FontAwesomeModule in your test, you import
 * IconsModule in stead.
 *
 * More info: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/guide/testing.md
 *
 * @example
 *
 *   const createHost = createHostFactory({
 *     component: SubmitButtonComponent,
 *     imports: [CommonModule, SharedUtilI18nModule, IconsModule.forRoot()],
 *     declarations: [LoadingButtonComponent],
 *     mocks: [AutoValidateDirective],
 *     detectChanges: false,
 *   });
 *
 */
@NgModule({
  imports: [FontAwesomeModule],
  declarations: [IconCustomSizingDirective],
  exports: [FontAwesomeModule, IconCustomSizingDirective],
})
export class IconsModule {
  static forRoot(): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (library: FaIconLibrary, faConfig: FaConfig) => {
            const service = new IconRegistryService(library, faConfig);
            service.loadAll();
            return () => EMPTY;
          },
          multi: true,
          deps: [FaIconLibrary, FaConfig],
        },
      ],
    };
  }
}
