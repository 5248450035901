import { Patient } from './patient';
import { Practitioner } from './practitioner';
import { PersonName, ResourceName } from './common';
import { HealthElementType } from './health-element';
import { PersonType, Relation } from './patient-entourage-member';

export interface Migration {
  name?: string;
  type: MigrationType;
  phase?: Phase;
  patient?: Patient;
  status?: MigrationStatusEnum;
  errorMessage?: string;
  blobName?: string;
  batch?: string;
  source?: string;
  numberOfLines?: number;
  lines?: MigrationLine[];
  createTime?: string;
  exportFileName?: string;
  responsiblePractitioner?: Practitioner;
  canContinue?: boolean;
  archived?: boolean;
}

export interface MigrationLine {
  name: ResourceName;
  skip: boolean;
  resourceType: LineResourceType;
  errorMessage: string;
  preview: PreviewType;
  dependants: ResourceName[];
}

export enum LineResourceType {
  HEALTH_ELEMENT = 'HEALTH_ELEMENT',
  PARAMETER = 'PARAMETER',
  PATIENT = 'PATIENT',
  ENTOURAGE_MEMBER = 'ENTOURAGE_MEMBER',
  CAREPLAN = 'CAREPLAN',
  PATIENT_DECISION = 'PATIENT_DECISION',
  VACCINATION = 'VACCINATION',
  THERAPY = 'THERAPY',
  PRESCRIPTION = 'PRESCRIPTION',
  MULTIMEDIA_REGISTRATION = 'MULTIMEDIA_REGISTRATION',
  DIAGNOSTIC_REPORT = 'DIAGNOSTIC_REPORT',
  CONTACT = 'CONTACT',
  INCAPACITY = 'INCAPACITY',
}

export type PreviewType =
  | PreviewHealthElement
  | PreviewParameter
  | PreviewPatient
  | PreviewEntourageMember
  | PreviewCarePlan
  | PreviewVaccination
  | PreviewTherapy
  | PreviewMultimediaRegistration
  | PreviewDiagnosticReport
  | PreviewPrescription
  | PreviewContact
  | PreviewPatientDecision
  | PreviewIncapacity;
export interface PreviewContact {
  contact: {
    practitionerPersonName: {
      given: string;
      family: string;
    };
    startTime: string;
  };
}

export interface PreviewMultimediaRegistration {
  multimediaRegistration: {
    categoryPath: string[];
    display: string;
    history: {
      eventTime: string;
    }[];
  };
}
export interface PreviewDiagnosticReport {
  diagnosticReport: {
    createDate: {
      value: string;
    };
  };
}

export interface PreviewVaccination {
  vaccination: {
    administrationDate: string;
    vaccineDescription: string;
    vaccineIndications: string[];
  };
}

export interface PreviewTherapy {
  therapy: {
    courseOfTherapyType: string;
    startDate: string;
    endDate: string | null;
    stopDate: string | null;
    medicationDescription: string;
  };
}

export interface PreviewPrescription {
  prescription: {
    authoredOn: string;
    status: string;
    medicationDescription: string;
  };
}

export interface PreviewVaccination {
  administrationDate: string;
  vaccineDescription: string;
  vaccineIndications: string[];
}

export interface PreviewHealthElement {
  healthElement: {
    kind: HealthElementType;
    description: string;
    startTime: string;
    endTime: string | null;
  };
}

export interface PreviewPatient {
  patient: Patient;
}

export interface PreviewEntourageMember {
  patientEntourageMember: {
    personName: PersonName;
    personType: PersonType;
    relations: Relation[];
  };
}

export interface PreviewPatientDecision {
  patientDecision: {
    definitionKey: string;
    definitionDisplay: string;
    definitionName: string;
    startTime: string;
    valueDisplay: string;
  };
}

export interface PreviewParameter {
  parameter: {
    parameterDefinitionName: string;
    rendering: {
      value: string;
      unit: {
        code: string;
        display: string;
      };
    };
    assertTime: string;
  };
}

export interface PreviewCarePlan {
  carePlan: {
    description: string;
    startTime: string;
    endTime: string | null;
  };
}

export interface PreviewIncapacity {
  incapacity: {
    attestName: string;
    startData: string;
    endDate: string | null;
  };
}

export enum MigrationStatusEnum {
  UNSPECIFIED = '',
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE_SUCCESS = 'DONE_SUCCESS',
  DONE_ERROR = 'DONE_ERROR',
}

export enum MigrationType {
  UNSPECIFIED = 'UNSPECIFIED',
  MMF = 'MMF',
  PMF = 'PMF',
  INSURANCE_PROVIDER = 'INSURANCE_PROVIDER',
}

export enum MigrationDirection {
  DIRECTION_IMPORT = 'DIRECTION_IMPORT',
  DIRECTION_EXPORT = 'DIRECTION_EXPORT',
}

export enum Phase {
  PHASE_UNSPECIFIED = 'PHASE_UNSPECIFIED',
  PARSE = 'PARSE',
  IMPORT = 'IMPORT',
  MERGE = 'MERGE',
  EXPORT = 'EXPORT',
}

export interface CovidBarometerStatsResponse {
  patientCounts: Record<string, number>;
  fractionCoded: number;
  nbPatientsWithContact: number;
  //ui
  percentageCoded?: number;
}

export enum MigrationView {
  VIEW_UNSPECIFIED = '',
  BASIC = 'BASIC', //Only migration
  PATIENT = 'PATIENT', //Only migration and patient
  LINES = 'LINES', //Only migration and lines, resources
  FULL = 'FULL', //migration, patient and all lines, resources
}
