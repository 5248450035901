
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Theme } from './theme';
import { ThemeStore } from './theme.store';

export abstract class AbstractThemeResolver  {
  protected constructor(protected themeStore: ThemeStore, protected settings: SettingsService) {}

  abstract getTheme(): Observable<Theme>;

  resolve(): Observable<Theme | null> {
    return this.getTheme().pipe(
      map((theme) => {
        const themingPath = this.settings.getSystemSettings().theming.path;
        return {
          ...theme,
          logo: `${themingPath}/${theme.logo}`,
          backgroundImage: theme.backgroundImage ? `${themingPath}/${theme.backgroundImage}` : undefined,
        };
      }),
      tap((theme) => this.themeStore.update({ ...theme, isDefault: false })),
      catchError(() => {
        console.warn('Theme could not be loaded... Using default theme');
        return of(null);
      })
    );
  }
}
