import { inject, InjectionToken } from '@angular/core';
import { PushNotification, PushNotificationConfig } from '@nexuzhealth/shared/tech/domain';
import { Observable } from 'rxjs';

import { PushNotificationsApiService } from './push-notifications-api.service';
import { notification$ } from './push-notifications.symbols';

export const PUSH_NOTIFICATION_CONFIG = new InjectionToken<PushNotificationConfig>('push-notification-config');

export const PUSH_NOTIFICATIONS = new InjectionToken<Observable<PushNotification>>('push-notifications', {
  providedIn: 'root',
  factory: () => inject(PushNotificationsApiService)[notification$],
});
