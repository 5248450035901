import { Component } from '@angular/core';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';

/**
 * When there's no refresh token, Auth0 will try to get tokens via Silent authentication.
 * More info: https://auth0.com/docs/authorization/configure-silent-authentication
 * This will handle the callback after successful authentication
 */
@Component({
  template: ``,
})
export class CallbackComponent {
  /**
   * Make sure the AuthService is loaded and starts the authentication process.
   * @param auth
   */
  constructor(auth: AuthService) {}
}
