import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventChannelService implements OnDestroy {
  private broadcast: BroadcastChannel;

  logout$ = new Subject<void>();
  switchUserContext$ = new Subject<void>();

  constructor() {
    this.broadcast = new BroadcastChannel('nexuzhealth');
    this.broadcast.addEventListener('message', (event) => {
      switch (event.data.command) {
        case 'logout':
          this.logout$.next();
          break;
        case 'switchUserContext':
          this.switchUserContext$.next();
          break;
      }
    });
  }

  sendLogout() {
    this.broadcast.postMessage({ command: 'logout' });
  }

  sendUserContextSwitch() {
    this.broadcast.postMessage({ command: 'switchUserContext' });
  }

  ngOnDestroy() {
    this.logout$.complete();
    this.switchUserContext$.complete();
    this.broadcast.close();
  }
}
