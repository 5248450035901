import { Injectable } from '@angular/core';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor() {}

  error(msg: string, ...params: any[]): any {
    console.error(msg, params);
  }

  info(msg: string, ...params: any[]): any {
    // eslint-disable-next-line
    console.info(msg, params);
  }

  // note: to see these logs you have to change 'default levels' in your devtools
  debug(msg: string, ...params: any[]) {
    params = [msg, ...params];
    // eslint-disable-next-line
    console.log(`%c${format(new Date(), 'HH:mm:ss')}`, 'color:green', ...params);
  }
}
