<div class="sort-header__container" (click)="sort()">
  <ng-content></ng-content>

  <ng-container [ngSwitch]="sortByOrder">
    <fa-duotone-icon
      [icon]="['fad', 'sort']"
      primaryOpacity="1"
      secondaryOpacity="1"
      primaryColor="var(--gray-600)"
      secondaryColor="var(--gray-400)"
      *ngSwitchCase="'asc'"
    ></fa-duotone-icon>
    <fa-duotone-icon
      [icon]="['fad', 'sort']"
      primaryOpacity="1"
      secondaryOpacity="1"
      primaryColor="var(--gray-400)"
      secondaryColor="var(--gray-600)"
      *ngSwitchCase="'desc'"
    ></fa-duotone-icon>
    <fa-duotone-icon
      [icon]="['fad', 'sort']"
      primaryOpacity="1"
      secondaryOpacity="1"
      primaryColor="var(--gray-400)"
      secondaryColor="var(--gray-400)"
      *ngSwitchDefault
    ></fa-duotone-icon>
  </ng-container>
</div>
