import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { I18NextPipe } from 'angular-i18next';
import { v4 as uuid } from 'uuid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * @deprecated Use nxh-button [loading] in stead
 */
@Component({
  selector: 'nxh-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Input() loading = false;
  /**
   * pass null or undefined to disable the icon if you're not loading
   **/
  @Input() icon: IconProp | IconName | null | undefined = 'check';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabled') _disabled = null;
  @Input() type = 'success';
  @Input() btnType = 'button';
  @Input() label = this.i18next.transform('save');
  @Input() showLabel = true;
  @Input() id = uuid();
  @ViewChild('button', { static: true }) buttonRef: ElementRef<HTMLButtonElement>;

  constructor(private i18next: I18NextPipe) {}

  @HostBinding('class.disabled')
  get disabled() {
    return this._disabled !== null ? this._disabled : this.loading;
  }

  focus() {
    this.buttonRef.nativeElement.focus({ preventScroll: true });
  }
}
