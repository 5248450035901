import { withTransaction } from '@datorama/akita';
import { combineLatest, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserApiService } from '../api/user-api.service';
import { AuthStore } from '../state/auth-store.service';
import { AuthQuery } from '../state/auth-query.service';

export abstract class SessionService {
  constructor(protected authStore: AuthStore, protected userApi: UserApiService, protected authQuery: AuthQuery) {}

  useUserContextName(userContextName: string): void {
    this.authStore.setUserContextName(userContextName);
  }

  retrieveOrganisationInformation() {
    this.authStore.setLoading(true);
    return this.userApi.listOrganisationRoles().pipe(
      withTransaction((accessRoles) => {
        this.authStore.setOrganisationRoles(accessRoles);
        this.authStore.setLoading(false);
      }),
      catchError((error: Error) => {
        this.authStore.setError<Error>(error);
        return throwError(error);
      })
    );
  }

  retrieveUserInformation() {
    this.authStore.setLoading(true);
    return combineLatest([
      this.userApi.getUserAndContexts(),
      this.userApi.getAccessRoles(this.authQuery.getUserContextName()),
    ]).pipe(
      withTransaction(([userInfo, accessRoles]) => {
        this.authStore.setUser({ ...userInfo.user, accessRoles });
        this.authStore.setUserContexts(userInfo.userContexts);
        this.authStore.setUserSettings(userInfo.settings);
        this.authStore.setLoading(false);
      }),
      catchError((error: Error) => {
        this.authStore.setError<Error>(error);
        return throwError(error);
      })
    );
  }

  abstract clear(): void;

  abstract setRedirectAfterLogin(path?: string): void;

  abstract getAndClearRedirectAfterLogin(): string;
}
