import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { SkeletonLoaderComponent } from './skeleton-loader.component';

@NgModule({
  imports: [CommonModule, ContentLoaderModule],
  exports: [SkeletonLoaderComponent],
  declarations: [SkeletonLoaderComponent],
})
export class SkeletonLoaderModule {}
