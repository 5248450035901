import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared/ui-toolkit/button';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { FilterOnService } from './filter-on.service';

@Component({
  selector: 'nxh-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18NextModule, NgIf, IconsModule, ButtonModule, AsyncPipe, SharedTechFeatureE2eModule],
})
export class EmptyComponent {
  /**
   * Custom "empty state" label
   */
  @Input() label?: string;

  /**
   * Custom label for the empty search state
   */
  @Input() noItemsFoundLabel?: string;

  /**
   * Specifies whether the data is empty as a result of a filter
   */
  @Input({ transform: booleanAttribute }) set filterOn(filterOn: boolean) {
    this.filterOn$$.next(filterOn);
  }

  /**
   * Custom label for when there is a call-to-action accompanying the empty state message
   */
  @Input() createItemLabel?: string;

  /**
   * For when the user has clicked on the call-to-action button
   */
  @Output() createItem = new EventEmitter<void>();

  private filterOn$$ = new BehaviorSubject<boolean | null>(null);
  private parentFilterOn$: Observable<boolean> = inject(FilterOnService, { optional: true })?.filterOn$ ?? of(null);

  isFilterOn$ = combineLatest([this.parentFilterOn$, this.filterOn$$]).pipe(
    map(([parentFilterOn, localFilterOn]) => {
      if (localFilterOn !== null) {
        return localFilterOn;
      } else if (parentFilterOn !== null) {
        return parentFilterOn;
      } else {
        return false;
      }
    })
  );
}
