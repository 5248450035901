import { DateRange, DateTime, Error, ResourceName } from '@nexuzhealth/shared/domain';

export interface Insurability {
  name: ResourceName;
  sendVersion: string;
  requestDate: DateTime;
  communicationDate: DateTime;
  thirdPayer: boolean;
  givenName: string;
  familyName: string;
  inss: string;
  mutuality: string;
  mutualityRegNr?: string;
  birthday: DateTime;
  deceasedDate?: DateTime;
  sex: string;
  period: DateRange;
  cb1: string;
  cb2: string;
  payment?: {
    specialSocialCategory: 'SOCIAL_CATEGORY_00' | 'SOCIAL_CATEGORY_01';
    maxInvoiced?: string[];
  };
  paymentApproval: string;
  partial: boolean;

  insurabilityHospital: any;
  insurabilityGeneralSituation: GeneralSituation[];
  insurabilityMedicalHouses?: MedicalHouse[];
  insurabilityPharmacies: ReferencePharmacies[];
  insurabilityCarePaths: CarePaths[];
  chronicConditionYears: string[];
  palliativeStartDate?: DateTime;
  gmf?: InsurabilityGmf;

  // contains the facets that were missing in case of partial response
  missingFacets?: Facet[];
}

export enum Facet {
  carePath = 'carePath',
  referencePharmacy = 'referencePharmacy',
  generalsituation = 'generalSituation',
  gmf = 'globalMedicalFile',
  palliative = 'palliative',
  insurability = 'insurability',
  chronicCondition = 'chronicCondition',
  hospital = 'hospital',
}

export interface ReferencePharmacies {
  // Nihii of the pharmacy where the patient had a convention running.
  pharmacy: string;
  // Start date convention with pharmacy
  pharmacyStartDate: DateTime;
}

export interface CarePaths {
  carePathType: string;
  // The general practioner who singed the contract
  carePathPhysician: string;
  // The medical house who singed the contract
  carePathMedicalHouse: string;
  // The specialist who singed the contract
  carePathSpecialist: string;
  // Start date care path
  carePathStartRightDate: DateTime;
  // End date care path
  carePathEndRightDate: DateTime;
  // The End date the care path ended
  carePathEndContractDate: DateTime;
}

export interface MedicalHouse {
  medicalHouse: string;
  // Type of care example Nurse,Medical or Kine
  medicalHouseTypes: MedicalHouseType[];
  // Start date contract medical house
  medicalHouseStartDate: DateTime;
  // End date contract medical house
  medicalHouseEndDate: DateTime;
}

export type MedicalHouseType = 'Nurse' | 'Medical' | 'Kine';

export interface GeneralSituation {
  situationType: string;
  transferDate: DateTime;
  transferDirection: string;
  transferMutuality: string;
}

export interface InsurabilityGmf {
  nihii11: string;
  firstname: string;
  name: string;
}

export type InsurabilityError = Error | InsurabilityFault[];

export interface InsurabilityFault {
  faultCode: INSURABILITY_FAULT_CODE;
  faultSource: string;
  lang: string;
  content: string;
  details?: InsurabilityFaultDetail[];
}

export type INSURABILITY_FAULT_CODE = 'INPUT_ERROR' | 'WARNING' | 'AUTHORIZATION_ERROR' | 'VALIDATION_ERROR';

export type INSURABILITY_DETAIL_CODE =
  | INSURABILITY_DETAIL_CODE_INPUT_ERROR
  | INSURABILITY_DETAIL_CODE_AUTHORIZATION_ERROR
  | INSURABILITY_DETAIL_CODE_WARNING;

export type INSURABILITY_DETAIL_CODE_INPUT_ERROR =
  | 'INVALID_IO_FORMAT'
  | 'INVALID_IO_VALUE'
  | 'BO_UNKNOWN_NISS'
  | 'BO_INVALID_REGNBR'
  | 'BO_UNKNOWN_REGNBR'
  | 'UNKNOWN_NISS_ROUTING'
  | 'UNKNOWN_IO'
  | 'MULTIPLE_IO'
  | 'EMPTY_INSS'
  | 'EMPTY_REG_NUM'
  | 'INVALID_INSS_FORMAT'
  | 'INVALID_INSS_VALUE'
  | 'INVALID_PERIOD'
  | 'START_DATE_IN_FUTURE'
  | 'INVALID_PERIOD_FOR_SECTOR'
  | 'PERIOD_TOO_FAR_IN_PAST'
  | 'BO_PERIOD_TOO_FAR_IN_THE_PAST'
  | 'MISMATCHED_CONTACTTYPE';
export type INSURABILITY_DETAIL_CODE_AUTHORIZATION_ERROR = 'UNAUTHORIZED_FACET';
export type INSURABILITY_DETAIL_CODE_WARNING = 'BO_MISSING_FACET' | 'MISSING_FACETS' | 'MUTATION';

export interface InsurabilityFaultDetail {
  detailCode: INSURABILITY_DETAIL_CODE;
  detailSource?: string;
  location?: string;
  lang?: string;
  content?: string;
}

export enum ForceMemberDataCall {
  DEFAULT,
  SOMETIMES_FORCE = 1,
  ALWAYS_FORCE = 2,
  NEVER_FORCE = 3,
}

export type ReturnMemberDataStatus = 'UNSPECIFIED' | 'MCN_DATA' | 'LAST_KNOWN_PERIOD' | 'LAST_KNOWN';
