import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserContextRO } from '@nexuzhealth/shared/domain';

const idKey: keyof UserContextRO = 'name';

export type UserContextROStateKey = UserContextRO[typeof idKey];
export type UserContextState = EntityState<UserContextRO, UserContextROStateKey>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-context', idKey, resettable: true })
export class UserContextStore extends EntityStore<UserContextState> {
  constructor() {
    super();
  }
}
