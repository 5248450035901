import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthQuery, AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, RoleExpr } from '@nexuzhealth/shared/domain';
import { GmfApiService, NewData } from '@nexuzhealth/shared/ehealth/data-access-gmf';
import { NavbarItem } from '@nexuzhealth/shared/toolkit/feature-shell';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';

interface RoleBasedNavbarItem extends NavbarItem {
  roleExpr?: RoleExpr;
}

@Component({
  selector: 'nxh-moapr-specialist-app-shell',
  templateUrl: './app-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit {
  userContext = this.authQuery.getUserContext();
  navItems$!: Observable<RoleBasedNavbarItem[]>;

  AccessRole = AccessRole;

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private http: HttpClient,
    private i18next: I18NextPipe,
    private gmfService: GmfApiService,
    private toast: ToastrService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private featureFlagQuery: FeatureFlagQuery
  ) {}

  ngOnInit() {
    this.featureFlagService.load('featureflags/nxh-mail');

    this.navItems$ = combineLatest([
      this.http.get<{ url: string }>('api/integrations/mynexuzhealth/v1/pro:link').pipe(
        map(({ url }) => url),
        catchError(() => of(''))
      ),
      this.featureFlagQuery.selectFeatureFlagEnabled('featureflags/nxh-mail'),
    ]).pipe(
      map(([url, nxhMailEnabled]) => {
        return this.defaultNavitems(url, nxhMailEnabled);
      })
    );

    const title = this.i18next.transform('_gmf.new-closures');
    const description = this.i18next.transform('_gmf.goto-new-closures');
    this.authQuery
      .selectUser()
      .pipe(
        filter((user) => user.name.length > 0),
        take(1),
        switchMap(() => this.gmfService.getPatientListExtensionUpdate('automatic'))
      )
      .subscribe((data: NewData) => {
        if (data.newClosure) {
          this.toast.info(description, title, { enableHtml: true });
          this.toExtensions();
        }
      });
  }

  private defaultNavitems(hospitalRecordsUrl: string, nxhMailEnabled: boolean) {
    return this.filterAuthorizedRoutes([
      // <= move to config file
      {
        label: 'patients',
        url: '/patients',
        roleExpr: { or: [AccessRole.clerk, AccessRole.clinical] },
      },
      {
        label: '_messages.navbar.title',
        url: '/messages',

        roleExpr: nxhMailEnabled ? null : AccessRole.clinical,
      },
      {
        label: '_resourceplanning.navbar.title',
        url: '/resourceplanning',
      },
      {
        label: '_settings.navbar.title',
        url: '/settings',
      },
    ]);
  }

  private filterAuthorizedRoutes(items: RoleBasedNavbarItem[]): RoleBasedNavbarItem[] {
    return items.filter((item) => this.authService.isAuthorized(item.roleExpr));
  }

  showEid() {
    return this.authService.isAuthorized({ or: [AccessRole.clinical, AccessRole.clerk] });
  }

  toExtensions() {
    this.router.navigateByUrl('/gmf/extensions');
  }
}
