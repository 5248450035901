import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';

import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared/tech/feature-e2e';
import { StickerDirective } from '@nexuzhealth/shared/ui-toolkit/sticker';
import { PillCategory, PillCategoryValue, ToolkitType, ToolkitTypeValue } from '../models';

@Component({
  selector: 'nxh-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'badge',
    [`[class.pill--${ToolkitType.Primary}]`]: `type === '${ToolkitType.Primary}'`,
    [`[class.pill--${ToolkitType.Success}]`]: `type === '${ToolkitType.Success}'`,
    [`[class.pill--${ToolkitType.Warning}]`]: `type === '${ToolkitType.Warning}'`,
    [`[class.pill--${ToolkitType.Danger}]`]: `type === '${ToolkitType.Danger}'`,
    [`[class.pill--${ToolkitType.Disabled}]`]: `type === '${ToolkitType.Disabled}'`,
    [`[class.pill--bold]`]: `bold`,
    [`[class.pill--outline]`]: `outline`,
    [`[class.pill--${PillCategory.DarkBlue}-washed]`]: `variant === '${PillCategory.DarkBlue}'`,
    [`[class.pill--grey-medium-dark]`]: `variant === '${PillCategory.Grey}'`,
    [`[class.pill--${PillCategory.LightBlue}-washed]`]: `variant === '${PillCategory.LightBlue}'`,
    [`[class.pill--${PillCategory.LightGreen}-washed]`]: `variant === '${PillCategory.LightGreen}'`,
    [`[class.pill--${PillCategory.Grey}-washed]`]: `variant === '${PillCategory.LightGrey}'`,
    [`[class.pill--${PillCategory.LightOrange}-washed]`]: `variant === '${PillCategory.LightOrange}'`,
    [`[class.pill--${PillCategory.LightRed}-washed]`]: `variant === '${PillCategory.LightRed}'`,
    [`[class.pill--${PillCategory.Purple}-washed]`]: `variant === '${PillCategory.Purple}'`,
    [`[class.pill--${PillCategory.Red}-washed]`]: `variant === '${PillCategory.Red}'`,
    [`[class.pill--${PillCategory.Yellow}-washed]`]: `variant === '${PillCategory.Yellow}'`,
  },
  hostDirectives: [
    {
      directive: StickerDirective,
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stickTopLeft', 'stickTopRight', 'stickBottomRight', 'stickBottomLeft'],
    },
  ],
})
export class PillComponent implements AfterViewInit {
  /**
   * Status colors.
   */
  @Input() type?: ToolkitTypeValue;

  /**
   * Makes the pill bold.
   */
  @Input() bold = false;

  /**
   * Makes the pill outlined. For ToolkitTypeValues only, not for variants.
   */
  @Input() outline = false;

  /**
   * Colorful labels for various use.
   */
  @Input() variant?: PillCategoryValue | null;

  /**
   * Marks the PillComponent as closeable, i.e. provides a close button and a "close click" event
   */
  @Input() closeable = false;

  /**
   * Tooltip to show above close icon, in case the PillComponent is closeable
   */
  @Input() closeTooltip?: string;

  /**
   * Marks the PillComponent as editable, i.e. provides an edit button and a "edit click" event
   */
  @Input() editable = false;

  /**
   * Tooltip to show above edit icon, in case the PillComponent is editable
   */
  @Input() editTooltip?: string;

  @Output() closeClick = new EventEmitter();
  @Output() editClick = new EventEmitter();

  @ViewChild('edit', { read: ElementRef }) editIcon?: ElementRef;
  @ViewChild('close', { read: ElementRef }) closeIcon?: ElementRef;

  constructor(@Optional() private dataTestDirective: DataTestDirective) {}

  ngAfterViewInit(): void {
    if (this.dataTestDirective) {
      if (this.editIcon) {
        addDataTestAttributes(this.dataTestDirective?.nxhDataTest, {
          element: this.editIcon.nativeElement,
          suffix: '_edit',
        });
      }
      if (this.closeIcon) {
        addDataTestAttributes(this.dataTestDirective?.nxhDataTest, {
          element: this.closeIcon.nativeElement,
          suffix: '_close',
        });
      }
    }
  }
}
