import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ReferenceType, ReferenceTypeState } from '../shared/reference-types';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'nationality', idKey: 'name' })
export class NationalityStore extends EntityStore<ReferenceTypeState, ReferenceType> {
  constructor() {
    super();
  }
}
