import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlurComponent } from './blur.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BlurComponent],
  exports: [BlurComponent],
})
export class BlurModule {}
