import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { BoldPipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/bold';
import { TypeaheadDirective } from './typeahead.directive';
import { TypeaheadOptionComponent } from './typeahead-option.component';
import { TypeaheadPopoverComponent } from './typeahead-popover.component';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, BoldPipeModule],
  exports: [TypeaheadDirective, TypeaheadOptionComponent, TypeaheadPopoverComponent],
  declarations: [TypeaheadDirective, TypeaheadOptionComponent, TypeaheadPopoverComponent],
})
export class TypeaheadModule {}
