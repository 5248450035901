import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PictureState, PictureStore } from './picture.store';

@Injectable({ providedIn: 'root' })
export class PictureQuery extends QueryEntity<PictureState> {
  constructor(store: PictureStore) {
    super(store);
  }
}
