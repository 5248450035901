import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nxh-alert-title',
  templateUrl: './alert-title.component.html',
  styleUrls: ['./alert-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AlertTitleComponent {
  @Input() alertTitle?: string;
  @Input() noCapitalization = false;
  @Input() closeable = false;
  @Output() closeAlert = new EventEmitter<void>();
}
