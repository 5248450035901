import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CookieService, FUNCTIONAL_COOKIES } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { EnvironmentsService } from '@nexuzhealth/shared/util';
import { SettingsService, SystemSettings } from '@nexuzhealth/shared/settings/data-access-settings';

@Component({
  selector: 'nxh-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieBarComponent implements OnInit {
  constructor(
    private cookieService: CookieService,
    private envService: EnvironmentsService,
    private systemSettings: SettingsService
  ) {}

  cookieUrl: string;
  hasFunctionalCookie = false;

  ngOnInit(): void {
    this.cookieUrl = `${this.systemSettings.loginEndpoint}/legal/cookies`;
    this.hasFunctionalCookie = !!this.cookieService.get(FUNCTIONAL_COOKIES);
  }

  setCookie() {
    this.hasFunctionalCookie = true;
    this.cookieService.put(FUNCTIONAL_COOKIES, 'true');
  }
}
