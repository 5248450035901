import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated Use i18nextCap in html or i18next.transform('key', {format: 'cap'} in code
 */
@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(s: string | undefined | null): string {
    if (!s?.length || typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}
