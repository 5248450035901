import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormHelperDirective } from '@nexuzhealth/shared/ui-toolkit/forms';

@Component({
  selector: 'nxh-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFooterComponent {
  @HostBinding('class')
  _hostClasses = 'modal-footer';

  @Input()
  @HostBinding('class.nxh-modal-footer-dark')
  dark = true;

  @Input() connectNxhForm: FormHelperDirective;
}
