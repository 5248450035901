export interface TestTarget {
  element: Element;
  suffix?: string;
}

export function addDataTestAttributes(base: string, ...targets: TestTarget[]): void {
  targets?.forEach((target) => {
    target.element.setAttribute('data-test', base + (target.suffix || ''));
  });
}
