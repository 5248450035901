import {
  ComponentType,
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  POPOVER_ACTIONS,
  POPOVER_CONTENT,
  POPOVER_DATA,
  PopoverActions,
  PopoverComponent,
  PopoverConfig,
  PopoverContent,
  PopoverRef,
} from './popover.model';
import { SimplePopoverComponent } from './simple-popover.component';
import { PopoverModule } from './popover.module';

const DEFAULT_CONFIG: PopoverConfig = {
  hasBackdrop: true,
  allowClicksOutside: false,
  backdropClass: 'popover-backdrop',
  panelClass: '',
  scrollStrategy: 'reposition',
  viewportMargin: 16,
};

const POSITIONS: { [position: string]: ConnectionPositionPair } = {
  bottom: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    panelClass: 'popper--bottom',
  },
  top: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    panelClass: 'popper--top',
  },
  right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    panelClass: 'popper--right',
  },
  left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    panelClass: 'popper--left',
  },
  'start-bottom-start-top': {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: ['popper--bottom-left'],
  },
  'start-top-start-top': {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    panelClass: ['popper--start-top-start-top'],
  },
  'start-top-start-bottom': {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    panelClass: ['popper--start-top-start-bottom'],
  },
};

/**
 * Inspired by https://netbasal.com/creating-powerful-components-with-angular-cdk-2cef53d81cea and
 * https://blog.thoughtram.io/angular/2017/11/20/custom-overlays-with-angulars-cdk.html
 */
@Injectable({ providedIn: PopoverModule })
export class PopoverService {
  private overlayRefs: OverlayRef[] = [];

  constructor(private overlay: Overlay, private injector: Injector) {}

  open<T>(popoverConfig: PopoverConfig = {}, popoverActions?: PopoverActions): PopoverRef {
    const config: PopoverConfig = { ...DEFAULT_CONFIG, ...popoverConfig };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(config);

    this.setupOverlayRefs(overlayRef);

    // Instantiate "remote control" to close modal
    const popoverRef = new PopoverRef(overlayRef);

    const injector = this.createInjector(popoverRef, config.customTokens, config.data, config.content, popoverActions);
    const container = config.component
      ? config.component
      : config.container
      ? config.container
      : SimplePopoverComponent;
    this.attachPopoverContainer(overlayRef, container, injector);

    this.setupBackdropClick(overlayRef, config, popoverRef);

    return popoverRef;
  }

  getScrollStrategy(config: PopoverConfig): ScrollStrategy {
    switch (config.scrollStrategy) {
      case 'reposition':
        return this.overlay.scrollStrategies.reposition();
      case 'close':
        return this.overlay.scrollStrategies.close();
      default:
        return this.overlay.scrollStrategies.block();
    }
  }

  getPositionStrategy(config: PopoverConfig): PositionStrategy {
    if (config.origin) {
      return this.getFlexiblePositionStrategy(config);
    } else {
      return this.getStaticPositionStrategy(config);
    }
  }

  private getStaticPositionStrategy(config: PopoverConfig) {
    let overlay = this.overlay.position().global().centerHorizontally();

    if (config.bottom) {
      overlay = overlay.bottom(config.bottom);
    } else {
      overlay = overlay.top(config.top || '40px');
    }

    return overlay;
  }

  private getFlexiblePositionStrategy(config: PopoverConfig) {
    const origin: any = config.origin;
    const el = origin.nativeElement ? origin.nativeElement : origin;
    return this.overlay
      .position()
      .flexibleConnectedTo(el)
      .withViewportMargin(config.viewportMargin)
      .withPositions(this.getPositions(config));
  }

  private createOverlay(config: PopoverConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config): OverlayConfig {
    const positionStrategy = this.getPositionStrategy(config);
    const scrollStrategy = this.getScrollStrategy(config);

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      width: config.width,
      height: this.getHeight(config),
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      positionStrategy,
      scrollStrategy,
    });
  }

  private getHeight(config) {
    if (!config.height) {
      return undefined;
    }

    const percentageIndex = config.height.indexOf('%');
    if (percentageIndex > -1) {
      return `${(config.height.substr(0, percentageIndex) * window.innerHeight) / 100}px`;
    } else {
      return config.height;
    }
  }

  private attachPopoverContainer(
    overlayRef: OverlayRef,
    component: ComponentType<PopoverComponent>,
    injector: Injector
  ) {
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(
    popoverRef: PopoverRef,
    customTokens: WeakMap<any, any>,
    data: any,
    content: PopoverContent,
    actions: PopoverActions
  ) {
    const injectionTokens = customTokens ? customTokens : new WeakMap();
    injectionTokens.set(PopoverRef, popoverRef);
    injectionTokens.set(POPOVER_DATA, data);
    injectionTokens.set(POPOVER_CONTENT, content);
    injectionTokens.set(POPOVER_ACTIONS, actions);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getPositions(config: PopoverConfig): ConnectionPositionPair[] {
    if (config.positions) {
      return config.positions.map((position) => POSITIONS[position]);
    } else {
      // default is for dropdown
      return [POSITIONS.bottom];
    }
  }

  private setupBackdropClick(overlayRef, config: PopoverConfig, popoverRef: PopoverRef) {
    // https://netbasal.com/advanced-angular-implementing-a-reusable-autocomplete-component-9908c2f04f5

    if (!config.hasBackdrop && !config.allowClicksOutside) {
      this.overlayClickOutside(overlayRef, config.origin).subscribe(() => {
        popoverRef.close();
      });
    } else {
      overlayRef.backdropClick().subscribe((_) => {
        popoverRef.close();
      });
    }
  }

  private overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement | ElementRef) {
    return fromEvent<MouseEvent>(document, 'mouseup').pipe(
      filter((event) => {
        const clickTarget = event.target as HTMLElement;
        const notOrigin = clickTarget !== origin && clickTarget !== origin['nativeElement'];
        // check if click is inside one of active popovers (ex. confirm inside detail bubble)
        const notOverlay =
          !!overlayRef && this.overlayRefs.some((o) => o.overlayElement?.contains(clickTarget)) === false;
        return notOrigin && notOverlay;
      }),
      takeUntil(overlayRef.detachments())
    );
  }

  private setupOverlayRefs(overlayRef: OverlayRef) {
    this.cleanupOverlayRefs();
    const index = this.overlayRefs.push(overlayRef) - 1;
    overlayRef
      .detachments()
      .pipe(take(1))
      .subscribe(() => this.overlayRefs.splice(index, 1));
  }

  private cleanupOverlayRefs() {
    this.overlayRefs = this.overlayRefs.filter((o) => o.overlayElement);
  }
}
