import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthQuery } from '../state/auth-query.service';
import { AuthService } from './abstract-auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const authQuery = inject(AuthQuery);

  return authService.isAuthenticated$.pipe(
    tap((loggedIn) => {
      if (!loggedIn) {
        authService.login(state.url);
      }
    }),
    switchMap(() => authQuery.selectUserContext()),
    tap((userContext) => {
      if (!userContext) {
        authService.logout(true);
      }
    }),
    map((userContext) => !!userContext)
  );
};
