import { bicMask, ibanMask } from './validation';

export const MASKS = {
  bic: bicMask,
  cbe: '0000.000.000',
  iban: ibanMask,
  insz: '00.00.00-000.00',
  streetNumber: '000',
  boxNumber: 'A0',
};
