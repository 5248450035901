<div class="empty__container">
  <div class="empty__image">
    <fa-icon [icon]="icon" size="3x"></fa-icon>
  </div>
  <div class="empty__content">
    <div class="empty__title"><ng-content select=".empty__title"></ng-content></div>
    <div class="empty__description"><ng-content select=".empty__description"></ng-content></div>
    <div class="empty__reasons"><ng-content select=".empty__reasons"></ng-content></div>
    <div class="empty__solution"><ng-content select=".empty__solution"></ng-content></div>
  </div>
</div>
