import { Injectable } from '@angular/core';
import { addYears } from 'date-fns';

export const FUNCTIONAL_COOKIES = 'functionalCookies';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private get cookieString(): string {
    return document.cookie || '';
  }

  private set cookieString(val: string) {
    document.cookie = val;
  }

  /**
   * @name CookieService#get
   *
   * @description
   * Returns the value of given cookie key.
   *
   * @param {string} key Id to use for lookup.
   * @returns {string} Raw cookie value.
   */
  get(key: string): string {
    return (<any>this.cookieReader())[key];
  }

  /**
   * @name CookieService#getObject
   *
   * @description
   * Returns the deserialized value of given cookie key.
   *
   * @param {string} key Id to use for lookup.
   * @returns {Object} Deserialized cookie value.
   */
  getAsObject(key: string): Record<string, unknown> {
    const value = this.get(key);
    return value ? JSON.parse(value) : value;
  }

  /**
   * @name CookieService#put
   *
   * @description
   * Sets a value for given cookie key.
   *
   * @param {string} key Id for the `value`.
   * @param {string} value Raw value to be stored.
   * @param {CookieOptionsArgs} options (Optional) Options object.
   */
  put(key: string, value: string, options?: CookieOptionsArgs) {
    this.cookieString = this.buildCookieString(key, value, options);
  }

  canSetCookies() {
    return this.get(FUNCTIONAL_COOKIES);
  }

  private buildCookieString(name: string, value: string, options?: CookieOptionsArgs): string {
    const cookiePath = '/';
    let expires = options?.expires || addYears(new Date(), 10);

    if (this.isBlank(value)) {
      value = '';
    }
    if (this.isString(expires)) {
      expires = new Date(expires);
    }

    let str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    str += options?.path ? ';path=' + options.path : ';path=' + cookiePath;
    str += options?.domain ? ';domain=' + options.domain : ';domain=' + '.nexuzhealth.pro';
    str += ';expires=' + expires.toUTCString();
    str += options?.secure ? ';secure' : '';

    // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
    // - 300 cookies
    // - 20 cookies per unique domain
    // - 4096 bytes per cookie
    const cookieLength = str.length + 1;
    if (cookieLength > 4096) {
      console.log(`Cookie '${name}' possibly not set or overflowed because it was too 
      large (${cookieLength} > 4096 bytes)!`);
    }
    return str;
  }

  private cookieReader(): Record<string, unknown> {
    let lastCookies = {};
    let lastCookieString = '';

    let cookieArray: string[], index: number, name: string;
    const currentCookieString = this.cookieString;
    if (currentCookieString !== lastCookieString) {
      lastCookieString = currentCookieString;
      cookieArray = lastCookieString.split('; ');
      lastCookies = {};

      for (const cookie of cookieArray) {
        index = cookie.indexOf('=');
        if (index > 0) {
          // ignore nameless cookies
          name = this.safeDecodeURIComponent(cookie.substring(0, index));
          // the first value that is seen for a cookie is the most
          // specific one.  values for the same cookie name that
          // follow are for less specific paths.
          if (this.isBlank((<any>lastCookies)[name])) {
            (<any>lastCookies)[name] = this.safeDecodeURIComponent(cookie.substring(index + 1));
          }
        }
      }
    }
    return lastCookies;
  }

  private safeDecodeURIComponent(str: string) {
    try {
      return decodeURIComponent(str);
    } catch (e) {
      return str;
    }
  }

  private isBlank(obj: any): boolean {
    return obj === undefined || obj === null;
  }

  private isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  private isString(obj: any): obj is string {
    return typeof obj === 'string';
  }
}

export interface CookieOptionsArgs {
  path?: string;
  domain?: string;
  expires?: string | Date;
  secure?: boolean;
}
