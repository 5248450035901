import { Component, Input, OnInit } from '@angular/core';
import { Environment, EnvironmentsService } from '@nexuzhealth/shared/util';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';

@Component({
  selector: 'nxh-nav-item-home',
  templateUrl: './nav-item-home.component.html',
  styleUrls: ['./nav-item-home.component.scss'],
})
export class NavItemHomeComponent implements OnInit {
  @Input() logoSize: 'sm' | 'md' = 'sm';
  @Input() homeLink: string[] | undefined | null;
  envPill: string;

  constructor(private environmentsService: EnvironmentsService, public themeQuery: ThemeQuery) {}

  ngOnInit() {
    if (this.environmentsService.environment !== Environment.PRD) {
      this.envPill = this.environmentsService.environmentFull;
    }
  }
}
