import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EidPersonInfo } from '@nexuzhealth/shared/eid/data-access';
import { DOCUMENT } from '@angular/common';
import { FormHelper } from '@nexuzhealth/shared/ui-toolkit/forms';
import { of } from 'rxjs';

@Component({
  selector: 'nxh-mock-eid-reader',
  templateUrl: 'mock-eid-reader.component.html',
  styleUrls: ['./mock-eid-reader.component.scss'],
})
export class MockEidReaderComponent {
  private readonly modal = inject(NgbActiveModal);

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly jsonExample =
    '{"sub":"1234","name":"Robert","givenName":"Cailliau","middleName":"","gender":{"name":"genders/d6068fce-abfd-4fc6-b3c3-6aa4fdef3c5d"},"documentType":"belgian_citizen","cardnumber":"1234","address":{"street":"Stationsstraat","postalCode":"3700","city":"Tongeren"},"birthdate":"1947-01-25T23:00:00.000Z"}';

  private readonly documentRef = inject(DOCUMENT);

  protected readonly form = this.fb.group({
    sub: ['', Validators.required],
    name: ['', Validators.required],
    givenName: ['', Validators.required],
    middleName: '',
    gender: this.fb.group({
      name: ['', Validators.required],
    }),
    birthdate: this.fb.control<Date | null>(null, Validators.required),
    documentType: 'belgian_citizen',
    cardnumber: ['', Validators.required],
    address: this.fb.group({
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
    }),
  });

  dismiss() {
    this.modal.dismiss();
  }

  submit(helper: FormHelper) {
    // call helper to do validation
    helper
      .submit(() => of(null), { resetOnSuccess: false })
      .subscribe(() => {
        const { birthdate, ...info } = this.form.getRawValue();
        const result: EidPersonInfo = {
          ...info,
          birthdate: birthdate.getDate() + '/' + (birthdate.getMonth() + 1) + '/' + birthdate.getFullYear() ?? '',
        };
        this.modal.close(result);
      });
  }

  paste($event: ClipboardEvent) {
    const data = $event.clipboardData.getData('text');
    try {
      let value = JSON.parse(data);
      value = { ...value, birthdate: value.birthdate ? new Date(value.birthdate) : undefined };
      this.form.setValue(value);
      $event.preventDefault();
    } catch (e) {
      // ignore - not that big a deal
    }
  }

  copy() {
    this.documentRef.defaultView.navigator.clipboard.writeText(this.jsonExample);
  }
}
