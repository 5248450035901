import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared/tech/feature-e2e';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SentenceCasePipeModule } from '@nexuzhealth/shared/ui-toolkit/pipes/sentence-case';
import { CompactFormControlDirectiveModule } from '@nexuzhealth/shared/ui-toolkit/compact-form-control';
import { ValidationModule } from '@nexuzhealth/shared/ui-toolkit/validation';
import { DatePickerComponent } from './date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    SharedTechFeatureE2eModule,
    SharedUtilI18nModule,
    BsDatepickerModule,
    CompactFormControlDirectiveModule,
    SentenceCasePipeModule,
    ValidationModule,
  ],
  exports: [DatePickerComponent],
  declarations: [DatePickerComponent],
})
export class DatePickerModule {}
