import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ResourceName } from '@nexuzhealth/shared/domain';
import { Picture } from './picture.model';

export interface PictureState extends EntityState<Picture, ResourceName>, ActiveState<ResourceName> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'picture',
  idKey: 'name',
})
export class PictureStore extends EntityStore<PictureState, Picture> {
  constructor() {
    super();
  }
}
