import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaritalStatusQuery, MaritalStatusReference } from '@nexuzhealth/shared/reference-data/data-access';
import { I18NextPipe } from 'angular-i18next';
import { SelectReferenceDataBaseComponent } from '../shared/select-reference-data-base.component';

@Component({
  selector: 'nxh-select-marital-status',
  templateUrl: '../shared/select-reference-data-base.component.html',
  styleUrls: ['../shared/select-reference-data-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMaritalStatusComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMaritalStatusComponent extends SelectReferenceDataBaseComponent<MaritalStatusReference> {
  @Input() clearable = true;

  constructor(query: MaritalStatusQuery, i18next: I18NextPipe) {
    super(query, i18next);
  }
}
