<div class="card confirm-popover card--modal--{{ type }}" cdkTrapFocus>
  <div class="confirm-popover__question">
    <fa-icon [icon]="icon" size="lg" class="confirm-popover__icon text-{{ type }}"></fa-icon>
    <div class="capitalize" [innerHTML]="confirmText | nl2br"></div>
  </div>
  <p *ngIf="confirmBody" [innerHTML]="confirmBody | nl2br"></p>
  <div class="confirm-popover__controls">
    <button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="cancel()" [disabled]="busy">
      {{ 'cancel' | i18next }}
    </button>
    <button #btn class="btn btn-{{ type }}" (click)="confirm()" [disabled]="busy">
      <fa-icon class="spinner" *ngIf="busy" [spin]="true" icon="sync"></fa-icon>
      {{ buttonText | i18next }}
    </button>
  </div>
</div>
