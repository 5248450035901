import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

export enum Environment {
  LOCAL = 'local',
  DEV = 'dev',
  DEV_INT = 'int.dev',
  TST = 'tst',
  ACC = 'acc',
  PRD = 'prd',
}

export enum EnvironmentFull {
  LOCAL = 'local',
  DEV = 'development',
  DEV_INT = 'development-int',
  TST = 'test',
  ACC = 'acceptance',
  PRD = 'production',
}

const environmentFullLookup = {
  [Environment.LOCAL]: EnvironmentFull.LOCAL,
  [Environment.DEV]: EnvironmentFull.DEV,
  [Environment.DEV_INT]: EnvironmentFull.DEV_INT,
  [Environment.TST]: EnvironmentFull.TST,
  [Environment.ACC]: EnvironmentFull.ACC,
  [Environment.PRD]: EnvironmentFull.PRD,
} as const;

@Injectable({ providedIn: 'root' })
export class EnvironmentsService {
  readonly environment = getEnvironment(inject(DOCUMENT).location.hostname);
  readonly environmentFull = environmentFullLookup[this.environment];
}

export function getEnvironment(hostname: string, sperator = '.'): Environment {
  const hostParts = hostname.split(sperator);

  if (hostParts[0].includes('-int') && hostParts[1] === Environment.DEV) {
    return Environment.DEV_INT;
  }

  return Object.values(Environment).find((hostPart) => hostParts[1] === hostPart) || Environment.PRD;
}
