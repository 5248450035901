import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'nxh-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyComponent {
  @HostBinding('class')
  _hostClasses = 'modal-body';
  @HostBinding('class.nxh-modal-body-no-top-padding')
  _noTopPadding!: boolean;

  @HostBinding('class.nxh-modal-body-no-padding')
  @Input()
  noPadding = false;

  @HostBinding('class.nxh-modal-body--fit-content')
  @Input()
  fitContent = false;
}
