<nxh-loading-states
  [loadingState]="loadingState"
  [emptyTemplate]="emptyTemplate"
  [errorTemplate]="errorTemplate"
  [loadingTemplate]="loadingTemplate"
  *ngIf="loadingState && loadingState !== 'loaded'"
/>
<div class="card-body__content">
  <ng-content />
</div>
