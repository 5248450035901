import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

/**
 * @Deprecated Don't use nxh-card for modals. In stead, use the components from the ModalModule: ModalHeaderComponent,
 * ModalBodyComponent, ModalFooterComponent.
 */
@Component({
  selector: 'nxh-card-modal-header',
  templateUrl: './card-modal-header.component.html',
  styleUrls: ['./card-modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardModalHeaderComponent {
  @Input() type: 'primary' | 'success' | 'danger' | 'warning' = 'primary';

  @HostBinding('class.nxh-card-header-closeable')
  @Input()
  closeable = true;

  @HostBinding('class.nxh-card-header-dark')
  @Input()
  dark = false;

  @HostBinding('class') get classAttribute(): string {
    const defaultClasses = ['card-header', 'nxh-card-component', 'nxh-card-modal-header'];
    const typeClass = 'nxh-card-modal-header-' + this.type;
    return defaultClasses.concat(typeClass).join(' ');
  }

  @Output() clickedClose = new EventEmitter<void>();

  constructor() {}
}
