import { AdministrativeGenderReference } from '@nexuzhealth/shared/reference-data/data-access';
import { Address, AssignedIdSourceEnum, SuggestedPatientsResponse } from '@nexuzhealth/shared/domain';
import { InjectionToken } from '@angular/core';
import {
  ELECTRONIC_ID_KIDS_ID,
  FOREIGNERS_CARD,
  HAS_NO_EID,
  ISI_PLUS,
  LOST_OR_STOLEN_DOCUMENT,
  MediumReasonEnum,
  MediumTypeEnum,
  ReasonEnum,
  SOCIAL_SECURITY_DOCUMENT,
  VIGNETTE_NO_IDENTITY_DOCUMENT,
  VIGNETTE_PATIENT_UNAVAILABLE,
} from '@nexuzhealth/shared/contact-stream/contact-detail/homenursing-detail/domain';

export const EID_DETAILED_INFO = new InjectionToken<EidDetailedInfo>('eid detailed info');
export const USE_MOCK_EID_READER = new InjectionToken<boolean>('eid mock reader');

export interface NoCardReadingReason {
  name: string;
  reason: ReasonEnum;
  mediums: NoCardReadingReasonMedium[];
}

export interface NoCardReadingReasonMedium {
  name: string;
  mediumType: MediumTypeEnum;
  mediumTypeReason: MediumReasonEnum;
  isDefault?: boolean;
  cardNumberRequired: boolean;
  i18n: string;
}

export const TYPE_TO_I18N_MAP: Record<MediumTypeEnum, string> = {
  [MediumTypeEnum.EID]: ELECTRONIC_ID_KIDS_ID.i18n,
  [MediumTypeEnum.ISIPlus]: ISI_PLUS.i18n,
  //[MediumTypeEnum.Vignette]: 'invalid',
  [MediumTypeEnum.SocialSecurityDocument]: SOCIAL_SECURITY_DOCUMENT.i18n,
  [MediumTypeEnum.LostOrStolenDocument]: LOST_OR_STOLEN_DOCUMENT.i18n,
  [MediumTypeEnum.ForeignersCard]: FOREIGNERS_CARD.i18n,
  [MediumTypeEnum.HasNoEID]: HAS_NO_EID.i18n,
  [MediumTypeEnum.Vignette]: 'invalid', // Vignette doesnt map to a single translation key
};

export function getTranslationKey(mediumId: MediumTypeEnum, mediumTypeReasonId: MediumReasonEnum): string {
  if (mediumId === MediumTypeEnum.Vignette) {
    if (mediumTypeReasonId === MediumReasonEnum.NoIdentityDocument) {
      return VIGNETTE_NO_IDENTITY_DOCUMENT.i18n;
    }
    if (mediumTypeReasonId === MediumReasonEnum.PatientNotAvailable) {
      return VIGNETTE_PATIENT_UNAVAILABLE.i18n;
    }
  }
  return TYPE_TO_I18N_MAP[mediumId];
}

export interface EidDetailedInfo {
  personInfo: EidPersonInfo;
  queryDate?: Date;
  source?: AssignedIdSourceEnum;
  mode?: 'therapeuticRelation' | 'administrativeRelation' | 'other' | null;
  preventNavigate?: boolean;
  hasAdministrativeRelation?: boolean;
}

export interface EidDetailsView {
  userInfo?: EidPersonInfo;
  name?: string;
  birthDate?: Date | string;
  address?: Address;
}

export interface EidResponse {
  view: EidDetailsView;
  matchingPatients?: SuggestedPatientsResponse;
}

export interface EidPersonInfo {
  patientName?: string;

  sub?: string;
  isi?: string;
  name?: string;
  givenName?: string;
  middleName?: string;
  picture?: string;
  photo?: string;
  gender?: Partial<AdministrativeGenderReference>;
  birthdate?: string;
  deceaseddate?: string;
  validityDateBegin?: string;
  validityDateEnd?: string;
  cardDeliveryMunicipality?: string;
  documentType?: string;
  nationality?: string;
  birthLocation?: string;
  certificateVerified?: boolean;
  dataValidated?: boolean;
  signatureVerified?: boolean;
  authenticationCertificateExpired?: boolean;
  authenticationCertificateRevoked?: boolean;
  cardnumber?: string;
  address?: Address;
}

export enum ReasonNoCardReadingType {
  BABY = 'baby',
  GMF_HOLDER = 'gmf-holder',
  CARD_NOT_PRESENT = 'card-not-present',
  HOME_VISIT = 'home-visit',
  TECHNICAL_ISSUE = 'technical-issue',
  // DEV_TESTING should only be visible in dev and test environments
  DEV_TESTING = 'dev-testing',
  OTHER = 'other',
  SECRET_PROVIDED = 'secret-provided',

  //FOR FE
  READ_EID = 'read-eid',
  ISI = 'isi',
}
