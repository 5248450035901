<nxh-modal-header (dismiss)="dismiss()">
  <nxh-card-title>{{ '_eid._mock-reader.title' | i18next }}</nxh-card-title>
</nxh-modal-header>
<nxh-modal-body>
  <!-- noFocus: true - ngb-modal takes care of this and messes up with the first input's touched status, resulting in a
  val-errors showing a validation error-->
  <form
    [formGroup]="form"
    nhForm
    #f="nhForm"
    [config]="{ noFocus: true }"
    (ngSubmit)="submit(f.helper)"
    (paste)="paste($event)"
  >
    <nh-control [label]="'_personal.inss' | i18next" [required]="true">
      <input formControlName="sub" placeholder="12345678901" />
      <val-errors></val-errors>
    </nh-control>
    <nh-control [label]="'name' | i18next" [required]="true">
      <input formControlName="name" />
      <val-errors></val-errors>
    </nh-control>
    <nh-control [label]="'_personal.firstname' | i18next" [required]="true">
      <input formControlName="givenName" />
      <val-errors></val-errors>
    </nh-control>
    <nh-control [label]="'_personal.middle-name' | i18next"> <input formControlName="middleName" /></nh-control>
    <ng-container formGroupName="gender">
      <nh-control [label]="'_personal.administrative-gender' | i18next" [required]="true">
        <nxh-select-administrative-gender formControlName="name" />
        <val-errors></val-errors>
      </nh-control>
    </ng-container>
    <nh-control [label]="'_personal.birthdate' | i18next" [required]="true">
      <nxh-date-picker formControlName="birthdate"></nxh-date-picker>
      <val-errors></val-errors>
    </nh-control>
    <nh-control [label]="'document-type' | i18next" [required]="true">
      <input formControlName="documentType" />
      <val-errors></val-errors>
    </nh-control>
    <nh-control [label]="'_personal.identity-card-number' | i18next" [required]="true">
      <input formControlName="cardnumber" placeholder="1234567890123" />
      <val-errors></val-errors>
    </nh-control>
    <ng-container formGroupName="address">
      <nh-control [label]="'_address.street' | i18next" [required]="true">
        <input formControlName="street" />
        <val-errors></val-errors>
      </nh-control>
      <nh-control [label]="'_address.postal-code' | i18next" [required]="true">
        <input formControlName="postalCode" />
        <val-errors></val-errors>
      </nh-control>
      <nh-control [label]="'_address.city' | i18next" [required]="true">
        <input formControlName="city" />
        <val-errors></val-errors>
      </nh-control>
    </ng-container>
  </form>
  <div class="info-copy-paste">
    <span>{{ '_eid._mock-reader.tip' | i18next }}</span>
    <button nxh-button [buttonType]="'link'" (click)="copy()">
      {{ '_eid._mock-reader.copy-example' | i18next }}
    </button>
  </div>
</nxh-modal-body>
<nxh-modal-footer [connectNxhForm]="f">
  <nxh-modal-controls>
    <nh-cancel-button (click)="dismiss()"></nh-cancel-button>
    <nh-submit-button nxhDataTest="submitMockEid">{{ '_eid._mock-reader.submit' | i18next }}</nh-submit-button>
  </nxh-modal-controls>
</nxh-modal-footer>
