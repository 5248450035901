import { Component, Inject } from '@angular/core';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { NavTarget } from '../nav-item-simple/nav-item-simple.component';

@Component({
  selector: 'nxh-nav-item-external-links',
  templateUrl: './nav-item-external-links.component.html',
  styleUrls: ['./nav-item-external-links.component.scss'],
})
export class NavItemExternalLinksComponent {
  constructor(@Inject(I18NEXT_SERVICE) private i18Next: ITranslationService, private settings: SettingsService) {}

  navigateToFamhp() {
    const urls = {
      nl: 'https://famhp-vons.prd.pub.vascloud.be/nl/form/PVH',
      en: 'https://famhp-vons.prd.pub.vascloud.be/nl/form/PVH',
      fr: 'https://famhp-vons.prd.pub.vascloud.be/fr/form/PVH',
    };
    const url = urls[this.i18Next.language];
    window.open(url, NavTarget.BLANK);
  }

  navigateToEhealthCertInfo() {
    const urls = this.settings.getSystemSettingValue('ehealth.certificates.generalInformation');
    const url = urls[this.i18Next.language] || urls['nl'];
    window.open(url, NavTarget.BLANK);
  }

  navigateToEhealthCertDownloadManager() {
    const url = this.settings.getSystemSettingValue('ehealth.certificates.downloadManager') as string;
    window.open(url, NavTarget.BLANK);
  }
}
