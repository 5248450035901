<div class="breadcrumb__container">
  <ng-container *ngFor="let bc of breadcrumbs$ | async; let last = last">
    <div class="d-inline-block {{ bc.classnames }}" [ngSwitch]="last" *ngIf="bc.label">
      <ng-container *ngSwitchCase="true">
        <span class="breadcrumb__item--span">{{ bc.label }}</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <a class="breadcrumb__item--link" [routerLink]="bc.extraUrl ? bc.extraUrl : bc.url">{{ bc.label }}</a>
        <span class="breadcrumb__separator" *ngIf="!last">/</span>
      </ng-container>
    </div>
  </ng-container>
</div>
