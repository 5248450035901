import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import {
  EID_DETAILED_INFO,
  EidDetailedInfo,
  EidDetailsView,
  EidPersonInfo,
  EidResponse,
  ReasonNoCardReadingType,
  USE_MOCK_EID_READER,
} from '@nexuzhealth/shared/eid/data-access';
import {
  AccessRole,
  AssignedId,
  AssignedIdSourceEnum,
  AssignedIdSourceTypeEnum,
  AssignedIdTypenameEnum,
  DateTimePrecision,
  ExtendedPatient,
  Patient,
  SuggestedPatientsResponse,
} from '@nexuzhealth/shared/domain';
import { PatientService } from '@nexuzhealth/shared/patient/data-access';
import { AdministrativeGenderQuery, ReferenceService } from '@nexuzhealth/shared/reference-data/data-access';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FormHelper, FormHelperDirective } from '@nexuzhealth/shared/ui-toolkit/forms';
import { FAR_AWAY_AS_STRING } from '@nexuzhealth/shared/util';
import { EidSyncModalComponent } from '../components/eid-sync-modal/eid-sync-modal.component';
import { EidSyncModalOptions } from '../components/eid-sync-modal/eid-sync-options';
import { EidService } from '../shared/eid.service';
import {
  handleVerifyIdError,
  handleVerifyIdFailureResponse,
  handleVerifyIdInvalidResponse,
  VerifyIdResponse,
} from '../shared/id-support-model';
import { EidFormComponent } from '../components/upsert/eid-form/eid-form.component';
import { MockEidReaderComponent } from '../components/mock-eid-reader/mock-eid-reader.component';
import { IdSupportApiService } from './id-support-api.service';

type State = 'request' | 'form' | 'loading' | 'error';
type SearchState = 'none' | 'loading' | 'found' | 'unknown' | 'error' | 'suggestions';

export const EidModalUseValueEid = {
  source: AssignedIdSourceEnum.EID,
};

@Component({
  selector: 'nxh-eid-modal-v2',
  templateUrl: './eid-modal.component.html',
  styleUrls: ['./eid-modal.component.scss'],
  providers: [EidService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EidModalV2Component implements OnInit, OnDestroy {
  readonly spinnerIcon = faSpinnerThird;
  readonly errorIcon = faExclamationCircle;
  readonly destroy$$ = new Subject<void>();

  readonly form = new UntypedFormGroup({
    eidForm: new UntypedFormControl({}),
    motivation: new UntypedFormControl(''),
    eHealthTr: new UntypedFormControl({ value: true, disabled: true }),
  });
  @ViewChild('f') formHelper!: FormHelperDirective;
  @ViewChild(EidFormComponent, { static: false, read: EidFormComponent })
  suggestionSelected = false;

  /**
   * data from the eid-form.
   */
  get eidForm(): UntypedFormControl {
    return this.form.get('eidForm') as unknown as UntypedFormControl;
  }

  /**
   * Is the popup opened as part of a "I want to read eid" flow. Example of this
   * is the eid button on top of the application.
   *
   * This is set using the `source` property on the `EID_DETAIL_INFO` object.
   */
  readonly eidSource$$ = new BehaviorSubject(false);

  get eidSource() {
    return this.eidSource$$.value;
  }

  set eidSource(value: boolean) {
    this.eidSource$$.next(value);
  }

  readonly state$$ = new BehaviorSubject<State>('request');
  readonly searchState$$ = new BehaviorSubject<SearchState>('none');
  readonly idSupportError$$ = new BehaviorSubject<boolean>(false);
  readonly idSupportLoading$$ = new BehaviorSubject<boolean>(false);

  // mainPatient$ contains the patient displayed at the top of the eid modal
  readonly mainPatient$$ = new BehaviorSubject<ExtendedPatient>(null);
  // selectedPatient$ contains either the main patient or the suggestion the user has selected
  readonly selectedPatient$$ = new BehaviorSubject<ExtendedPatient>(null);

  readonly showEHealthTRCheckbox$ = this.selectedPatient$$.pipe(
    map((pat) => !pat.activeTherapeuticRelation && this.isAuthorizedForTR() && this.hasInss(pat))
  );

  readonly showOpenDossierButton$ = this.selectedPatient$$.pipe(
    map(
      (pat) =>
        pat?.name &&
        pat?.activeAdministrativeRelation &&
        (!this.isAuthorizedForTR() || (this.isAuthorizedForTR() && pat?.activeTherapeuticRelation))
    )
  );

  readonly showCreateTRButton$ = this.selectedPatient$$.pipe(
    map((pat) => pat?.name && !pat?.activeTherapeuticRelation && this.isAuthorizedForTR())
  );

  readonly showCreateARButton$ = this.selectedPatient$$.pipe(
    map(
      (pat) =>
        pat?.name &&
        !pat?.activeAdministrativeRelation &&
        (!this.isAuthorizedForTR() || (this.isAuthorizedForTR() && pat?.activeTherapeuticRelation))
    )
  );

  readonly showCreateDossierButton$ = this.selectedPatient$$.pipe(map((pat) => !pat?.name));

  /**
   * The latest inss from either personInfo or selectedPatient
   */
  readonly sourceInss$ = this.selectedPatient$$.pipe(
    map((patient) => {
      return patient?.assignedIds?.find((id) => id.typeName === AssignedIdTypenameEnum.BE_INSS)?.value ?? '';
    })
  );

  readonly eidForm$ = this.eidForm.valueChanges.pipe(startWith(this.eidForm.value)) as Observable<EidForm>;

  warningMessageTitle: string;
  warningMessageBody: string;

  // eidDetailsView should only be used by the nxh-eid-detail component
  eidDetailsView: EidDetailsView;
  patientSuggestions: any[];

  get selectedPatient(): ExtendedPatient {
    return this.selectedPatient$$.value;
  }

  // only use detailInfo.mode for the title, because the title is shown before the patient is loaded
  get title(): string {
    const useResults = !!this.detailInfo.personInfo;
    if (!useResults) {
      return '_eid._modal.title';
    }
    if (this.detailInfo.mode === 'therapeuticRelation') {
      return '_eid._modal.therapeutic-relation-title';
    }
    if (this.detailInfo.mode === 'administrativeRelation') {
      return '_eid._modal.administrative-relation-title';
    }
    return '_eid._modal.use-results-title';
  }

  private eidFormComponent: EidFormComponent | undefined;

  private readonly doVerifyId = this.settings.getComponentSettingValue(
    'components.eid-modal-component.doVerifyId',
    false
  );

  constructor(
    private activeModal: NgbActiveModal,
    private patientService: PatientService,
    private toastrService: ToastrService,
    private i18n: I18NextPipe,
    private cdr: ChangeDetectorRef,
    @Inject(EID_DETAILED_INFO) public detailInfo: EidDetailedInfo,
    private eidService: EidService,
    private idSupportApiService: IdSupportApiService,
    private settings: SettingsService,
    private injector: Injector,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private administrativeGenderQuery: AdministrativeGenderQuery,
    private referenceService: ReferenceService,
    @Inject(USE_MOCK_EID_READER) @Optional() private useMockEidReader = false
  ) {
    if (detailInfo) {
      this.eidSource = detailInfo.source === AssignedIdSourceEnum.EID;
    }
  }

  ngOnInit() {
    console.log('eid modal v2');
    if (this.administrativeGenderQuery.getCount() === 0) {
      this.referenceService.getAdministrativeGenderValues().subscribe();
    }
    this.listenForEhealthTRChanges();
    if (!this.detailInfo.personInfo) {
      this.initFromEidRead();
    } else {
      this.initFromProvidedInfo();
    }
    this.configureAutomaticTRCreation();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  initFromEidRead() {
    this.state$$.next('loading');

    let retrieveEidData$: Observable<EidResponse>;
    if (this.useMockEidReader) {
      retrieveEidData$ = from(this.modalService.open(MockEidReaderComponent, { size: 'lg' }).result).pipe(
        mergeMap((info) => {
          console.log(info);
          const userInfo = this.eidService.mapToUserInfo(info);
          return combineLatest(of(userInfo), this.patientService.searchSuggested(userInfo));
        }),
        map(([info, suggested]) => {
          return {
            view: this.eidService.getEidDetailsView(info),
            matchingPatients: suggested,
          };
        })
      );
    } else {
      retrieveEidData$ = this.eidService.retrieveEIDData();
    }

    retrieveEidData$.subscribe({
      next: (resp) => {
        const detailView = resp.view;
        if (!detailView) {
          this.state$$.next('request');
          return;
        }
        this.detailInfo = {
          personInfo: detailView.userInfo,
          queryDate: new Date(),
        };
        this.eidDetailsView = detailView;
        const pat = convertToPatient(this.detailInfo);
        this.selectedPatient$$.next(pat);
        this.mainPatient$$.next(pat);
        this.state$$.next('form');

        this.applySuggestions(of(resp.matchingPatients), detailView.userInfo.sub, detailView.userInfo.cardnumber);
      },
      error: (err) => {
        console.error(err);
        this.state$$.next('error');
      },
      complete: () => {
        if (this.state$$.getValue() === 'loading') {
          this.state$$.next('request');
        }
      },
    });
  }

  get eidFormTitle() {
    if (!this.isAuthorizedForTR() && !this.detailInfo.hasAdministrativeRelation) {
      return '_eid._ar.intro';
    }
    if (!this.selectedPatient?.name) {
      return '_eid._tr.intro-unknown';
    }
    return '_eid._tr.intro';
  }

  arePatientSuggestionsVisible(): boolean {
    return this.patientSuggestions?.length > 0;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  close(patient: Patient = null) {
    this.activeModal.close(patient);
    if (this.eidSource && patient && this.authService.isAuthorized({ or: [AccessRole.clinical, AccessRole.clerk] })) {
      this.checkNewEidData(patient);
    }
  }

  getIcon(check: boolean) {
    return check ? faCheck : faTimes;
  }

  isAuthorizedForTR() {
    return this.authService.isAuthorized(AccessRole.clinical);
  }

  getExcludedColumns() {
    return this.isAuthorizedForTR() ? new Set('') : new Set(['relation']);
  }

  setPatient(event: ExtendedPatient) {
    if (event === null) {
      this.selectedPatient$$.next(this.mainPatient$$.value);
      this.suggestionSelected = false;
    } else {
      this.selectedPatient$$.next(event);
      this.suggestionSelected = true;
    }
  }

  createTr(fh: FormHelper) {
    const createAr = this.detailInfo?.mode !== 'therapeuticRelation';
    this.openDossier(true, createAr, fh);
  }

  createAr(fh: FormHelper) {
    this.openDossier(false, true, fh);
  }

  createTempPatient(fh: FormHelper) {
    const req = () => {
      const patient = convertToTempPatient(convertToPatient(this.detailInfo));
      return this.createPatient(patient);
    };

    fh.submit(req, { validate: false, resetOnSuccess: false }).subscribe({
      next: (patient) => {
        if (!this.detailInfo.preventNavigate) {
          this.router.navigate([
            this.settings.getComponentSettingValue('components.patient.link', 'patients'),
            patient.name,
          ]);
        }
        this.activeModal.close(patient.name);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openDossier(createTr = false, createAr = false, fh: FormHelper = null) {
    this.form.markAllAsTouched();
    this.eidFormComponent?.markAsTouched();
    if (!createAr && !createTr) {
      this.openDossierWithMerge();
    } else {
      const trCall = this.getRegisterPatientRequest(createTr, createAr);
      fh.submit(trCall, { resetOnSuccess: false })
        .pipe(
          mergeMap((trRes) => {
            return this.handleRegisterPatientResponse(trRes, createTr);
          }),
          catchError((err) => {
            return this.handleRegisterPatientError(err, createTr, createAr);
          })
        )
        .subscribe();
    }
  }

  checkNewEidData(patient) {
    this.patientService.checkEidData(patient, this.detailInfo.personInfo).subscribe((result) => {
      if (result?.status === 'OUTDATED') {
        this.openSyncModal(patient, this.detailInfo.personInfo);
      }
    });
  }

  openSyncModal(patient, eidData) {
    const injector = Injector.create({
      providers: [
        {
          provide: EidSyncModalOptions,
          useValue: {
            patient: patient,
            eidData: eidData,
          },
        },
      ],
      parent: this.injector,
    });

    this.modalService.open(EidSyncModalComponent, {
      injector,
      size: 'xl',
    });
  }

  createDossier() {
    const req = () => {
      const patient = convertToPatient(this.detailInfo);
      return defer(() => {
        return this.createPatient(patient);
      });
    };

    this.formHelper.helper
      .submit(req, {
        resetOnSuccess: false,
      })
      .subscribe(
        (p) => {
          this.toastrService.success(this.i18n.transform('_eid._modal.create-success'));
          this.close(p);
        },
        (error) => {
          console.error(error);
          this.toastrService.error(
            this.i18n.transform('_eid._modal.create-error'),
            this.i18n.transform('_eid._modal.create-error-title')
          );
        }
      );
  }

  createPatient(patient: Patient) {
    const eidForm = this.eidForm?.value;
    let identifiers = {
      inss: eidForm?.ssin?.value,
      eid: eidForm?.eid?.value === '' ? undefined : eidForm?.eid?.value,
      isi: eidForm?.isi?.value === '' ? undefined : eidForm?.isi?.value,
    };
    const eidRead = eidForm?.reasonNoCardReading?.value === ReasonNoCardReadingType.READ_EID;
    const reasonNoCardReadingValue = eidRead ? '' : eidForm?.reasonNoCardReading?.value;
    if (this.eidSource) {
      identifiers = removeFalsyValues(this.getEidReadValue()) as any;
    }
    return this.patientService
      .createPatient(
        patient,
        this.shouldCreateEHealthTherapeuticRelation() && !isTempPatient(patient),
        identifiers,
        reasonNoCardReadingValue
      )
      .pipe(
        mergeMap((newPat) => {
          // If the selected patient was not validated, merge into the new patient
          if (this.detailInfo?.personInfo.dataValidated === false) {
            return this.mergePatients(newPat, this.detailInfo?.personInfo.patientName).pipe(
              take(1),
              catchError(() => {
                this.toastrService.warning(
                  this.i18n.transform('_eid._modal.patientMerge-error'),
                  this.i18n.transform('_eid._modal.patientMerge-error-title')
                );
                return of(newPat);
              })
            );
          }
          return of(newPat);
        })
      );
  }

  mergePatients(main: Patient, mergingPatientName: string): Observable<Patient> {
    return this.patientService.getPatient(mergingPatientName, 'MINIMAL').pipe(
      switchMap((oldPat) => {
        return this.patientService
          .mergePatients({
            mainPatient: main,
            mergingPatient: oldPat,
          })
          .pipe(
            map(() => {
              return main;
            })
          );
      })
    );
  }

  retryRead() {
    this.initFromEidRead();
  }

  retrySearch() {
    this.searchForPatient(this.detailInfo.personInfo);
  }

  shouldCreateEHealthTherapeuticRelation() {
    return (
      this.form.get('eHealthTr').value === true &&
      this.isAuthorizedForTR() &&
      (this.selectedPatient?.assignedIds.some((id) => id.typeName === AssignedIdTypenameEnum.BE_INSS) ?? false)
    );
  }

  hasInss(pat: Patient) {
    if (!pat.assignedIds) {
      return false;
    }
    return pat.assignedIds?.some((id) => id.typeName === AssignedIdTypenameEnum.BE_INSS) ?? false;
  }

  private getEidReadValue() {
    return {
      inss: this.detailInfo.personInfo?.sub ?? '',
      eid: this.detailInfo.personInfo?.cardnumber ?? '',
    };
  }

  private verifyId(idNumber: string, cardNumber: string) {
    // This detailInfo.source is not updated in the selectedPatient variable, is this a problem?
    this.detailInfo.source = AssignedIdSourceEnum.IDSUPPORT;
    this.idSupportLoading$$.next(true);
    this.idSupportApiService.verifyId(idNumber, cardNumber).subscribe({
      next: (response) => {
        this.handleVerifyIdResponse(response);
      },
      error: (err) => {
        const message = handleVerifyIdError(err, this.i18n);
        this.warningMessageTitle = message.title;
        this.warningMessageBody = message.body;

        this.idSupportError$$.next(true);
        this.idSupportLoading$$.next(false);
      },
      complete: () => {
        this.idSupportLoading$$.next(false);
      },
    });
  }

  private handleVerifyIdResponse(response: VerifyIdResponse) {
    if (!response.success) {
      this.idSupportError$$.next(true);
      const message = handleVerifyIdFailureResponse(response, this.i18n);
      this.warningMessageTitle = message.title;
      this.warningMessageBody = message.body;
    } else if (!response.valid) {
      this.idSupportError$$.next(true);
      const message = handleVerifyIdInvalidResponse(response, this.i18n);
      this.warningMessageTitle = message.title;
      this.warningMessageBody = message.body;
    }
  }

  private applySuggestions(suggestions: Observable<SuggestedPatientsResponse>, inss: string, cardNumber?: string) {
    suggestions
      .pipe(
        switchMap((resp) => {
          const hasCardNumber = cardNumber?.length > 0;
          if (!resp.match && this.doVerifyId && hasCardNumber) {
            this.verifyId(inss, cardNumber);
          }
          if (!resp.match) {
            const filtered = resp.suggestions
              ?.filter((x) => {
                return (
                  this.hasInss(x.patient) ||
                  (this.isAuthorizedForTR() && x.activeTherapeuticRelation) ||
                  (!this.isAuthorizedForTR() && x.activeAdministrativeRelation)
                );
              })
              .map((pat) => {
                return {
                  ...pat.patient,
                  activeTherapeuticRelation: pat.activeTherapeuticRelation,
                  activeAdministrativeRelation: pat.activeAdministrativeRelation,
                };
              });
            return of(filtered);
          }
          this.searchState$$.next('found');
          const extendedPatient: ExtendedPatient = {
            ...resp.match.patient,
            activeTherapeuticRelation: resp.match.activeTherapeuticRelation,
            activeAdministrativeRelation: resp.match.activeAdministrativeRelation,
          };
          return of(extendedPatient);
        }),
        takeUntil(this.destroy$$)
      )
      .subscribe({
        next: (result) => {
          if (!result || (Array.isArray(result) && !result.length)) {
            if (!this.mainPatient$$.getValue().name) {
              this.searchState$$.next('unknown');
            } else {
              this.searchState$$.next('none');
            }
            return;
          }
          if (Array.isArray(result)) {
            this.searchState$$.next('suggestions');
            this.patientSuggestions = result;
          } else {
            this.selectedPatient$$.next(result);
            this.mainPatient$$.next(result);
          }
          this.cdr.markForCheck();
        },
        error: (err) => {
          console.error(err);
          this.searchState$$.next('error');
        },
      });
  }

  private searchForPatient(patient: EidPersonInfo, cardNumber?: string) {
    this.searchState$$.next('loading');
    const suggestionResponse = this.patientService.searchSuggested(patient);
    this.applySuggestions(suggestionResponse, patient.sub, cardNumber);
  }

  private handleRegisterPatientError(err, createTr: boolean, createAr: boolean) {
    // fallback if the TR (trCall) fails or if there are form validation errors
    let message = '';
    let title = '';
    let showToast = false;
    if (!createTr || err?.kind?.startsWith('pm_administrativelink_') === true) {
      if (!createAr) {
        title = '_eid._modal.tr-read-error-title';
        message = '_eid._modal.tr-read-error-message';
        showToast = true;
      } else if (err.kind !== 'pm_administrativelink_already-exists') {
        title = '_eid._modal.medLink-create-error-title';
        message = '_eid._modal.medLink-create-error-message';
        showToast = true;
      }
    }
    if (createTr && err?.kind !== 'pm_therapeuticrelation_already-exists') {
      title = '_eid._modal.tr-register-error-title';
      message = '_eid._modal.tr-register-error';
      showToast = true;
    }
    if (showToast) {
      this.toastrService.error(this.i18n.transform(message), this.i18n.transform(title));
    } else {
      this.navigateToDossier(this.selectedPatient.name);
    }
    return EMPTY;
  }

  private handleRegisterPatientResponse(trRes, createTr: boolean) {
    if (!this.selectedPatient.activeTherapeuticRelation && createTr) {
      this.toastrService.success(this.i18n.transform('_eid._modal.tr-create-success'));
    }
    if (trRes.ehealthRelationCreated) {
      this.toastrService.success(this.i18n.transform('_consent.ehealth-registration-successful'));
    } else if (trRes.ehealthError) {
      const err = trRes.ehealthError;
      let message = '';
      const serviceMsg = this.i18n.transform('_therapeutic-relation.ehealth-title', { format: 'cap' });
      const errorTrans = this.i18n.transform(err.translationKey);
      const errorMsg = err.kind === 'unspecified' || errorTrans === err.translationKey ? err.message : errorTrans;
      message += `\u2022${serviceMsg} (${errorMsg})<br/>`;
      this.toastrService.error(message, this.i18n.transform('_consent._error.ehealth-registration-consent'), {
        enableHtml: true,
      });
    }
    this.openDossierWithMerge();
    return EMPTY;
  }

  private getRegisterPatientRequest(createTr: boolean, createAr: boolean) {
    return () => {
      if (this.eidSource) {
        return this.patientService.registerPatient(this.selectedPatient.name, {
          createTherapeuticRelation: createTr,
          motivation: '',
          createAdministrativeRelation: createAr,
          type: 'EID_READ',
          identifiers: removeFalsyValues(this.getEidReadValue()) as any,
          createEhealthTherapeuticRelation: this.shouldCreateEHealthTherapeuticRelation(),
        } as any);
      } else {
        const motivation = this.form.get('motivation');
        const eid = this.eidForm?.value.eid;
        const isi = this.eidForm?.value.isi;
        const reasonNoCardReading = this.eidForm?.value.reasonNoCardReading;
        const reasonFreeText = this.eidForm?.value.reasonFreeText;
        const eidRead = reasonNoCardReading?.value === ReasonNoCardReadingType.READ_EID;
        let reasonNoCardReadingValue = reasonNoCardReading?.value;
        if (
          reasonNoCardReadingValue === undefined &&
          this.searchState$$.getValue() === 'found' &&
          this.detailInfo.personInfo.sub?.length > 0
        ) {
          reasonNoCardReadingValue = ReasonNoCardReadingType.SECRET_PROVIDED;
        }
        return this.patientService.registerPatient(this.selectedPatient.name, {
          createTherapeuticRelation: createTr,
          motivation: createTr ? motivation.value : '',
          createAdministrativeRelation: createAr,
          type: eidRead ? 'EID_READ' : 'EID_MANUAL',
          reasonNoCardReading: eidRead ? '' : reasonNoCardReadingValue, // no name required when reading eid.
          reasonFreeText,
          identifiers: removeFalsyValues({
            eid: eid?.value,
            isi: isi?.value,
          }) as any,
          createEhealthTherapeuticRelation: this.shouldCreateEHealthTherapeuticRelation(),
        });
      }
      return of(null);
    };
  }

  private openDossierWithMerge() {
    if (this.detailInfo?.personInfo.dataValidated === false) {
      this.selectedPatient$$
        .pipe(
          take(1),
          mergeMap((newPat) => {
            const merger = this.mergePatients(newPat, this.detailInfo?.personInfo.patientName).pipe(
              catchError(() => {
                this.toastrService.warning(
                  this.i18n.transform('_eid._modal.patientMerge-error'),
                  this.i18n.transform('_eid._modal.patientMerge-error-title')
                );
                return of(newPat);
              })
            );
            if (!this.detailInfo.preventNavigate) {
              this.navigateToDossier(newPat.name);
            } else {
              this.activeModal.close(newPat.name);
            }
            return merger;
          })
        )
        .subscribe();
    } else {
      if (!this.detailInfo.preventNavigate) {
        this.navigateToDossier(this.selectedPatient.name);
      } else {
        this.activeModal.close(this.selectedPatient.name);
      }
    }
  }

  // this is close but with the patientName instead of the patient itself -_-
  private navigateToDossier(patientName: string) {
    if (this.selectedPatient && this.eidSource$$.value) {
      this.checkNewEidData(this.selectedPatient);
    }
    this.activeModal.close(patientName);
  }

  // Automatic tr creation
  private configureAutomaticTRCreation() {
    this.eidForm$
      .pipe(
        filter((eidForm) => {
          return eidForm.reasonNoCardReading?.value === ReasonNoCardReadingType.READ_EID;
        }),
        take(1)
      )
      .subscribe(() => {
        document.getElementById('formSubmit').click();
      });
  }

  private initFromProvidedInfo() {
    const pat = convertToPatient(this.detailInfo);
    this.mainPatient$$.next(pat);
    this.selectedPatient$$.next(pat);
    this.state$$.next('form');
    this.eidDetailsView = this.eidService.getEidDetailsView(this.detailInfo.personInfo);

    if (!this.selectedPatient.name || this.detailInfo?.personInfo.dataValidated === false) {
      this.searchForPatient(this.detailInfo?.personInfo, this.detailInfo?.personInfo?.isi);
    }
  }

  private listenForEhealthTRChanges() {
    const eHealthTrCheckboxChanges$ = this.form.get('eHealthTr').valueChanges;
    combineLatest([this.eidSource$$, this.selectedPatient$$, eHealthTrCheckboxChanges$]).subscribe(
      ([eidSrc, selectedPatient, tr]) => {
        if (!selectedPatient) {
          return;
        }
        const isDeniedAccess =
          (!selectedPatient.activeTherapeuticRelation && this.isAuthorizedForTR()) ||
          (!selectedPatient.activeAdministrativeRelation && !this.isAuthorizedForTR());
        const showEidForm = this.hasInss(selectedPatient) && tr && !eidSrc && isDeniedAccess;

        if (!showEidForm) {
          this.form.removeControl('eidForm');
        } else {
          this.form.addControl('eidForm', new UntypedFormControl({}));
        }
      }
    );

    this.selectedPatient$$.subscribe((pat) => {
      const eHealthTr = this.form.get('eHealthTr');
      if (!pat || !eHealthTr) {
        return;
      }
      if (pat.name && this.hasInss(pat)) {
        eHealthTr.setValue(!pat.activeTherapeuticRelation);
        eHealthTr.disable();
      } else {
        eHealthTr.enable();
      }
    });
  }
}

function convertToPatient(detailInfo: EidDetailedInfo) {
  const assignedIds: AssignedId[] = [];
  const personInfo = detailInfo.personInfo;

  if (personInfo.sub) {
    const id = {
      typeName: AssignedIdTypenameEnum.BE_INSS,
      source: AssignedIdSourceEnum.EID,
      value: personInfo.sub,
      sourceType: AssignedIdSourceTypeEnum.SUBJECT,
      startTime: null,
      endTime: FAR_AWAY_AS_STRING,
    } as AssignedId;
    if (detailInfo.source?.length > 0) {
      id.confirmSource = detailInfo.source;
    }
    if (detailInfo.queryDate) {
      id.confirmTime = detailInfo.queryDate.toISOString();
    }
    assignedIds.push(id);
  }

  const addresses = !personInfo.address
    ? null
    : [
        removeUndefinedValues({
          street: personInfo.address.street?.trim(),
          number: personInfo.address.number?.trim(),
          box: personInfo.address.box?.trim(),
          extraLines: personInfo.address.extraLines?.trim(),
          use: personInfo.address.use?.trim(),
          postalCode: personInfo.address.postalCode?.trim(),
          city: personInfo.address.city?.trim(),
          country: personInfo.address.country,
          source: 'EID',
        }),
      ];

  const deathDate = !detailInfo.personInfo.deceaseddate
    ? null
    : {
        value: detailInfo.personInfo.deceaseddate,
        precision: DateTimePrecision.DAY,
      };

  const patient = {
    name: personInfo.patientName,
    personName: {
      family: personInfo.name?.trim(),
      given: personInfo.givenName?.trim(),
      otherGivens: personInfo.middleName?.length ? [personInfo.middleName?.trim()] : null,
    },
    administrativeGender: personInfo.gender?.name,
    birthDate: {
      value: detailInfo.personInfo.birthdate,
      precision: DateTimePrecision.DAY,
    },
    deathDate: deathDate,
    addresses: addresses,
    assignedIds: assignedIds,
    nationalities: personInfo.nationality ? [personInfo.nationality] : null,
    blobPictureName: personInfo.picture,
    isValidated: true,
    isComplete: true,
  } as ExtendedPatient;

  patient.activeAdministrativeRelation = detailInfo.hasAdministrativeRelation;

  return patient;
}

function convertToTempPatient(patient: Patient): Patient {
  if (patient.name.length > 0) {
    patient.assignedIds = [
      {
        value: patient.name,
        typeName: AssignedIdTypenameEnum.LINKED_PATIENT_ID,
        source: AssignedIdSourceEnum.UNSPECIFIED,
        sourceType: AssignedIdSourceTypeEnum.SUBJECT,
        startTime: null,
        endTime: FAR_AWAY_AS_STRING,
      },
    ];
  }
  patient.name = '';
  patient.addresses = undefined;
  return patient;
}

function isTempPatient(pat: Patient): boolean {
  if (!pat.assignedIds) {
    return false;
  }
  return pat.assignedIds?.some((id) => id.typeName === AssignedIdTypenameEnum.LINKED_PATIENT_ID) ?? false;
}

function removeUndefinedValues<T extends Record<string | number | symbol, unknown>>(obj: T): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => typeof value !== 'undefined')) as Partial<T>;
}

function removeFalsyValues<T extends Record<string | number | symbol, unknown>>(obj: T): Partial<T> {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => !!value)) as Partial<T>;
}

interface EidForm {
  isi?: { value: string; disabled: boolean };
  eid?: { value: string; disabled: boolean };
  reasonNoCardReading?: { value: string; disabled: boolean };
}
