import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

/**
 * Simple animation for opening and closing a sidebar. The given width specifies
 * the width of the sidebar when opened
 *
 * @param width Specifies the width of the sidebar. Defaults to '200px'
 */
export const openClose = trigger('openClose', [
  state(
    'open',
    style({
      width: '{{width}}px',
    }),
    { params: { width: 200 } }
  ),
  state(
    'closed',
    style({
      width: '0px',
    })
  ),
  transition('closed => open', [animate('0.2s')]),
  transition('open => closed', [animate('0.1s')]),
]);

export const changeSize = trigger('changeSize', [
  state(
    'small',
    style({
      width: '{{small}}px',
    }),
    { params: { small: 200 } }
  ),
  state(
    'large',
    style({
      width: '{{large}}px',
    }),
    { params: { large: 200 } }
  ),
  transition('small => large', [animate('0.3s ease-in')]),
  transition('large => small', [animate('0.2s')]),
]);

export const insertRemove = trigger('insertRemove', [
  state('in', style({ transform: 'translateX(0)' })),
  transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
  transition(':leave', [animate(500, style({ opacity: 0 }))]),
]);

export const slideIn = trigger('slideIn', [
  transition(':enter', [style({ opacity: 0, height: 0 }), animate('0.2s', style({ opacity: 1, height: '*' }))]),
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0.5 }), animate('0.3s ease-in', style({ opacity: 1 }))]),
]);

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [style({ opacity: 0, transform: 'translateY(-100%)' }), animate('0.2s ease-in')]),
  transition(':leave', [animate('0.1s ease-out', style({ opacity: 0, transform: 'translateY(100%)' }))]),
]);

export const slideUpOut = trigger('slideInOut', [
  transition(':enter', [style({ opacity: 0, transform: 'translateY(100%)' }), animate('0.2s ease-in')]),
  transition(':leave', [animate('0.1s ease-out', style({ opacity: 0, transform: 'translateY(-100%)' }))]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    // each time the binding value changes
    query(':leave', [stagger(100, [animate('0.5s', style({ opacity: 0 }))])], { optional: true }),
    query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.5s', style({ opacity: 1 }))])], {
      optional: true,
    }),
  ]),
]);

export const uiToolkitAnimations = {
  changeSize,
  fadeIn,
  insertRemove,
  listAnimation,
  openClose,
  slideInOut,
  slideUpOut,
};
