export interface SortOptions<T = void> {
  sortBy?: T extends void ? string : Extract<keyof T, string>;
  sortByOrder?: SortOrder;
  ignoreCase?: boolean;
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export function sortOptionsToString<T>(sortOptions?: SortOptions<T> | null): string {
  if (!sortOptions || !sortOptions.sortBy || !sortOptions.sortByOrder) return '';
  return `${sortOptions.sortBy} ${sortOptions.sortByOrder} ${sortOptions.ignoreCase ? 'ci' : ''}`;
}
