import { NgModule } from '@angular/core';

import { I18NextModule } from 'angular-i18next';
import { I18nextArrayByKeyPipe } from './i18next-array-by-key.pipe';

@NgModule({
  imports: [I18NextModule],
  exports: [I18nextArrayByKeyPipe],
  declarations: [I18nextArrayByKeyPipe],
  providers: [I18nextArrayByKeyPipe],
})
export class SharedUtilI18nextArrayByKeyPipeModule {}
