import { Route } from '@angular/router';
import { ReferenceResolveService } from '@nexuzhealth/shared/reference-data/data-access';
import { PageNotFoundComponent } from '@nexuzhealth/shared/ui-toolkit/not-found';
import { AuthenticatedThemeResolver } from '@nexuzhealth/shared/toolkit/feature-theming';
import { FeatureFlagResolver } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { FatalErrorPageComponent } from '@nexuzhealth/shared/toolkit/feature-shell';
import { isAuthorizedGuard } from '@nexuzhealth/shared/authentication/feature-authorization';
import { AuthGuard } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole } from '@nexuzhealth/shared/domain';
import { AppShellComponent } from './app-shell/app-shell.component';

export const routes: Route[] = [
  {
    path: 'fatal-error',
    component: FatalErrorPageComponent,
  },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'patients',
        loadChildren: () =>
          import('@nexuzhealth/moapr-specialist/patient/shell').then((m) => m.MoaprSpecialistPatientShellModule),
        canActivate: [isAuthorizedGuard({ or: [AccessRole.clerk, AccessRole.clinical] })],
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('@nexuzhealth/shared/communication/messages/feature-overview').then((m) => m.FeatureOverviewModule),
      },
      {
        path: 'resourceplanning',
        data: {
          breadcrumb: '_resourceplanning.navbar.title',
        },
        loadChildren: () =>
          import('@nexuzhealth/moapr-specialist/resource-planning/feature').then(
            (mod) => mod.MoaprSpecialistResourcePlanningFeatureModule
          ),
      },
      {
        path: 'settings',
        data: {
          breadcrumb: '_settings.navbar.title',
        },
        loadChildren: () =>
          import('@nexuzhealth/moapr-specialist/settings/shell').then((mod) => mod.MoaprSpecialistSettingsShellModule),
      },
      {
        path: '',
        redirectTo: 'patients',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: {
          breadcrumb: '_page-not-found.breadcrumb',
        },
      },
    ],
    resolve: {
      references: ReferenceResolveService,
      theme: AuthenticatedThemeResolver,
      featureFlags: FeatureFlagResolver,
    },
    data: {
      flagNames: [
        'featureflags/select-french',
        'featureflags/nxh-mail',
        'featureflags/ehealthbox-organisation',
        'featureflags/ehealthbox-send-via-organisation',
        'featureflags/care-rotation',
        'featureflags/health-elements-split-by-validation-state',
        'featureflags/mock-eid-supported',
      ],
    },
  },
];
