<h4 class="modal-title">
  <ng-container *ngIf="title">{{ title }}</ng-container>
  <ng-content></ng-content>
</h4>
<button
  nxh-button
  buttonType="subtle-button"
  buttonStatus="neutral"
  (click)="dismiss.next()"
  nxhDataTest="nxh-card-modal-header-close-button"
  tabindex="-1"
>
  <fa-icon icon="times" size="2x"></fa-icon>
</button>
