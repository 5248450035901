import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Address,
  AssignedId,
  AssignedIdSourceEnum,
  AssignedIdSourceTypeEnum,
  AssignedIdTypenameEnum,
  DateTime,
  DateTimePrecision,
  Patient,
} from '@nexuzhealth/shared/domain';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { AssignedIdService } from '@nexuzhealth/shared/util';
import { cloneDeep } from 'lodash-es';
import { PatientService } from '@nexuzhealth/shared/patient/data-access';
import { ToastrService } from 'ngx-toastr';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject } from 'rxjs';
import { EidSyncModalOptions } from './eid-sync-options';

@Component({
  selector: 'nxh-eid-sync-modal',
  templateUrl: './eid-sync-modal.component.html',
  styleUrls: ['./eid-sync-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EidSyncModalComponent implements OnInit {
  patient: Patient;
  eidData;
  readonly inssFormatType = AssignedIdTypenameEnum.BE_INSS;
  loading$ = new BehaviorSubject<boolean>(false);
  faSave = faSave;

  constructor(
    private activeModal: NgbActiveModal,
    private modalOptions: EidSyncModalOptions,
    private assignedIdService: AssignedIdService,
    private patientService: PatientService,
    private toastr: ToastrService,
    private i18next: I18NextPipe
  ) {}

  ngOnInit(): void {
    this.patient = this.modalOptions.patient;
    this.eidData = this.modalOptions.eidData;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  submit() {
    this.loading$.next(true);
    this.patientService.savePatient(this.mergeEidData(), 'EID_READING').subscribe({
      next: () => {
        this.toastr.success(
          this.i18next.transform('_patient.sync-modal-success-message'),
          this.i18next.transform('_patient.sync-modal-success-title')
        );
        this.activeModal.dismiss();
      },
      error: (err) => {
        if (
          err.kind === 'pm_patient_identifier-already-in-use' ||
          err.kind === 'common_person_identifier-already-in-use'
        ) {
          this.toastr.error(
            this.i18next.transform('_patient.sync-modal-in-use-message'),
            this.i18next.transform('_patient.sync-modal-in-use-title')
          );
        } else if (err.kind === 'common_person_inss-change-with-user') {
          this.toastr.error(
            this.i18next.transform('_personal._identifier-inss-change-with-user.message'),
            this.i18next.transform('_personal._identifier-inss-change-with-user.title')
          );
        } else {
          this.toastr.error(
            this.i18next.transform('_patient.sync-modal-error-message'),
            this.i18next.transform('_patient.sync-modal-error-title')
          );
        }
        this.loading$.next(false);
      },
    });
  }

  convertDate(eidDate: string) {
    const d = eidDate.split('-');

    if (d[2] === '00') {
      d[2] = '01';
    }

    if (d[1] === '00') {
      d[1] = '01';
    }

    return d.join('-');
  }

  private mergeEidData(): Patient {
    const p = cloneDeep(this.patient);

    if (p.personName) {
      p.personName.given = this.eidData.givenName;
      p.personName.family = this.eidData.name;
    }

    const inss = this.assignedIdService.getInss(p.assignedIds);

    if (inss) {
      p.assignedIds = this.assignedIdService.upsertInss(
        this.eidData.sub,
        p.assignedIds.sort((a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime())
      );
    } else {
      p.assignedIds.push({
        value: this.eidData.sub,
        typeName: AssignedIdTypenameEnum.BE_INSS,
        confirmSource: AssignedIdSourceEnum.EID,
        startTime: new Date().toISOString(),
        source: AssignedIdSourceEnum.EID,
        sourceType: AssignedIdSourceTypeEnum.SUBJECT,
      } as AssignedId);
    }

    p.administrativeGender = this.eidData?.gender?.name;

    if (p.birthDate) {
      p.birthDate.value = this.eidData.birthdate;
    } else {
      p.birthDate = {
        value: this.eidData.birthdate,
        precision: DateTimePrecision.DAY,
      } as DateTime;
    }

    if (this.eidData.nationality) {
      if (p.nationalities.length > 0) {
        p.nationalities[0] = this.eidData.nationality;
      } else {
        p.nationalities.push(this.eidData.nationality);
      }
    }

    if (p.addresses.length > 0 && p.addresses.find(({ use }) => use === 'Use_OFFICIAL')) {
      const address = p.addresses.find(({ use }) => use === 'Use_OFFICIAL');
      address.street = this.eidData.address.street;
      address.number = this.eidData.address.number;
      address.box = this.eidData.address.box;
      address.city = this.eidData.address.city;
      address.postalCode = this.eidData.address.postalCode;
      address.country = this.eidData.address.country;
    } else {
      p.addresses.push({
        street: this.eidData.address.street,
        number: this.eidData.address.number,
        box: this.eidData.address.box,
        city: this.eidData.address.city,
        postalCode: this.eidData.address.postalCode,
        country: this.eidData.address.country,
        use: 'Use_OFFICIAL',
      } as Address);
    }

    return p;
  }
}
