<nxh-not-found
  [title]="'_page-not-found.title' | i18next"
  [description]="'_page-not-found.description' | i18next"
  [reasons]="['_page-not-found.reason-1' | i18next, '_page-not-found.reason-2' | i18next]"
  [icon]="telescope"
>
  <div class="not-found__actions">
    <span>{{ '_not-found.go-back-to' | i18next }} </span>
    <button nxh-button buttonType="link-small" (click)="goBack()">
      <span class="first-letter-lowercase">{{ 'previous' | i18next }}</span>
    </button>
    <span> {{ 'or' | i18next }} </span>
    <a [routerLink]="'/home'">{{ '_page-not-found.cta-home-page' | i18next }}</a>
  </div>
</nxh-not-found>
