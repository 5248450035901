import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EnumCategory } from '../shared/enum-category';

const dnrConsent = [
  { name: 'dnr0', description: 'dnr0' },
  { name: 'dnr1', description: 'dnr1' },
  { name: 'dnr2', description: 'dnr2' },
  { name: 'dnr3', description: 'dnr3' },
];

const dnhConsent = [
  { name: 'dnh0', description: 'dnh0' },
  { name: 'dnh1', description: 'dnh1' },
  { name: 'dnh2', description: 'dnh2' },
  { name: 'dnh3', description: 'dnh3' },
];

const defaultConsent = [
  { name: 'YES', description: 'yes' },
  { name: 'NO', description: 'no' },
];

const confidentialities = [
  { name: 'NO_CONFIDENTIALITY', description: 'no confidentiality', translationKey: '_enum.no_confidentiality' },
  { name: 'THIRD_PARTY', description: 'third party', translationKey: '_enum.third_party' },
  { name: 'THERAPEUTIC_RELATION', description: 'therapeutic relation', translationKey: '_enum.therapeutic_relation' },
  { name: 'CARE_TEAM', description: 'care team', translationKey: '_enum.care_team' },
  { name: 'GROUP_PRACTICE', description: 'group practice', translationKey: '_enum.group_practice' },
  { name: 'DMG', description: 'DMG', translationKey: '_enum.dmg' },
  { name: 'AUTHOR', description: 'Author', translationKey: '_enum.author' },
];

// defined in: https://www.ehealth.fgov.be/standards/kmehr/en/tables/certainty
const certainties = [
  { name: 'EXCLUDED', description: 'excluded', translationKey: '_enum.excluded' },
  { name: 'PROBABLE', description: 'probable', translationKey: '_enum.probable' },
  { name: 'PROVEN', description: 'proven', translationKey: '_enum.proven' },
  { name: 'UNPROBABLE', description: 'unprobable', translationKey: '_enum.unprobable' },
];

const familyRelations = [
  { name: 'father', description: 'father' },
  { name: 'mother', description: 'mother' },
  { name: 'sister', description: 'sister' },
  { name: 'brother', description: 'brother' },
  { name: 'son', description: 'son' },
  { name: 'daughter', description: 'daughter' },
  { name: 'aunt', description: 'aunt' },
  { name: 'uncle', description: 'uncle' },
  { name: 'nephew', description: 'nephew' },
  { name: 'niece', description: 'niece' },
  { name: 'grandfather', description: 'grandfather' },
  { name: 'grandmother', description: 'grandmother' },
  { name: 'other', description: 'other' },
];

// defined in: https://www.ehealth.fgov.be/standards/kmehr/en/tables/severity
const severities = [
  { name: 'HIGH', description: 'high', translationKey: '_enum.high', groupKey: '1' },
  { name: 'LOW', description: 'low', translationKey: '_enum.low', groupKey: '1' },
  { name: 'VERY_ABNORMAL', description: 'very abnormal', translationKey: '_enum.very_abnormal', groupKey: '2' },
  { name: 'ABNORMAL', description: 'abnormal', translationKey: '_enum.abnormal', groupKey: '2' },
  { name: 'NORMAL', description: 'normal', translationKey: '_enum.normal', groupKey: '2' },
  { name: 'EXTREMELY_HIGH', description: 'extremely high', translationKey: '_enum.extremely_high', groupKey: '3' },
  { name: 'VERY_HIGH', description: 'very high', translationKey: '_enum.very_high', groupKey: '3' },
  { name: 'VERY_LOW', description: 'very low', translationKey: '_enum.very_low', groupKey: '3' },
  { name: 'EXTREMELY_LOW', description: 'extremely low', translationKey: '_enum.extremely_low', groupKey: '3' },
  {
    name: 'VERY_SUSCEPTIBLE',
    description: 'very susceptible',
    translationKey: '_enum.very_susceptible',
    groupKey: '4',
  },
  { name: 'SUSCEPTIBLE', description: 'susceptible', translationKey: '_enum.susceptible', groupKey: '4' },
  {
    name: 'SUSCEPTIBLE_INTERMEDIATE',
    description: 'susceptible intermediate',
    translationKey: '_enum.susceptible_intermediate',
    groupKey: '4',
  },
  {
    name: 'SUSCEPTIBLE_MODERATE',
    description: 'susceptible moderate',
    translationKey: '_enum.susceptible_moderate',
    groupKey: '4',
  },
  { name: 'RESISTENT', description: 'resistent', translationKey: '_enum.resistent', groupKey: '4' },
];

const countries = [
  { name: 'B', description: 'Belgium' },
  { name: 'F', description: 'France' },
];

const genderOptions = [
  { name: 'enums/common-person-gender/values/male', description: 'male' },
  { name: 'enums/common-person-gender/values/female', description: 'female' },
  { name: 'enums/common-person-gender/values/other', description: 'other' },
];

export type EnumCategoryState = EntityState<EnumCategory, string>;

const initialState = {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'enum-category', idKey: 'name' })
export class EnumCategoryStore extends EntityStore<EnumCategoryState> {
  constructor() {
    super(initialState);

    // todo fetch this from backend or real enums
    this.add([
      { name: 'riskfactors:severity', values: severities },
      { name: 'riskfactors:certainty', values: certainties },
      { name: 'riskfactors:family-relation', values: familyRelations },
      { name: 'riskfactors:confidentiality', values: confidentialities },
      // todo can be removed? used in unused component
      { name: 'country', values: countries },
      { name: 'dnr-consent', values: dnrConsent },
      { name: 'dnh-consent', values: dnhConsent },
      { name: 'def-consent', values: defaultConsent },
      { name: 'enums/common-person-gender', values: genderOptions },
    ]);
  }
}
