import { Component, Injector } from '@angular/core';
import { EID_DETAILED_INFO } from '@nexuzhealth/shared/eid/data-access';
import { Patient } from '@nexuzhealth/shared/domain';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Router } from '@angular/router';
import { EidModalUseValueEid } from '../../eid-modal-v2/eid-modal.component';
import { EidModalService } from '../../shared/eid-modal.service';

@Component({
  selector: 'nxh-nav-item-eid',
  templateUrl: './nav-item-eid.component.html',
  styleUrls: ['./nav-item-eid.component.scss'],
})
export class NavItemEidComponent {
  private readonly successRoute = this.settings.getComponentSettingValue(
    'components.eid-modal-component.routeOnSuccess',
    ['/patients', null, 'administrative-dossier']
  );

  constructor(private router: Router, private settings: SettingsService, private eidModalService: EidModalService) {}

  openEid() {
    const injector = Injector.create({
      providers: [
        {
          provide: EID_DETAILED_INFO,
          useValue: EidModalUseValueEid,
        },
      ],
    });

    const modalRef = this.eidModalService.openEidModal(injector);
    modalRef.closed.subscribe((patient: Patient | string) => {
      if (patient) {
        const route = [...this.successRoute];
        if (typeof patient === 'string') {
          route[1] = patient;
        } else {
          route[1] = patient.name;
        }

        this.router.navigate(route);
      }
    });
  }
}
