import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Theme } from './theme';

@StoreConfig({ name: 'theme' })
@Injectable({ providedIn: 'root' })
export class ThemeStore extends Store<Theme & { isDefault: boolean }> {
  constructor() {
    super({
      mainColor: '#167fac',
      logo: '/assets/theming-default/nxh-pro-logo.svg',
      backgroundImage: '/assets/theming-default/nxh-bg.jpg',
      supportEmail: 'support.pro@nexuzhealth.com',
      supportPhone: '+3216348399',
      shortCode: 'nxh',
      isDefault: true,
    });
  }
}
