import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedUtilI18nModule} from '@nexuzhealth/shared/util';

import {ButtonModule} from '@nexuzhealth/shared/ui-toolkit/button';
import {CancelButtonComponent} from './cancel-button.component';

/**
 * @deprecated Use nh-cancel-button instead: https://uitoolkit.dev.nexuzhealth.pro/validation
 */
@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, ButtonModule],
  declarations: [CancelButtonComponent],
  exports: [CancelButtonComponent],
})
export class SharedUiToolkitCancelButtonModule {}
