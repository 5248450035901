import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@nexuzhealth/shared/ui-toolkit/icons';
import { SharedUtilI18nModule } from '@nexuzhealth/shared/util';
import { CustomDatePipeModule } from '@nexuzhealth/shared/ui-toolkit/l10n/custom-date';
import { CertificateBarComponent } from './certificate-bar/certificate-bar.component';

@NgModule({
  declarations: [CertificateBarComponent],
  imports: [CommonModule, RouterModule, SharedUtilI18nModule, IconsModule, CustomDatePipeModule],
  exports: [CertificateBarComponent],
})
export class SharedEhealthFeatureCertificateBarModule {}
