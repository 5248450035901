import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CompactFormControlDirective } from './compact-form-control.directive';

/**
 * @deprecated Use nh-control implementation
 */
@NgModule({
  imports: [CommonModule],
  exports: [CompactFormControlDirective],
  declarations: [CompactFormControlDirective],
})
export class CompactFormControlDirectiveModule {}
