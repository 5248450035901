import { Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { TimeoutListener } from './timeout-utils';

@Injectable({ providedIn: 'root' })
export class TimeoutListenerService implements TimeoutListener {
  private warning$ = new Subject<string | void>();
  private error$ = new Subject<string | void>();

  constructor(private toast: ToastrService, private i18next: I18NextPipe) {
    this.warning$.pipe(throttleTime(300)).subscribe((logInfo) => {
      console.warn('timout warning', logInfo);
      const warningTitle = this.i18next.transform('_loading-states._warning-timeout.title');
      const warningDescription = this.i18next.transform('_loading-states._warning-timeout.description');
      this.toast.warning(warningTitle, warningDescription);
    });

    this.error$.pipe(throttleTime(300)).subscribe((logInfo) => {
      console.warn('timout error', logInfo);
      const errorTitle = this.i18next.transform('_loading-states._error-timeout.title');
      const errorDescription = this.i18next.transform('_loading-states._error-timeout.description');
      this.toast.error(errorTitle, errorDescription);
    });
  }

  error(logInfo?: string): void {
    this.error$.next(logInfo);
  }

  warning(logInfo?: string): void {
    this.warning$.next(logInfo);
  }
}
