import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MASKS } from '@nexuzhealth/shared/util';

@Component({
  selector: 'nxh-blur',
  templateUrl: 'blur.component.html',
  styleUrls: ['blur.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurComponent {
  @Input() pattern?: keyof typeof MASKS | string;
  @Input() size: keyof typeof sizes = 'md';
  @Input() useProjection = false;

  get actual() {
    return this.pattern ? MASKS[this.pattern] || this.pattern : secret.substring(0, sizes[this.size]);
  }
}

const secret = 'He has been dead the entire movie and is one of the ghosts young Cole sees.';
const sizes = { xs: 5, sm: 10, md: 20, lg: 50, xl: 100 };
