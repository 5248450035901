/*
 this function accepts:
  A. a shade between and including -1 to 1. negative values will darken the color in a lineair fashion. positive values will lighten the color in a lineair fashion
  B. a hex in the form of #XXXXXX. it is important that the hex follows that pattern.
 */
export function manipulateHex(shade, hex): string {
  //thanks internet
  const t = shade < 0 ? 0 : 255 * shade;
  const P = shade < 0 ? 1 + shade : 1 - shade;

  return (
    '#' +
    [
      parseInt(hex.substring(1, 3), 16).toString(10),
      parseInt(hex.substring(3, 5), 16).toString(10),
      parseInt(hex.substring(5), 16).toString(10),
    ]
      .map((item) =>
        Math.round(parseInt(item, 10) * P + t)
          .toString(16)
          .padStart(2, '0')
      )
      .join('')
  );
}

/*
 this function accepts a hex in the form of #XXXXXX. it is important that the hex follows that pattern.
 */
export function hexToRGB(hex): string {
  return [
    parseInt(hex.substring(1, 3), 16).toString(10),
    parseInt(hex.substring(3, 5), 16).toString(10),
    parseInt(hex.substring(5), 16).toString(10),
  ].join(',');
}
