import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {TypeaheadOption} from './typeahead-option.model';

// the service is provided in the typeahead directive
@Injectable()
export class TypeaheadService<T> {
  private state = new BehaviorSubject({
    options: [] as TypeaheadOption<T>[],
    term: '',
    active: -1,
    completed: false,
    size: 0,
  });

  options$ = this.state.asObservable().pipe(map(({ options }) => options));
  completed$ = this.state.asObservable().pipe(map(({ completed }) => completed));
  term$ = this.state.asObservable().pipe(map(({ term }) => term));
  active$ = this.state.asObservable().pipe(map(({ active }) => active));

  selected$: Subject<TypeaheadOption<T>> = new Subject<TypeaheadOption<T>>();
  allResults$ = new BehaviorSubject(false);
  advancedSearching$ = new BehaviorSubject(false);

  constructor() {}

  updateState(state: Partial<TypeaheadInState>): any {
    this.state.next({ ...this.state.getValue(), ...state });
  }

  selectOption(option: TypeaheadOption<T>) {
    this.selected$.next(option);
  }

  allResultsOptions() {
    this.allResults$.next(true);
  }

  advancedSearchingOptions() {
    this.advancedSearching$.next(true);
  }

  getTerm() {
    return this.state.getValue().term;
  }

  getActive() {
    return this.state.getValue().active;
  }

  arrowUp() {
    const active = this.state.getValue().active;
    const last = this.state.getValue().options.length - 1;
    if (active >= 1) {
      this.updateState({ active: active - 1 });
    } else {
      this.updateState({ active: last });
    }
  }

  arrowDown() {
    const active = this.state.getValue().active;
    const last = this.state.getValue().options.length - 1;
    if (active < last) {
      this.updateState({ active: active + 1 });
    } else {
      this.updateState({ active: 0 });
    }
  }

  getSelectedOption(): TypeaheadOption<T> {
    const options = this.state.getValue().options;
    const active = this.state.getValue().active;
    return options[active];
  }
}

interface TypeaheadInState {
  options: any[];
  term: string;
  active: number;
  completed: boolean;
  selected: any;
}
