import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollMode, UiCardService } from '../../service/ui-card.service';
import { CardHeaderComponent } from '../card-header/card-header.component';
import { CardBodyComponent } from '../card-body/card-body.component';

@Component({
  selector: 'nxh-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [UiCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit, OnDestroy, AfterContentInit {
  @HostBinding('class')
  _hostClasses = 'card nxh-card-component';
  @HostBinding('class.nxh-card-compact')
  _compact!: boolean;
  @HostBinding('class.nxh-card-scrollable--body')
  _scrollableBody!: boolean;
  @HostBinding('class.nxh-card-scrollable--custom')
  _scrollableCustom!: boolean;

  /**
   * @description
   * When set to true, you get less padding in the header, body and footer.
   */
  @Input()
  set compact(compact: boolean) {
    this.uiCardService.setCompact(compact);
  }

  /**
   * @description
   * Used to make the card scrollable. Typically you set it to 'body' to make the card body scrollable. Should you want to
   * only make a part of the card scrollable, you set it to 'custom'.
   *
   * Note: if you wish to make a modal scrollable, you need to set nxh-card's scrollMode to 'body' and you have to open the
   * modal with scrollable: true, e.g. this.modalService.open({... , scrollable: true})
   */
  @Input()
  set scrollMode(scrollMode: ScrollMode) {
    this.uiCardService.setScrollMode(scrollMode);
  }

  /**
   * @description
   * When set to true, the header receives a chevron to collapse the body and footer.
   */
  @Input() set collapsible(collapsible: boolean) {
    this.uiCardService.setCollapsible(collapsible);
  }
  /**
   * @description
   * This acts as a default in case collapsible is true. also used to change the collapsible state at runtime, instead of using the chevron in the nxh-card-header.
   */
  @Input() set collapsed(collapsed: boolean) {
    this.uiCardService.setCollapsed(collapsed);
  }

  @ContentChild(CardHeaderComponent) private cardHeaderComponent: CardHeaderComponent;
  @ContentChild(CardBodyComponent) private cardBodyComponent: CardBodyComponent;

  protected uiCardService = inject(UiCardService);

  private _destroySubject = new Subject<void>();
  private _cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    // note: HostBinding doesn't work with Observables - https://github.com/angular/angular/issues/19483
    this.uiCardService.compact$.pipe(takeUntil(this._destroySubject)).subscribe((value) => {
      this._compact = value;
      this._cdr.markForCheck();
    });
    this.uiCardService.scrollMode$.pipe(takeUntil(this._destroySubject)).subscribe((value) => {
      this._scrollableBody = value === 'body';
      this._scrollableCustom = value === 'custom';
      this._cdr.markForCheck();
    });
  }

  ngAfterContentInit() {
    if (this.cardBodyComponent?.dark && this.cardHeaderComponent) {
      this.cardHeaderComponent.bottomBorder = true;
      this.uiCardService.updateHeader(this.cardHeaderComponent);
    }
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }
}
