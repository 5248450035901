import { I18NextModule, ITranslationService } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { cloneDeep } from 'lodash-es';
import { I18NextConfig } from './config';

const local_storage_lang_key = 'nh.locize.i18nextlang';

export function appInit(
  i18next: ITranslationService,
  customConfig: I18NextConfig,
  preferredLanguage?: string,
  namespacesConfig?: NamespacesConfig
): Promise<any> {
  if (!namespacesConfig) {
    namespacesConfig = {
      ns: ['common', 'backend'],
      defaultNS: 'common',
      fallbackNS: 'common',
    };
  }

  // @see https://github.com/locize/i18next-locize-backend/blob/master/src/index.js
  // (for now?) we don't specify referenceLng or fallbackLng, as this might result in
  // loading all translation bundles twice
  const defaultConfig = {
    whitelist: ['en', 'nl', 'de', 'fr'],
    debug: false,
    returnEmptyString: false,
    fallbackLng: 'nl',
    interpolation: {
      format: I18NextModule.interpolationFormat((value, format) => {
        if (format === 'lowercaseFirstChar') {
          return lowercaseFirstChar(value);
        }
        return value;
      }),
    },
    ...namespacesConfig,
  };

  // store preferred language (as loaded from UserPreferences)...
  if (preferredLanguage) {
    localStorage.setItem(local_storage_lang_key, preferredLanguage);
  }

  // ... and configure LanguageDetector to primarily fetch the language from localstorage
  const detectionConfig = {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: local_storage_lang_key,
  };

  const config = { ...defaultConfig, ...customConfig, detection: detectionConfig };

  return i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init(cloneDeep(config)) // deep clone, otherwise i18next will dirten the settings
    .then((evt) => {
      if (evt && evt.err) {
        // this doesn't work??
        console.error('Error while loading i18next', evt.err);
      }
    });
}

export interface NamespacesConfig {
  ns: string[];
  defaultNS?: string;
  fallbackNS?: string;
}

function lowercaseFirstChar(value: string) {
  // don't lowercase first char of values like OCMW, RIZIV
  const isAbbrev = /^[A-Z][A-Z]/.test(value);
  if (isAbbrev) {
    return value;
  }
  return value ? value[0]?.toLowerCase() + value.substring(1) : '';
}
