import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

export function disabledChanges(control: AbstractControl): Observable<boolean> {
  return new Observable((observer) => {
    if (control.disabled) {
      observer.next(true);
    }

    const originalDisable = control.disable;
    control.disable = function () {
      observer.next(true);
      // eslint-disable-next-line
      originalDisable.apply(this, arguments);
    };

    const originalEnable = control.enable;
    control.enable = function () {
      observer.next(false);
      // eslint-disable-next-line
      originalEnable.apply(this, arguments);
    };
  });
}
