<div class="empty-container">
  <ng-container *ngIf="isFilterOn$ | async; else noFilter">
    <fa-icon class="empty-icon" icon="folder-magnifying-glass" [fixedWidth]="true" [size]="'2x'" />
    <div class="no-items-found-label">
      {{ noItemsFoundLabel ? noItemsFoundLabel : ('_loading-states.no-items-found' | i18next) }}
    </div>
    <div class="change-filter">{{ '_loading-states.change-filter' | i18next }}</div>
  </ng-container>
  <ng-template #noFilter>
    <fa-icon class="empty-icon" icon="folder-open" [fixedWidth]="true" [size]="'2x'" />
    <div class="no-items-label">
      {{ label ? label : ('_loading-states.no-items' | i18next) }}
    </div>
    <button
      nxh-button
      buttonType="link"
      (click)="createItem.emit()"
      nxhDataTest="create-item-button"
      *ngIf="createItemLabel"
    >
      <fa-icon icon="plus" />
      {{ createItemLabel }}
    </button>
  </ng-template>
</div>
