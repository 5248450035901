import { AfterViewInit, Component } from '@angular/core';
import { faRedo } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';
// TODO Import is not correct --> could be solved with a injection token in utils lib and use settings service class to the token
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { AuthQuery, AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'nxh-fatal-error-page',
  templateUrl: './fatal-error-page.component.html',
  styleUrls: ['./fatal-error-page.component.scss'],
})
export class FatalErrorPageComponent implements AfterViewInit {
  redo = faRedo;
  productSelectorUrl: string;
  errorType$: Observable<string>;

  constructor(
    private router: Router,
    public auth: AuthService,
    private authQuery: AuthQuery,
    private settingsService: SettingsService
  ) {
    this.productSelectorUrl = `${settingsService.loginEndpoint}/product-selector`;
    this.errorType$ = router.routerState.root.queryParams.pipe(map((p) => p['fatal-error']));
  }

  ngAfterViewInit(): void {
    const userContextName = this.authQuery.getUserContextName();
    if (userContextName == null) {
      this.auth.logout(true);
    }
  }

  logout() {
    this.auth.logout(false);
  }

  reload() {
    const target = history.state.target;
    window.location.href = target || '/';
  }
}
