import { EMPTY, forkJoin, throwError, zip } from 'rxjs';
import { applyTransaction, withTransaction } from '@datorama/akita';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CertificateQuery } from '../state/certificate.query';
import { CertificateStore } from '../state/certificate.store';
import { CertificateHttpService } from '../api/certificate.http.service';

@Injectable({ providedIn: 'root' })
export class CertificateService {
  constructor(
    private httpService: CertificateHttpService,
    private store: CertificateStore,
    private query: CertificateQuery
  ) {}

  loadMyCurrentCertificates() {
    forkJoin([this.query.selectLoading().pipe(take(1)), this.query.selectHasCache().pipe(take(1))])
      .pipe(
        switchMap(([isLoading, hasCache]) => {
          if (isLoading || hasCache) {
            // Another call is already loading the certificates, or it is already loaded
            return EMPTY;
          }
          this.store.setLoading(true);
          return zip(
            this.httpService.getCurrentOrganisationCertificate(),
            this.httpService.getCurrentUserCertificate()
          ).pipe(
            withTransaction(([organisationCertificate, userCertificate]) => {
              this.store.setLoading(false);
              this.store.setHasCache(true); // Manually, because we don't use an EntityStore
              this.store.update({ organisation: organisationCertificate, user: userCertificate });
            }),
            catchError((err) => {
              applyTransaction(() => {
                this.store.setError(err);
                this.store.setLoading(false);
              });
              return throwError(err);
            })
          );
        })
      )
      .subscribe();
  }
}
