import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FilterOnService {
  private filterOn$$ = new BehaviorSubject(false);
  readonly filterOn$ = this.filterOn$$.asObservable();

  setFilterOn(filterOn: boolean) {
    this.filterOn$$.next(filterOn);
  }
}
