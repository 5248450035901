<div class="title-part">
  <ng-content select="nxh-card-title"></ng-content>
</div>
<div class="controls-part">
  <ng-content select="nxh-card-controls"></ng-content>
  <button
    class="btn nxh-card-modal-header-close-button"
    tabindex="-1"
    type="button"
    (click)="clickedClose.next()"
    *ngIf="closeable"
    nxhDataTest="nxh-card-modal-header-close-button"
  >
    <fa-icon icon="times" size="2x"></fa-icon>
  </button>
</div>
