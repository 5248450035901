import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { addDataTestAttributes } from '../util/e2e-utils';

@Directive({
  selector: '[nxhDataTest]',
})
export class DataTestDirective implements OnInit {
  @Input() nxhDataTest!: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    addDataTestAttributes(this.nxhDataTest, { element: this.element.nativeElement });
  }
}
