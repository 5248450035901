import { Component, OnInit } from '@angular/core';
import { AuthQuery, AuthService, LinkService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { CertificateQuery, CertificateService } from '@nexuzhealth/shared/ehealth/data-access';
import {
  calculateCertificateStatus,
  Certificate,
  CertificateBannerVm,
  CertificateStatus,
} from '@nexuzhealth/shared/ehealth/domain';
import { add, isAfter } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessRole } from '@nexuzhealth/shared/domain';

@Component({
  selector: 'nxh-certificate-bar',
  templateUrl: './certificate-bar.component.html',
  styleUrls: ['./certificate-bar.component.scss'],
})
export class CertificateBarComponent implements OnInit {
  userId: string;
  remindMeSettings: Record<string, string>;
  readonly localStorageKey = 'nh.ehealth.remind-me-after';

  remindMe$ = new BehaviorSubject<CertificateBannerVm>(null);
  certificate$!: Observable<CertificateBannerVm[] | null>;

  constructor(
    private certificateService: CertificateService,
    private certificateQuery: CertificateQuery,
    private linkService: LinkService,
    private authQuery: AuthQuery,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.remindMeSettings = JSON.parse(localStorage.getItem(this.localStorageKey) || '{}');
    this.userId = this.authQuery.getUser().name;
    this.certificateService.loadMyCurrentCertificates();
    this.certificate$ = combineLatest([this.certificateQuery.myCurrentCertificates$, this.remindMe$]).pipe(
      map(([myCurrentCerts]) => {
        const certs = [myCurrentCerts?.organisation, myCurrentCerts?.user];
        return certs
          ?.filter((cert: Certificate) => (cert ? this.showReminder(cert?.name) : false))
          .map((cert: Certificate) => this.convertCertificateToCertificateBanner(cert))
          .filter((cert: CertificateBannerVm) => !!cert);
      })
    );
  }

  remindMeLater(certificate: CertificateBannerVm): void {
    const later = add(new Date(), { days: 1 });
    this.remindMeSettings[certificate.certificate.name] = later.toISOString();
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.remindMeSettings));

    this.remindMe$.next(certificate);
  }

  private convertCertificateToCertificateBanner(certificate: Certificate): CertificateBannerVm | null {
    if (certificate) {
      const status: CertificateStatus = calculateCertificateStatus(certificate);
      if (status === 'active') {
        return null;
      }
      if (certificate.tenantName && !this.authService.isAuthorized(AccessRole.organisationManagement)) {
        return null;
      }
      return {
        certificate,
        status,
        certificateStatusMessage: this.getCertificateStatusMessage(status, !!certificate.tenantName),
        moreInfoLink: certificate.tenantName
          ? this.linkService.organizationPortalLink('/organisation/certificate')
          : this.linkService.userPortalLink('/configuration'),
      };
    }
    return null;
  }

  private getCertificateStatusMessage(
    certificateStatus: CertificateStatus,
    isCertificateOrganisationCertificate: boolean
  ): { title: string; body: string } {
    if (certificateStatus === 'almost-expired') {
      if (isCertificateOrganisationCertificate) {
        return {
          title: '_ehealth._certificate-organisation.almost-expired-title%date',
          body: '_ehealth._certificate-organisation.almost-expired-body',
        };
      } else {
        return {
          title: '_ehealth._certificate.almost-expired-title',
          body: '_ehealth._certificate.almost-expired-body',
        };
      }
    } else {
      if (isCertificateOrganisationCertificate) {
        return {
          title: '_ehealth._certificate-organisation.expired-title%date',
          body: '_ehealth._certificate-organisation.expired-body',
        };
      } else {
        return { title: '_ehealth._certificate.expired-title', body: '_ehealth._certificate.expired-body' };
      }
    }
  }

  private showReminder(certificateId: string): boolean {
    const remindMeAfter = this.remindMeSettings[certificateId];
    if (!remindMeAfter) {
      return true;
    }
    return isAfter(new Date(), new Date(remindMeAfter));
  }
}
