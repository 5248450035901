import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Certificate } from '@nexuzhealth/shared/ehealth/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CertificateHttpService {
  private readonly baseUrl = 'api/ehealth/certmgmt/v1';

  constructor(private http: HttpClient) {}

  getCurrentUserCertificate(): Observable<Certificate> {
    const url = `${this.baseUrl}/certificates:current`;
    return this.http.get<Record<'data', Certificate>>(url).pipe(map((response) => response.data));
  }

  getCurrentOrganisationCertificate(): Observable<Certificate> {
    const url = `${this.baseUrl}/certificates:currentForEntity`;
    return this.http.get<Record<'data', Certificate>>(url).pipe(map((response) => response.data));
  }

  create(userName: string, tenantName: string, blobName: string, password: string): Observable<Certificate> {
    const url = `${this.baseUrl}/certificates`;
    return this.http
      .post<Record<'data', Certificate>>(url, {
        userName,
        tenantName,
        metadataName: blobName,
        password,
      })
      .pipe(map((response) => response.data));
  }
}
