import { MediumReasonEnum } from './medium-reason.enum';
import { MediumTypeEnum } from './medium-type.enum';

export const ISI_MASK = '0 000 000 000';
export const EID_MASK = '000-0000000-00';
export const FOREIGNER_CARD_MASK = 'A 0000000 00 || A000000 00';

export interface Medium {
  type: MediumTypeEnum;
  reason: MediumReasonEnum;
  i18n: string;
  cardNumberRequired?: boolean;
  cardNumberValidatorMask?: string;
}

export const ELECTRONIC_ID_KIDS_ID: Medium = {
  type: MediumTypeEnum.EID,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'ELECTRONIC_ID_KIDS_ID',
  cardNumberRequired: true,
  cardNumberValidatorMask: EID_MASK,
};
export const ISI_PLUS: Medium = {
  type: MediumTypeEnum.ISIPlus,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'ISI_PLUS',
  cardNumberRequired: true,
  cardNumberValidatorMask: ISI_MASK,
};
export const VIGNETTE_PATIENT_UNAVAILABLE: Medium = {
  type: MediumTypeEnum.Vignette,
  reason: MediumReasonEnum.PatientNotAvailable,
  i18n: 'VIGNETTE_PATIENT_UNAVAILABLE',
};
export const VIGNETTE_NO_IDENTITY_DOCUMENT: Medium = {
  type: MediumTypeEnum.Vignette,
  reason: MediumReasonEnum.NoIdentityDocument,
  i18n: 'VIGNETTE_NO_IDENTITY_DOCUMENT',
};
export const SOCIAL_SECURITY_DOCUMENT: Medium = {
  type: MediumTypeEnum.SocialSecurityDocument,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'SOCIAL_SECURITY_DOCUMENT',
};
export const LOST_OR_STOLEN_DOCUMENT: Medium = {
  type: MediumTypeEnum.LostOrStolenDocument,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'LOST_OR_STOLEN_DOCUMENT',
};
export const FOREIGNERS_CARD: Medium = {
  type: MediumTypeEnum.ForeignersCard,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'FOREIGNERS_CARD',
  cardNumberRequired: true,
  cardNumberValidatorMask: FOREIGNER_CARD_MASK,
};
export const HAS_NO_EID: Medium = {
  type: MediumTypeEnum.HasNoEID,
  reason: MediumReasonEnum.ReasonUnknown,
  i18n: 'HAS_NO_EID',
};


