<nxh-default-errors></nxh-default-errors>

<nxh-navbar>
  <ng-container class="left">
    <nxh-nav-item-simple *ngFor="let navItem of navItems$ | async" [navItem]="navItem"></nxh-nav-item-simple>
  </ng-container>
  <ng-container class="right">
    <nxh-nav-item-eid *isAuthorized="{ or: [AccessRole.clinical, AccessRole.clerk] }"></nxh-nav-item-eid>
    <nxh-nav-item-patient-search></nxh-nav-item-patient-search>
    <nxh-nav-item-external-links *isAuthorized="AccessRole.clinical"></nxh-nav-item-external-links>
    <nxh-nav-item-support-bubble></nxh-nav-item-support-bubble>
    <nxh-nav-item-user [organizationPortalLinkVisible]="true" [userPortalLinkVisible]="true"></nxh-nav-item-user>
  </ng-container>
</nxh-navbar>

<div class="page-container">
  <router-outlet></router-outlet>
</div>
<nxh-certificate-bar></nxh-certificate-bar>
<nxh-cookie-bar appUrl="/specialist"></nxh-cookie-bar>
