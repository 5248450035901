import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthQuery } from '../state/auth-query.service';
import { AuthService } from './abstract-auth.service';
import { SessionService } from './abstract-session.service';

@Injectable()
export class KwsAuthService extends AuthService {
  readonly isAuthenticated$ = of(true);

  constructor(private sessionService: SessionService, protected authQuery: AuthQuery) {
    super(authQuery);

    this.sessionService.retrieveUserInformation().subscribe();
    this.sessionService.retrieveOrganisationInformation().subscribe();
  }

  getTokenSilently$(): Observable<string> {
    const firstContext = window.bridge.contexts()[0];
    return of(window.bridge.token(firstContext));
  }

  login(): void {
    throw new Error('Calling the login() function in kws-auth.service.ts should never happen.');
  }

  logout(): void {
    throw new Error('Calling the logout() function in kws-auth.service.ts should never happen.');
  }

  refreshToken(): void {
    throw new Error('Calling the refreshToken() function in kws-auth.service.ts should never happen.');
  }

  switchUserContext(): void {
    throw new Error('Calling the switchUserContext() function in kws-auth.service.ts should never happen.');
  }
}
