import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguageQuery, LanguageReference } from '@nexuzhealth/shared/reference-data/data-access';
import { I18NextPipe } from 'angular-i18next';
import { SelectReferenceDataBaseComponent } from '../shared/select-reference-data-base.component';

@Component({
  selector: 'nxh-select-language',
  templateUrl: '../shared/select-reference-data-base.component.html',
  styleUrls: ['../shared/select-reference-data-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLanguageComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguageComponent extends SelectReferenceDataBaseComponent<LanguageReference> {
  // todo: this should not be static
  private static filtered: any;

  @Input() clearable = true;
  @Input() languageType: SelectLanguageType = 'all';

  constructor(query: LanguageQuery, i18next: I18NextPipe) {
    super(query, i18next);
  }

  protected filter(items: LanguageReference[]): LanguageReference[] {
    if (this.languageType === 'all') {
      return items;
    }

    if (!SelectLanguageComponent.filtered) {
      const languageNames = filters[this.languageType];
      SelectLanguageComponent.filtered = items.filter((item) => languageNames.has(item.name));
    }

    return SelectLanguageComponent.filtered;
  }
}

type SelectLanguageType = 'document-lang' | 'all';

const DUTCH = '3d50c237-0add-43e7-92a2-5edf1ac7c6ee';
const FRENCH = '1238dde0-08df-4ae0-8676-59919f66737e';
const GERMAN = 'd0cb6c63-bfe0-4cb4-ad70-5cae73d81252';
const ENGLISH = '03c22f90-4a3c-493c-a6df-7ea7a80515bf';
const filters = { 'document-lang': new Set([DUTCH, FRENCH, GERMAN, ENGLISH]) };
