import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SERVICE_PORTS, ServicePort } from '@nexuzhealth/shared/authentication/data-access-auth';
import { NgIdleModule } from '@ng-idle/core';
import { CallbackComponent } from './components/callback/callback.component';
import { LoginComponent } from './components/login/login.component';
import { SingleLogoutComponent } from './components/logout/single-logout.component';

@NgModule({
  declarations: [CallbackComponent, LoginComponent, SingleLogoutComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forChild([
      {
        path: 'callback',
        pathMatch: 'full',
        component: CallbackComponent,
      },
      {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent,
      },
      {
        path: 'single-logout',
        pathMatch: 'full',
        component: SingleLogoutComponent,
      },
    ]),
    NgIdleModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class SharedAuthenticationModule {
  static forRoot(servicePorts?: ServicePort[]): ModuleWithProviders<SharedAuthenticationModule> {
    const providers: Provider[] = [];

    if (servicePorts) {
      providers.push({ provide: SERVICE_PORTS, useValue: servicePorts });
    }

    return {
      ngModule: SharedAuthenticationModule,
      providers,
    };
  }
}
