import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GmfPatient,
  GmfPatientList,
  GmfPatientListRequest,
  GmfPatientListRequests,
  GmfPayment,
  MutualityType,
  PagingResult,
  ResourceName,
} from '@nexuzhealth/shared/domain';
import { Envelope, getHttpParams, timeoutHeaders, unpack } from '@nexuzhealth/shared/util';
import { EMPTY, Observable } from 'rxjs';
import { expand, map, pluck, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GmfApiService {
  private readonly baseUrl = 'api/ehealth/gmf/v1';

  constructor(private httpClient: HttpClient) {}

  public registerAsGmf(registerAsGmfRequest: RegisterAsGmfRequest): Observable<RegisterStatus[]> {
    return this.httpClient
      .post<RegisterStatusResponse>(`${this.baseUrl}/register`, registerAsGmfRequest, {
        headers: timeoutHeaders({ warningMillis: 30000, errorMillis: 60000 }),
      })
      .pipe(pluck('registerStatus'));
  }

  public registerStatus(): Observable<RegisterStatus[]> {
    return this.httpClient.get<RegisterStatusResponse>(`${this.baseUrl}/register/status`).pipe(pluck('registerStatus'));
  }

  /**
   * Ehealth exposes a async service to get the patients a practitioner is GMF holder of.
   * This starts an requests to ehealth. To get it status, call getPatientRequests
   * @param date
   */
  public registerPatientListRequest(date: Date): Observable<GmfPatientListRequest> {
    const url = `${this.baseUrl}/requestmypatients`;
    return this.httpClient
      .post<Envelope<GmfPatientListRequest>>(url, {
        requestDate: date,
      })
      .pipe(unpack());
  }

  /**
   * Gets all the patients requests
   * @param username MOAA compliant user resource name
   */
  public getPatientListRequests(username: ResourceName): Observable<GmfPatientListRequests> {
    const url = `${this.baseUrl}/${username}/gmfstatusses`;
    return this.httpClient.get<GmfPatientListRequests>(url);
  }

  public refreshRequest(username: ResourceName): Observable<boolean> {
    const url = `${this.baseUrl}/${username}/gmfstatusses:update`;

    return this.httpClient
      .post<Envelope<boolean>>(
        url,
        {},
        {
          headers: timeoutHeaders({ warningMillis: 45000, errorMillis: 180000 }),
        }
      )
      .pipe(unpack('newData'));
  }

  private triggerGetPatientListExtensionUpdate(trigger): Observable<NewData> {
    const url = `${this.baseUrl}/gmfstatusses/extensions:update`;

    return this.httpClient.post<NewData>(
      url,
      {
        trigger: trigger,
      },
      {
        headers: timeoutHeaders({ warningMillis: 45000, errorMillis: 180000 }),
      }
    );
  }

  public getPatientListExtensionUpdate(trigger): Observable<NewData> {
    return this.triggerGetPatientListExtensionUpdate(trigger).pipe(
      expand((response) => {
        if (!response.newExtension && !response.newClosure) return EMPTY;

        return this.triggerGetPatientListExtensionUpdate(trigger);
      }),
      reduce((acc, data) => {
        return {
          newData: acc.newData || data.newData,
          newExtension: acc.newExtension || data.newExtension,
          newClosure: acc.newClosure || data.newClosure,
        };
      })
    );
  }

  public getPatientListExtensionSearch(pageSize, pageToken): Observable<PagingResult<GmfExtension>> {
    const url = `${this.baseUrl}/gmfstatusses/extensions:search`;

    const params = getHttpParams({
      pageSize,
      pageToken,
    });

    return this.httpClient.get<PagingResult<GmfExtension> & { nextPageToken: string }>(url, { params }).pipe(
      map((response) => ({
        data: response['data'],
        pageToken: response['nextPageToken'],
        totalSize: response['totalSize'],
        lastRequest: response['lastRequest'],
      }))
    );
  }

  public getPatientList(name: ResourceName): Observable<GmfPatientList> {
    const url = `${this.baseUrl}/${name}/patientdetails`;

    return this.httpClient.get<Envelope<GmfPatientList>>(url).pipe(pluck('data'));
  }
}

export interface RegisterStatus {
  mutuality: MutualityType;
  success: boolean;
  error: {
    code: number;
    message: string;
    kind: string;
  };
  date: string;
}

export interface NewData {
  newData: boolean;
  newExtension: boolean;
  newClosure: boolean;
}

export interface RegisterAsGmfRequest {
  bankAccounts: {
    mutuality: MutualityType;
    iban: string;
    bic: string;
  }[];
}

export interface RegisterStatusResponse {
  registerStatus: RegisterStatus[];
}

export interface GmfExtension {
  patient: Partial<GmfPatient>;
  startTime: Date;
  endTime: Date;
  mutualityDisplay: string;
  payment: GmfPayment[];
  mutualityRegNr: string;
  encounterDate: Date;
  claim: string;
  gmfHolder: GmfHolder;
  previousGmfHolder: GmfHolder;
  entryType: string;
}

export interface GmfHolder {
  nihii: string;
  inss: string;
  givenName: string;
  familyName: string;
}
