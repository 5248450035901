<!--ng-option--not-found for backwards compatibility, check _selects.scss for more details-->
<div class="ng-option ng-option--not-found">
  <div class="no-result-content">
    <ng-container *ngIf="error; else notFoundTemplate">
      <div class="error-container">
        <fa-icon [icon]="'times-circle'"></fa-icon>
        <span class="error-description">{{ errorText ? errorText : ('_ng-select.loading-error' | i18next) }}</span>
        <a class="error-cta" href="" (click)="onReload($event)">{{ 'try-again' | i18next }}</a>
      </div>
    </ng-container>
    <ng-template #notFoundTemplate>
      <span *ngIf="notFoundText; else defaultNotFoundTemplate">
        {{ '_loading-states.no-items-found' | i18next }}
      </span>
      <ng-template #defaultNotFoundTemplate>{{ '_ng-select.no-result' | i18next }}</ng-template>
    </ng-template>
  </div>
</div>
