import { Component } from '@angular/core';

/**
 * @deprecated Use nxh-sections instead. See {@link SectionsComponent}
 */
@Component({
  selector: 'nxh-card-body-sections',
  templateUrl: './card-body-sections.component.html',
  styleUrls: ['./card-body-sections.component.scss'],
})
export class CardBodySectionsComponent {
  constructor() {}
}
