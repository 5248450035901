import { Pipe, PipeTransform } from '@angular/core';
import { PersonName } from '@nexuzhealth/shared/domain';
import { formatPersonName } from '@nexuzhealth/shared/util';

@Pipe({
  name: 'formatPersonName',
})
export class FormatPersonNamePipe implements PipeTransform {
  transform(personName: PersonName | undefined | null) {
    return formatPersonName(personName);
  }
}
