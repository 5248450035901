import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IconLoaderComponent } from './icon-loader.component';

@NgModule({
  imports: [CommonModule],
  exports: [IconLoaderComponent],
  declarations: [IconLoaderComponent],
})
export class IconLoaderModule {}
