import { Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * @deprecated Use nh-cancel-button instead: https://uitoolkit.dev.nexuzhealth.pro/validation
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'deprecated-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss'],
})
export class CancelButtonComponent {
  /**
   * @ignore
   */
  @ViewChild('button', { static: true }) button: ElementRef<HTMLButtonElement>;

  /**
   * Disable button.
   */
  @Input() disabled: boolean;
  /**
   * I18n key to overwrite the default cancel label.
   */
  @Input() label: string | undefined = 'cancel';

  @Input() id = 'cancel-button';

  constructor() {}

  /**
   * @ignore
   */
  preventLayoutShift(event: MouseEvent) {
    // do not focus the button to prevent unwanted onblur validation of an active input
    // a layout shift might occur which could push the button away from the cursor
    event.preventDefault();
  }

  /**
   * @ignore
   */
  focus() {
    // form validation will have been executed by now and layout shifts are no longer a concern
    // focus the button to make it seem like normal click behaviour
    this.button.nativeElement.focus();
  }
}
