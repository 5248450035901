import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nxh-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertItemComponent {
  @Input() alertTitle?: string | null;
  @Input() description?: string;
  @Input() closeable = false;
  @Output() closeAlert = new EventEmitter<void>();
}
