import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AdministrativeGenderQuery,
  AdministrativeGenderReference,
} from '@nexuzhealth/shared/reference-data/data-access';
import { I18NextPipe } from 'angular-i18next';
import { flatten, partition } from 'lodash-es';
import { SelectReferenceDataBaseComponent } from '../shared/select-reference-data-base.component';

@Component({
  selector: 'nxh-select-administrative-gender',
  templateUrl: '../shared/select-reference-data-base.component.html',
  styleUrls: ['../shared/select-reference-data-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAdministrativeGenderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAdministrativeGenderComponent extends SelectReferenceDataBaseComponent<AdministrativeGenderReference> {
  @Input() clearable = true;

  constructor(query: AdministrativeGenderQuery, i18next: I18NextPipe) {
    super(query, i18next);
  }

  protected sort(items: AdministrativeGenderReference[]): AdministrativeGenderReference[] {
    items = items.map((item) => ({ ...item, label: this.i18next.transform(item.translationKey) }));
    return flatten(
      partition(super.sort(items), (item) => {
        return item.translationKey === '_gender.male' || item.translationKey === '_gender.female';
      })
    );
  }
}
