import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from '@nexuzhealth/shared/domain';
import { formatDateTime, isDateTime } from '@nexuzhealth/shared/util';

@Pipe({
  name: 'partialDate',
})
export class PartialDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: DateTime): string {
    if (!value || !isDateTime(value)) {
      return '';
    }

    return formatDateTime(value, { locale: this.locale, isExport: false });
  }
}
