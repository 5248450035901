<val-default-errors>
  <!--  common-->
  <ng-template valError="required" let-label let-error="error">
    {{ 'errors.required' | i18next : { label: label } }}
  </ng-template>
  <ng-template valError="no-whitespace" let-label let-error="error">
    {{ 'errors.required' | i18next : { label: label } }}
  </ng-template>
  <ng-template valError="no-empty-space" let-label let-error="error">
    {{ 'errors.required' | i18next : { label: label } }}
  </ng-template>
  <ng-template valError="min" let-label let-error="error">{{
    'errors.min%min' | i18next : { min: error.min }
  }}</ng-template>
  <ng-template valError="max" let-label let-error="error">{{
    'errors.max%max' | i18next : { max: error.max }
  }}</ng-template>
  <ng-template valError="pattern" let-label>
    {{ '_errors._invalid-pattern%label' | i18next : { label: label } }}</ng-template
  >
  <!--  date-->
  <ng-template valError="invalid-date">{{ 'errors.invalid-date' | i18next }}</ng-template>
  <ng-template valError="date-in-future" let-label>{{
    'errors-date-in-future%label' | i18next : { label: label }
  }}</ng-template>
  <ng-template valError="min-date">{{ 'errors.min-date' | i18next }}</ng-template>
  <ng-template valError="max-date">{{ 'errors.max-date' | i18next }}</ng-template>
  <ng-template valError="past-date">{{ '_errors.past-date' | i18next }}</ng-template>
  <ng-template valError="future-date">{{ '_errors.future-date' | i18next }}</ng-template>
  <ng-template valError="endDateIsBeforeStartDate">{{ '_errors.startdate-before-enddate' | i18next }}</ng-template>
  <ng-template valError="endTimeIsBeforeStartTime">{{ '_errors.starttime-before-endtime' | i18next }}</ng-template>
  <ng-template valError="endTimeIsSameAsStartTime">{{ '_errors.starttime-is-same-as-endtime' | i18next }}</ng-template>
  <ng-template valError="maxDifference" let-error="error"
    >{{
      '_errors.max-difference-between-start-and-enddate'
        | i18next : { max: error.max, unit: (error.unit | i18next : { count: error.max }) }
    }}
  </ng-template>
  <!--  number-->
  <ng-template valError="invalid-number">{{ 'errors-invalid-number' | i18next }}</ng-template>
  <ng-template valError="precision-error_max" let-error="error"
    >{{
      '_errors.precision-error_max'
        | i18next
          : { maxPrecision: error['maxPrecision'], decimal: ('decimal' | i18next : { count: error['maxPrecision'] }) }
    }}
  </ng-template>
  <ng-template valError="precision-error_round">{{ '_errors.precision-error_round' | i18next }}</ng-template>

  <!--  date-range-picker-->
  <ng-template valError="date-range-picker.from-required" let-label let-error="error">
    {{ 'errors.required' | i18next : { label: label || error.label | i18next } }}
  </ng-template>
  <ng-template valError="date-range-picker.required">
    {{ '_errors.start-and-end-date-required' | i18next }}
  </ng-template>

  <!--  other-->
  <ng-template valError="invalid-phone">{{ 'errors-invalid-phone' | i18next }}</ng-template>
  <ng-template valError="invalid-email">{{ 'errors-invalid-email' | i18next }}</ng-template>
  <!-- This one is for the default angular email validator (Validators.email) normally we don't use this one in favour of our own -->
  <ng-template valError="email">{{ 'errors-invalid-email' | i18next }}</ng-template>
  <ng-template valError="mask">{{ 'errors-invalid-mask' | i18next }}</ng-template>
  <ng-template valError="maxlength">{{ 'errors.maxlength' | i18next }}</ng-template>
  <ng-template valError="minlength" let-label let-error="error">{{
    'errors-minlength%minvalue' | i18next : { minvalue: error.requiredLength }
  }}</ng-template>
  <ng-template valError="invalidEmail">{{ 'errors-invalidEmail' | i18next }}</ng-template>
  <ng-template valError="invalid-email">{{ 'errors-invalid-email' | i18next }}</ng-template>
  <ng-template valError="invalid-bic">{{ 'errors-_gmf._registration.invalid-bic' | i18next }}</ng-template>
  <ng-template valError="invalid-iban">{{ 'errors-_gmf._registration.invalid-iban' | i18next }}</ng-template>
  <ng-template valError="invalidInss"> {{ 'errors-_personal.inss-invalid' | i18next }}</ng-template>
  <ng-template valError="invalidIsi"> {{ '_personal.isi-plus-invalid' | i18next }}</ng-template>
  <ng-template valError="input-group-child-error" let-label>{{
    '_errors.input-group-child-error' | i18nextCap : { label: label }
  }}</ng-template>
</val-default-errors>
