import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { Patient, PatientAddress, ResourceName } from '@nexuzhealth/shared/domain';
import { AssignedIdService } from '@nexuzhealth/shared/util';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PatientState, PatientStore } from './patient.store';

@Injectable({
  providedIn: 'root',
})
export class PatientQuery extends QueryEntity<PatientState, Patient, ResourceName> {
  constructor(protected store: PatientStore, private assignedIdService: AssignedIdService) {
    super(store);
  }

  inssChanged$ = this.selectActive().pipe(
    distinctUntilChanged((left, right) => {
      if (left?.name !== right?.name) {
        return false;
      }

      const leftInss = this.assignedIdService.getInss(left?.assignedIds ?? []);
      const rightInss = this.assignedIdService.getInss(right?.assignedIds ?? []);
      return leftInss?.value !== rightInss?.value;
    }),
    filterNilValue() // this should be after the distinctUntilChanged, otherwise you can't open the same patient twice
  );

  hasDetails(patientName: ResourceName = this.getActiveId()) {
    return this.hasEntity(patientName) && !!this.getEntity(patientName).addresses;
  }

  // @deprecated Use selectActive(). This one does not work for patients without addresses in the database
  selectDetails(): Observable<Patient> {
    return this.selectActive().pipe(
      filterNilValue(),
      filter(({ name }) => this.hasDetails(name))
    );
  }

  hasAddresses(patientName: ResourceName) {
    return this.hasEntity(patientName) && !!this.getEntity(patientName).addresses;
  }

  selectAddresses(patientName: ResourceName): Observable<PatientAddress[]> {
    return this.selectEntity(patientName).pipe(
      filterNilValue(),
      filter(({ name }) => this.hasAddresses(name)),
      map((p) => p.addresses)
    );
  }

  selectPicture(patientName: ResourceName): Observable<string> {
    return this.select('pictures').pipe(map((pictures) => pictures[patientName]));
  }

  selectEmergencySumehr(): Observable<string> {
    return this.select((patient) => patient.emergencySumehr);
  }

  getEmergencySumehr(): string {
    return this.getValue().emergencySumehr;
  }

  getPicture(patientName: ResourceName = this.getActiveId()): string {
    return this.getValue().pictures?.[patientName];
  }

  hasPicture(patientName: ResourceName = this.getActiveId()): boolean {
    return !!this.getPicture(patientName);
  }

  selectPatientDeceased(): Observable<boolean> {
    return this.selectActive().pipe(
      map((patient: Patient) => {
        return !!patient.deathDate;
      })
    );
  }
}
