import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SessionService} from '@nexuzhealth/shared/authentication/data-access-auth';
import {take} from 'rxjs';

/**
 * After login and tenant/role (userContext) selection,
 * the LoginPortal redirects the user to the app (GP/HN/UP/...) via this endpoint to set the chosen userContext and finalize the login.
 */
@Component({
  template: ``,
})
export class LoginComponent implements OnInit {
  constructor(private sessionService: SessionService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(take(1)).subscribe((params: ParamMap) => {
      const userContextName = params.get('userContextName');
      if (userContextName) {
        this.sessionService.clear();
        this.sessionService.useUserContextName(userContextName);
      }
      const redirectTo = this.sessionService.getAndClearRedirectAfterLogin();

      const urlTree = this.router.parseUrl(redirectTo);
      this.router.navigateByUrl(urlTree);
    });
  }
}
