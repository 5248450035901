import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReferenceApiService } from '../api/reference-api.service';
import {
  AdministrativeGenderReference,
  CityReference,
  MediumReference,
  CountryReference,
  HealthCareWorkerProfessionReference,
  LanguageReference,
  MaritalStatusReference,
  NationalityReference,
  ProfessionReference,
} from '../shared/reference-types';
import { AdministrativeGenderStore } from './administrative-gender.store';
import { CityStore } from './city.store';
import { CountryStore } from './country.store';
import { HealthCareWorkerProfessionStore } from './health-care-worker-profession.store';
import { LanguageStore } from './language.store';
import { MaritalStatusStore } from './maritalstatus.store';
import { NationalityStore } from './nationality.store';
import { ProfessionStore } from './profession.store';
import { MediumStore } from './medium.store';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(
    private apiService: ReferenceApiService,
    private professionStore: ProfessionStore,
    private healthCareWorkerStore: HealthCareWorkerProfessionStore,
    private countryStore: CountryStore,
    private cityStore: CityStore,
    private maritalStatusStore: MaritalStatusStore,
    private nationalityStore: NationalityStore,
    private mediumStore: MediumStore,
    private languageStore: LanguageStore,
    private genderStore: AdministrativeGenderStore
  ) {}

  getProfessionValues(): Observable<ProfessionReference[]> {
    const request$ = this.apiService.getProfessionValues().pipe(tap((response) => this.professionStore.set(response)));

    return cacheable(this.professionStore, request$);
  }

  getHealthCareWorkerProfessionValues(): Observable<HealthCareWorkerProfessionReference[]> {
    const request$ = this.apiService
      .getHealthCareWorkerProfessionValues()
      .pipe(tap((response) => this.healthCareWorkerStore.set(response)));

    return cacheable(this.healthCareWorkerStore, request$);
  }

  getCountryValues(): Observable<CountryReference[]> {
    const request$ = this.apiService.getCountryValues().pipe(tap((response) => this.countryStore.set(response)));

    return cacheable(this.countryStore, request$);
  }

  getCityValues(): Observable<CityReference[]> {
    const request$ = this.apiService.getCityValues().pipe(tap((response) => this.cityStore.set(response)));

    return cacheable(this.cityStore, request$);
  }

  getMaritalStatusValues(): Observable<MaritalStatusReference[]> {
    const request$ = this.apiService
      .getMaritalStatusValues()
      .pipe(tap((response) => this.maritalStatusStore.set(response)));

    return cacheable(this.maritalStatusStore, request$);
  }

  getNationalityValues(): Observable<NationalityReference[]> {
    const request$ = this.apiService
      .getNationalityValues()
      .pipe(tap((response) => this.nationalityStore.set(response)));

    return cacheable(this.nationalityStore, request$);
  }

  getLanguageValues(): Observable<LanguageReference[]> {
    const request$ = this.apiService.getLanguageValues().pipe(tap((response) => this.languageStore.set(response)));

    return cacheable(this.languageStore, request$);
  }

  getAdministrativeGenderValues(): Observable<AdministrativeGenderReference[]> {
    const request$ = this.apiService
      .getAdministrativeGenderValues()
      .pipe(tap((response) => this.genderStore.set(response)));

    return cacheable(this.genderStore, request$);
  }

  getMediumValues(): Observable<MediumReference[]> {
    const request$ = this.apiService.getMediumValues().pipe(tap((response) => this.mediumStore.set(response)));
    return cacheable(this.mediumStore, request$);
  }
}
