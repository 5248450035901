import { HttpClient, HttpHeaders, HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Metadata, RenditionMetadata } from '@nexuzhealth/shared/domain';
import { getHttpParams, timeoutHeaders } from '@nexuzhealth/shared/util';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { retryBackoff, RetryBackoffConfig } from '@nexuzhealth/shared/util';
import { SignedUrlResponse } from '../model/blob.model';

@Injectable({
  providedIn: 'root',
})
export class BlobApiService {
  private readonly baseUrl = 'api/tech/blob/v1';

  constructor(private http: HttpClient) {}

  getBlobMetaData(blobName: string): Observable<Metadata> {
    const url = `${this.baseUrl}/${blobName}`;
    return this.http.get<Metadata>(url, {
      params: { metadata: true },
    });
  }

  getBlob(blobName: string, download = true): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
      })
      .pipe(
        tap((data) => {
          if (download) this.downLoadFile(data, data.headers.get('content-type'));
        })
      );
  }

  getBlobWithRetry(blobName: string, retryConfig: RetryBackoffConfig, download = true): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
      })
      .pipe(
        retryBackoff(retryConfig),
        tap((data) => {
          if (download) this.downLoadFile(data, data.headers.get('content-type'));
        })
      );
  }

  createBlob(file: File) {
    const url = `${this.baseUrl}/signedUrl`;
    return this.http
      .post<SignedUrlResponse>(url, {
        contentType: file.type,
        fileName: file.name,
      })
      .pipe(
        mergeMap(
          (response) => {
            return this.http.put(response.url, file, {
              headers: new HttpHeaders(response.headers),
            });
          },
          (response) => response.blobName
        )
      );
  }

  createBlobWithUploadProgress(file: File) {
    const url = `${this.baseUrl}/signedUrl`;
    return this.http
      .post<SignedUrlResponse>(url, {
        contentType: file.type,
        fileName: file.name,
      })
      .pipe(
        mergeMap(
          (response) => {
            return this.http.put(response.url, file, {
              headers: new HttpHeaders(response.headers),
              reportProgress: true,
              observe: 'events',
            });
          },
          (response, event) => ({
            blobName: response.blobName,
            event: event,
          })
        )
      );
  }

  saveBlob(blobName: string): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe();
  }

  updateBlobRendition(rendition: RenditionMetadata, blob: Blob): Observable<RenditionMetadata> {
    const url = `${this.baseUrl}/${rendition.name}`;
    const params = getHttpParams({
      send_version: rendition.sendVersion,
    });
    const formData = new FormData();
    formData.append('file', blob);
    return this.http.patch<RenditionMetadata>(url, formData, { params });
  }

  private downLoadFile(data: any, type: string) {
    const contentDisposition = data.headers.get('content-disposition');

    let name = '';
    if (contentDisposition !== null) {
      name = contentDisposition.split('=').length >= 2 ? data.headers.get('content-disposition').split('=')[1] : '';
      name = trimByChar(name, '"');
    }

    const url = window.URL.createObjectURL(data.body);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}

function trimByChar(fileName, character) {
  const first = [...fileName].findIndex((char) => char !== character);
  const last = [...fileName].reverse().findIndex((char) => char !== character);
  return fileName.substring(first, fileName.length - last);
}
