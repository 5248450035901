import { isAfter, sub } from 'date-fns';

export interface Certificate {
  name: string;
  sendVersion: string;
  metadataName: string;
  // tenantName or userName is required
  tenantName?: string;
  userName?: string;
  notBefore: string;
  notAfter: string;
}

export type CertificateStatus = 'active' | 'almost-expired' | 'expired' | 'no-cert';

export interface MyCurrentCertificates {
  user?: Certificate;
  organisation?: Certificate;
}

export function calculateCertificateStatus(cert: Certificate): CertificateStatus {
  if (!cert.notAfter && !cert.notBefore) {
    return 'no-cert';
  }
  if (isAfter(new Date(), new Date(cert.notAfter))) {
    return 'expired';
  }

  if (
    isAfter(
      new Date(),
      sub(new Date(cert.notAfter), {
        months: 1,
      })
    )
  ) {
    return 'almost-expired';
  }
  return 'active';
}

export function isActiveCertificate(cert: Certificate): boolean {
  if (!cert?.notAfter && !cert?.notBefore) {
    return false;
  }
  return !isAfter(new Date(), new Date(cert.notAfter));
}
