import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { HealthCareWorkerProfessionReference, ReferenceTypeState } from '../shared/reference-types';
import { HealthCareWorkerProfessionStore } from './health-care-worker-profession.store';

@Injectable({
  providedIn: 'root',
})
export class HealthCareWorkerProfessionQuery extends QueryEntity<
  ReferenceTypeState,
  HealthCareWorkerProfessionReference
> {
  constructor(protected store: HealthCareWorkerProfessionStore) {
    super(store);
  }
}
