import { Directive, Self } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[nxhAutoValidate]',
})
export class AutoValidateDirective {
  submit$ = this.formGroup.ngSubmit;

  constructor(@Self() private formGroup: FormGroupDirective) {}

  getForm() {
    return this.formGroup.form;
  }
}
