import { I18NextPipe } from 'angular-i18next';
import { Error as MoaprError } from '@nexuzhealth/shared/domain';
export const invalidInfoOptions = [
  'combination',
  'lost',
  'stolen',
  'destroyed',
  'expired',
  'dead',
  'old card',
  'cancelled',
  'card with invalid status',
  'undefined',
];

export enum faultValueOptions {
  NO_DATA_FOUND = 'NO_DATA_FOUND',
  NO_RESULT = 'NO_RESULT',
}

export enum faultCodeOptions {
  IDS00011 = 'IDS00011',
  MSG00011 = 'MSG00011',
  MSG00001_1 = 'MSG00001_1',
  MSG00001_2 = 'MSG00001_2',
  MSG00001_3 = 'MSG00001_3',
  MSG00001_4 = 'MSG00001_4',
  MSG00001_5 = 'MSG00001_5',
  MSG00005 = 'MSG00005',
  MSG00006 = 'MSG00006',
  MSG00007 = 'MSG00007',
}

export interface VerifyIdResponse {
  valid: boolean;
  success: boolean;
  statusCode: string;
  info: string;
  fault?: Fault;
}

interface Fault {
  statusCode: string;
  statusMessage: string;
  value: string;
  code: string;
  description: string;
  information: any[];
}

/**
 * maps an errored verrifyId response to an object that we can pass to toast
 */
export function handleVerifyIdError(err: MoaprError, i18next: I18NextPipe): { title: string; body: string } {
  let warningMessageTitle = i18next.transform('_eid._modal._idsupport.fault-title');

  let warningMessageBody: string;
  if (err.message.startsWith('SOA-')) {
    warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-technical%details', {
      details: err.message,
    });
  } else if (err.kind === 'common_person_inss-change-with-user') {
    warningMessageTitle = i18next.transform('_personal._identifier-inss-change-with-user.title');
    warningMessageBody = i18next.transform('_personal._identifier-inss-change-with-user.message', {
      details: err.message,
    });
  } else {
    warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-other');
  }

  return { title: warningMessageTitle, body: warningMessageBody };
}

// maps an invalid verrifyId response to an object that we can pass to toast
export function handleVerifyIdInvalidResponse(
  response: VerifyIdResponse,
  i18next: I18NextPipe
): { title: string; body: string } {
  const warningMessageTitle = i18next.transform('_eid._modal._idsupport.info-title');

  let warningMessageBody;
  if (invalidInfoOptions.includes(response.info.toLowerCase())) {
    warningMessageBody = i18next.transform(
      '_eid._modal._idsupport.info-' + response.info.split(' ').join('-').toLowerCase()
    );
  } else {
    warningMessageBody = i18next.transform('_eid._modal._idsupport.info-unknown%info', {
      info: response.info,
    });
  }

  return { title: warningMessageTitle, body: warningMessageBody };
}

// maps a failed verifyId response to an object that we can pass to toast
export function handleVerifyIdFailureResponse(
  response: VerifyIdResponse,
  i18next: I18NextPipe
): { title: string; body: string } {
  const warningMessageTitle = i18next.transform('_eid._modal._idsupport.fault-title');

  let warningMessageBody;
  if (response.fault?.value === faultValueOptions.NO_DATA_FOUND) {
    warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-no-data-found%code%description', {
      code: response.fault?.code,
      description: response.fault?.description,
    });
  } else if (response.fault?.value === faultValueOptions.NO_RESULT) {
    if (Object.values(faultCodeOptions).includes(response.fault?.code as any)) {
      warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-' + response.fault?.code.toLowerCase());
    } else {
      warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-unknown%code%description', {
        code: response.fault?.code,
        description: response.fault?.description,
      });
    }
  } else if (response.fault?.statusMessage) {
    warningMessageBody = i18next.transform('_eid._modal._idsupport.fault-technical%details', {
      details: response.fault?.statusMessage,
    });
  }

  return { title: warningMessageTitle, body: warningMessageBody };
}
