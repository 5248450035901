<form [formGroup]="form" nhForm #f="nhForm">
  <nxh-card>
    <nxh-card-modal-header (clickedClose)="dismiss()">
      <nxh-card-title [title]="title | i18next"></nxh-card-title>
    </nxh-card-modal-header>
    <nxh-card-body>
      <ng-container [ngSwitch]="state$$ | async">
        <ng-container *ngSwitchCase="'request'">
          <nxh-empty-state [icon]="['far', 'address-card']">
            <div class="empty__description">
              <strong>{{ '_eid._modal.insert-eid' | i18next }}</strong>
            </div>
            <div class="empty__solution">
              {{ '_eid._modal.no-popup' | i18next }}
              <button nxh-button [buttonType]="'link'" (click)="initFromEidRead()">
                {{ '_eid._modal.reopen-popup' | i18next }}
              </button>
            </div>
          </nxh-empty-state>
        </ng-container>

        <ng-container *ngSwitchCase="'form'">
          <nxh-alert
            type="warning"
            [alertTitle]="warningMessageTitle"
            [description]="warningMessageBody"
            *ngIf="idSupportError$$ | async"
          >
          </nxh-alert>
          <nxh-eid-detail *ngIf="eidDetailsView" [view]="eidDetailsView"></nxh-eid-detail>
          <div
            class="search-state search-state--loading"
            *ngIf="(searchState$$ | async) === 'loading' || (idSupportLoading$$ | async); else loaded"
          >
            <fa-icon [icon]="spinnerIcon" [spin]="true" class="status-icon"></fa-icon>
            <span class="search-state--message">{{ '_eid._modal.patient-loading' | i18next }}</span>
          </div>
          <ng-template #loaded>
            <ng-container [ngSwitch]="searchState$$ | async">
              <div>
                <div class="search-state search-state--found" *ngSwitchCase="'found'">
                  <fa-icon [icon]="'check'" class="text-success"></fa-icon>
                  <span class="search-state--message">{{ '_eid._modal.patient-found' | i18next }}</span>
                </div>
                <div class="search-state search-state--unknown" *ngSwitchCase="'unknown'">
                  <fa-icon [icon]="'times'" class="text-danger"></fa-icon>
                  <span class="search-state--message">{{ '_eid._modal.patient-unknown' | i18next }}</span>
                </div>
                <div class="search-state search-state--suggestions" *ngSwitchCase="'suggestions'">
                  <fa-icon [icon]="'times'" class="text-danger"></fa-icon>
                  <span class="search-state--message">{{ '_eid._modal.patient-suggestions' | i18next }}</span>
                </div>
                <ng-container *ngIf="mainPatient$$ | async as patient">
                  <div
                    class="search-state"
                    *ngIf="patient?.name"
                    [ngClass]="{
                      'search-state--found': patient.activeAdministrativeRelation,
                      'search-state--unknown': !patient.activeAdministrativeRelation
                    }"
                  >
                    <fa-icon
                      [icon]="getIcon(patient.activeAdministrativeRelation)"
                      [ngClass]="{
                        'text-success': patient.activeAdministrativeRelation,
                        'text-danger': !patient.activeAdministrativeRelation
                      }"
                    ></fa-icon>
                    <span class="search-state--message">{{
                      (patient.activeAdministrativeRelation ? '_eid._modal.patient-ar' : '_eid._modal.patient-no-ar')
                        | i18next
                    }}</span>
                  </div>
                  <div
                    class="search-state"
                    *ngIf="patient?.name && isAuthorizedForTR()"
                    [ngClass]="{
                      'search-state--found': patient.activeTherapeuticRelation,
                      'search-state--unknown': !patient.activeTherapeuticRelation
                    }"
                  >
                    <fa-icon
                      [icon]="getIcon(patient.activeTherapeuticRelation)"
                      [ngClass]="{
                        'text-success': patient.activeTherapeuticRelation,
                        'text-danger': !patient.activeTherapeuticRelation
                      }"
                    ></fa-icon>
                    <span class="search-state--message">{{
                      (patient.activeTherapeuticRelation ? '_eid._modal.patient-tr' : '_eid._modal.patient-no-tr')
                        | i18next
                    }}</span>
                  </div>
                </ng-container>
                <div
                  *ngIf="(showEHealthTRCheckbox$ | async) && !suggestionSelected"
                  class="search-state search-state--ehealth-tr"
                >
                  <nh-checkbox
                    [label]="'_eid._modal.register-ehealth-tr' | i18next"
                    formControlName="eHealthTr"
                    nxhDataTest="eHealthTr"
                  ></nh-checkbox>
                </div>
              </div>
              <ng-container *ngSwitchCase="'suggestions'">
                <ng-container *ngIf="arePatientSuggestionsVisible()">
                  <hr />
                  <h3 class="suggestions-message">
                    {{ '_eid._modal.patient-suggestions-found' | i18next }}
                  </h3>
                  <div class="mask-on-form">
                    {{ '_eid._modal.patient-suggestions-select-one' | i18next }}
                  </div>
                  <nxh-suggestion-table
                    [patients]="patientSuggestions"
                    [columsToExclude]="getExcludedColumns()"
                    (pickedPatient)="setPatient($event)"
                  >
                  </nxh-suggestion-table>
                </ng-container>
              </ng-container>

              <ng-container>
                <div *ngIf="(showEHealthTRCheckbox$ | async) && suggestionSelected">
                  <div class="search-state search-state--ehealth-tr">
                    <nh-checkbox
                      [label]="'_eid._modal.register-ehealth-tr' | i18next"
                      formControlName="eHealthTr"
                      nxhDataTest="eHealthTr"
                    ></nh-checkbox>
                  </div>
                </div>
                <nxh-eid-form
                  *ngIf="form.get('eidForm')"
                  [intro]="eidFormTitle | i18next"
                  [currentInss]="sourceInss$ | async"
                  formControlName="eidForm"
                >
                  <p ssin-no-match class="text-danger">{{ '_eid._tr.inss-no-match' | i18next }}</p>
                </nxh-eid-form>
              </ng-container>

              <div class="search-state search-state--error" *ngSwitchCase="'error'">
                <fa-icon [icon]="errorIcon" class="text-danger"></fa-icon>
                <span class="search-state--message text-danger">
                  {{ '_eid._modal.patient-error' | i18next }}
                  <button nxh-button [buttonType]="'link'" (click)="retrySearch()">
                    {{ '_eid._modal.patient-error-retry' | i18next }}
                  </button>
                </span>
              </div>
            </ng-container>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'loading'">
          <nxh-page-loader
            [loading]="true"
            [loadingMessage]="'_eid._modal.loading-label' | i18next"
            [debounceTime]="0"
          ></nxh-page-loader>
        </ng-container>

        <ng-container *ngSwitchCase="'error'">
          <nxh-timeout-state (customTimeoutReloadAction)="retryRead()">
            <div class="timeout__title">{{ '_eid._modal.error-loading' | i18next }}</div>
          </nxh-timeout-state>
        </ng-container>
      </ng-container>
    </nxh-card-body>

    <nxh-card-footer>
      <nxh-card-controls left>
        <span
          [ngbTooltip]="'_eid._modal.more-info-relation-tooltip' | i18next"
          *ngIf="this.selectedPatient?.name && !this.selectedPatient.activeTherapeuticRelation && isAuthorizedForTR()"
        >
          <fa-icon icon="info-circle"></fa-icon>
          {{ '_eid._modal.more-info-relation' | i18next }}
        </span>
        <!--       placeholder--might need info field for administrative relation later on -->
        <span
          *ngIf="
            this.selectedPatient?.name && !this.selectedPatient.activeAdministrativeRelation && !isAuthorizedForTR()
          "
        >
        </span>
        <div
          *ngIf="
            (searchState$$ | async) === 'suggestions' &&
            !this.selectedPatient.activeTherapeuticRelation &&
            !this.selectedPatient?.name
          "
        >
          <span>{{ '_eid.patient-still-not-found' | i18next }}</span>
        </div>
      </nxh-card-controls>
      <nxh-card-controls right>
        <div class="buttons">
          <nh-cancel-button (click)="dismiss()" nxhDataTest="dismiss-button"></nh-cancel-button>

          <!-- Just open -->
          <ng-container *ngIf="(state$$ | async) === 'form'">
            <button
              nxh-button
              (click)="openDossier(false, false, f.helper)"
              *ngIf="showOpenDossierButton$ | async"
              buttonStatus="success"
              [disabled]="(searchState$$ | async) === 'loading' || (searchState$$ | async) === 'error'"
              nxhDataTest="open-dossier-button"
            >
              <fa-icon icon="check"></fa-icon>
              <span>{{ '_eid._modal.open-dossier' | i18next }}</span>
            </button>

            <!-- Create tr -->
            <ng-container *ngIf="showCreateTRButton$ | async">
              <nh-submit-button
                *ngIf="(eidSource$$ | async) === false"
                (submit)="createTempPatient(f.helper)"
                type="secondary"
                nxhDataTest="create-temporary-patient-button"
              >
                {{ '_eid._modal.create-tmp-patient' | i18next }}
              </nh-submit-button>

              <nh-submit-button
                (submit)="createTr(f.helper)"
                type="success"
                id="formSubmit"
                nxhDataTest="create-tr-button"
              >
                {{ '_eid._modal.register-patient-record' | i18next }}
              </nh-submit-button>
            </ng-container>

            <!-- Create AR -->
            <ng-container *ngIf="showCreateARButton$ | async">
              <nh-submit-button
                (submit)="createAr(f.helper)"
                type="success"
                id="formSubmit2"
                nxhDataTest="create-ar-button"
              >
                {{ '_eid._modal.register-patient-record' | i18next }}
              </nh-submit-button>
            </ng-container>

            <!-- Create patient -->
            <ng-container *ngIf="showCreateDossierButton$ | async">
              <nh-submit-button
                (submit)="createDossier()"
                type="success"
                nxhDataTest="create-dossier-btn"
                [disabled]="(searchState$$ | async) === 'loading' || (searchState$$ | async) === 'error'"
              >
                {{ '_eid._modal.create-dossier' | i18next }}
              </nh-submit-button>
            </ng-container>
          </ng-container>
        </div>
      </nxh-card-controls>
    </nxh-card-footer>
  </nxh-card>
</form>
