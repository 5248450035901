<li *featureFlag="navItem.featureFlag">
  <ng-container *ngIf="!navItem.target; else browserNav">
    <a class="nav__menus__btn nav-item" [routerLink]="[navItem.url]" routerLinkActive="active">
      {{ navItem.text ? navItem.text : (navItem.label | i18next) }}
    </a>
  </ng-container>
  <ng-template #browserNav>
    <a class="nav__menus__btn nav-item" [href]="navItem.url" [target]="navItem.target" rel="noopener noreferrer">
      {{ navItem.text ? navItem.text : (navItem.label | i18next) }}
    </a>
  </ng-template>
</li>
