<ng-container *ngIf="!hasFunctionalCookie">
  <div class="cookie-bar nh-row">
    <div class="nh-col-10">
      {{ '_cookie-policy.message' | i18next }}
      <a [href]="cookieUrl">{{ 'cookie-link' | i18next }}</a>
    </div>
    <div class="text-right nh-col">
      <button class="btn" (click)="setCookie()" nxhDataTest="close-cookie-bar">
        <fa-icon icon="check"></fa-icon> {{ '_cookie-policy.confim' | i18next }}
      </button>
    </div>
  </div>
</ng-container>
