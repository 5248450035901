import { Component, Input } from '@angular/core';

// cf. https://github.com/NetanelBasal/ngx-content-loader
// create your own: http://danilowoz.com/create-content-loader/
@Component({
  selector: 'nxh-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() rows = 10;
  @Input() primaryColor = '#f3f3f3';
  @Input() secondaryColor = '#ecebeb';
  @Input() view:
    | 'dayplanner-header'
    | 'document-viewer-attachments'
    | 'latest-vital-param'
    | 'patient-nav'
    | 'block'
    | 'avatar'
    | 'calendar';

  constructor() {}

  rand(nbr) {
    return nbr * (Math.random() * (1 - 0.7) + 0.7);
  }
}
