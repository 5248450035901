import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { faIdCardAlt } from '@fortawesome/pro-regular-svg-icons';
import { EidDetailsView, EidPersonInfo, ReasonNoCardReadingType } from '@nexuzhealth/shared/eid/data-access';
import { EnumCategoryQuery, EnumValue } from '@nexuzhealth/shared/reference-data/data-access-enum';
import { eidValidator, isiValidator } from '@nexuzhealth/shared/util';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { orderBy } from 'lodash-es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EidService } from '../../../shared/eid.service';
import { MockEidReaderComponent } from '../../mock-eid-reader/mock-eid-reader.component';

@Component({
  selector: 'nxh-eid-form',
  templateUrl: './eid-form.component.html',
  styleUrls: ['./eid-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EidFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EidFormComponent),
      multi: true,
    },
    EidService,
  ],
})
export class EidFormComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy, Validator {
  /**
   * Allow manual entry of eid values.
   * @default true
   */
  @Input() manual = true;

  /**
   * Current inss to compared to. Null when no inss is known
   * @default null
   */
  @Input() currentInss: string | null = null;

  /**
   * Text to display above button.
   */
  @Input() intro: string;

  private destroy$: Subject<void> = new Subject<void>();
  readonly idCardIcon = faIdCardAlt;
  form: UntypedFormGroup;
  reasonOptions$: Observable<EnumValue[]>;
  readonly eidStateSubj = new BehaviorSubject<'loading' | 'error' | 'loaded'>(null);

  /**
   * Latest data read from eID
   */
  readonly detailViewSubj = new BehaviorSubject<EidDetailsView>(null);

  /**
   * get the translated value for the eid or isi+ required message. This is not trivial, so shouldnt
   * be done in the template.
   */
  get eidOrIsiErrorMessage(): string {
    const eid = this.i18n.transform('eid-number');
    const isi = this.i18n.transform('isi-plus-number');
    const or = this.i18n.transform('or');
    return this.i18n.transform('errors.required', {
      label: `${eid} ${or} ${isi}`,
    });
  }

  onChange: (_: EidOption) => void;
  onTouched: any;

  constructor(
    private service: EidService,
    private enumQuery: EnumCategoryQuery,
    private i18n: I18NextPipe,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        eid: new UntypedFormControl('', { validators: eidValidator }),
        isi: new UntypedFormControl('', { validators: isiValidator }),
        reasonNoCardReading: new UntypedFormControl('', { validators: Validators.required }),
      },
      { validators: [validateEidOrIsi] }
    );

    const hasIsi$ = this.form.get('isi').valueChanges.pipe(
      startWith(this.form.get('isi').value),
      map((isi) => !!isi)
    );

    this.reasonOptions$ = hasIsi$.pipe(
      switchMap((hasIsi) =>
        hasIsi
          ? of([
              {
                name: ReasonNoCardReadingType.ISI,
                description: this.i18n.transform('isi+'),
              },
            ])
          : this.service.getNoCardReadingReasons().pipe(
              map((reasons) => {
                let translated = reasons.map((reason) => ({
                  name: reason,
                  description: this.i18n.transform('ehealth_refrepo_reason-no-card-reading.' + reason),
                }));
                translated = orderBy(translated, [(reason) => reason.description.toLowerCase()], ['asc']);
                return translated;
              })
            )
      )
    );

    hasIsi$.subscribe((hasIsi) => {
      const reasonNoCardReading = this.form.get('reasonNoCardReading');
      if (hasIsi) {
        reasonNoCardReading.disable();
        reasonNoCardReading.setValue(ReasonNoCardReadingType.ISI);
      } else {
        reasonNoCardReading.enable();
        reasonNoCardReading.reset();
      }
    });

    this.form
      .get('reasonNoCardReading')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (reason) => {
          if (reason === ReasonNoCardReadingType.OTHER) {
            this.form.addControl('reasonFreeText', new UntypedFormControl('', Validators.required));
          } else {
            this.form.removeControl('reasonFreeText');
          }
        },
      });
  }

  public markAsTouched() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.form.getRawValue())
      )
      .subscribe((value: any) => {
        const identification = {
          eid: { value: value.eid?.toUpperCase(), disabled: this.form.get('eid').disabled },
          isi: { value: value.isi, disabled: this.form.get('isi').disabled },
          reasonNoCardReading: {
            value: value.reasonNoCardReading,
            disabled: this.form.get('reasonNoCardReading').disabled,
          },
          reasonFreeText: this.form.get('reasonFreeText')?.value,
        };
        this.onChange?.(identification);
      });
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.form.invalid) {
      return { invalidForm: { valid: false, message: 'invalid-form' } };
    }
    return null;
  }

  writeValue(option: EidOption): void {
    this.form?.patchValue({
      eid: option.eid?.value,
      isi: option.isi?.value,
    });
    Object.keys(option).forEach((key) => {
      if (option[key]?.disabled) {
        this.form.get(key).disable();
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  openEidPopup(useMockEidReader = false) {
    this.eidStateSubj.next('loading');

    let retrieveEidData$: Observable<EidDetailsView>;
    if (useMockEidReader) {
      retrieveEidData$ = from(this.modalService.open(MockEidReaderComponent, { size: 'lg' }).result).pipe(
        map((info: EidPersonInfo) => {
          const userInfo = this.service.mapToUserInfo(info);
          return this.service.getEidDetailsView(userInfo);
        })
      );
    } else {
      retrieveEidData$ = this.service.retrieveEIDData().pipe(map((data) => data.view));
    }

    retrieveEidData$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (detailView) => {
        if (detailView) {
          this.eidStateSubj.next('loaded');
          this.detailViewSubj.next(detailView);
          if (this.ssinMatch(detailView)) {
            this.form.patchValue({
              eid: detailView?.userInfo?.cardnumber ?? '',
              isi: '',
              reasonNoCardReading: ReasonNoCardReadingType.READ_EID,
            });
            this.setDisabledState(true);
          }
        } else {
          this.eidStateSubj.next(null);
        }
      },
      error: (_) => {
        this.eidStateSubj.next('error');
      },
      complete: () => {
        if (this.eidStateSubj.getValue() === 'loading') {
          // TODO: can this even happen?
          this.eidStateSubj.next(null);
        }
      },
    });
  }

  ssinMatch(view: EidDetailsView): boolean {
    return this.currentInss === view?.userInfo?.sub;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface EidOption {
  eid: EidOptionValue;
  isi: EidOptionValue;
  reasonNoCardReading?: EidOptionValue;
}

type EidOptionValue = { value: string; disabled?: boolean };

function validateEidOrIsi(control: AbstractControl): ValidationErrors {
  console.assert(control instanceof UntypedFormGroup, 'invalid use of validator, should be on group');

  const group = control as UntypedFormGroup;
  const reasonNoCardReading = group.get('reasonNoCardReading');

  // also includes falsy values because we don't want to show the eid-or-isi required error if no
  // reason is selected
  const valuesNoEidIsiRequired = [
    ReasonNoCardReadingType.BABY,
    ReasonNoCardReadingType.GMF_HOLDER,
    ReasonNoCardReadingType.DEV_TESTING,
    ReasonNoCardReadingType.OTHER,
    '',
    null,
    undefined,
  ];
  if (valuesNoEidIsiRequired.includes(reasonNoCardReading.value)) {
    return null;
  }

  const isi = group.get('isi') as unknown as UntypedFormControl;
  const eid = group.get('eid') as unknown as UntypedFormControl;

  // if neither has been touched yet, we don't want to show the error. Our form submit makes these
  // touched, so we defer the error to then.
  if (!isi.touched && !eid.touched) {
    return null;
  }

  const isOk = (ctrl: UntypedFormControl) => {
    // we don't want to show the eid-or-isi-required error if either the isi+ or eid+ has an error
    // so we don't show more than 1 error.
    if (ctrl.invalid) {
      return true;
    }

    const value = ctrl.value;
    if (typeof value !== 'string') {
      return false;
    }
    return value !== '';
  };
  if (isOk(isi) || isOk(eid)) {
    return null;
  }

  return {
    'eid-or-isi-required': true,
  };
}
