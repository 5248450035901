import { Observable } from 'rxjs';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { RoleExpr } from '@nexuzhealth/shared/domain';
import { map } from 'rxjs/operators';
import { AuthQuery } from '../state/auth-query.service';
import { isAuthorized } from './authorization-utils';

export abstract class AuthService {
  constructor(protected authQuery: AuthQuery) {}

  abstract readonly isAuthenticated$: Observable<boolean>;

  abstract login(redirectPath?: string): void;

  abstract switchUserContext(userContextName: string): void;

  abstract logout(onlyLocal?: boolean, reason?: string): void;

  abstract refreshToken(): void;

  abstract getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string>;

  selectAuthorized(roleExpr: RoleExpr) {
    return this.authQuery.allRoles$.pipe(map((roles) => isAuthorized(roleExpr, roles)));
  }

  isAuthorized(roleExpr?: RoleExpr) {
    return isAuthorized(roleExpr, this.authQuery.getAllRoles());
  }
}
