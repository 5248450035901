import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/**
 * Outputs some debug information together with a message
 */
export const debug = <T, R>(message: string, projectionFunction?: (arg0: T) => R) => {
  return tap({
    next: (arg: T) => {
      const projected = projectionFunction ? projectionFunction(arg) : arg;
      if (message) {
        console.log('next: ' + message, projected);
      } else {
        console.log(projected);
      }
    },
    error: (err: unknown) => console.error('error: ' + message, err),
    complete: () => console.log('complete: ' + message),
  });
};

// shamelessly copied from https://github.com/datorama/akita/blob/master/akita/src/filterNil.ts so as to avoid
// dependency on akita for non-akita related components
type Diff<T, U> = T extends U ? never : T;
export const filterNullOrUndefined = <T>(source: Observable<T | undefined | null>) =>
  source.pipe(filter((value): value is Diff<T, null | undefined> => value !== null && typeof value !== 'undefined'));

type ToastOptions = {
  title?: string;
  message: string;
};
type ToastOptionsFunction<T> = (value: T) => ToastOptions;

export interface ShowToastrOptions<T, E = any> {
  success?: ToastOptions | ToastOptionsFunction<T>;
  error?: ToastOptions | ToastOptionsFunction<E>;
  toastr: ToastrService;
  i18next: I18NextPipe;
}

export const showToastr = <T, E = unknown>({ success, error, toastr, i18next }: ShowToastrOptions<T, E>) =>
  tap<T>({
    next: (val) => {
      if (success) {
        if (typeof success === 'function') {
          const successResult = success(val);
          const message = i18next.transform(successResult.message);
          if (successResult.title) {
            const title = i18next.transform(successResult.title);
            toastr.success(message, title);
            return;
          }
          toastr.success(message);
          return;
        } else {
          const message = i18next.transform(success.message);
          if (success.title) {
            const title = i18next.transform(success.title);
            toastr.success(message, title);
            return;
          }
          toastr.success(message);
        }
      }
    },
    error: (err: E) => {
      if (error) {
        if (typeof error === 'function') {
          const errorResult = error(err);
          const message = i18next.transform(errorResult.message);
          if (errorResult.title) {
            const title = i18next.transform(errorResult.title);
            toastr.error(message, title);
            return;
          }
          toastr.error(message);
        } else {
          const message = i18next.transform(error.message);
          if (error.title) {
            const title = i18next.transform(error.title);
            toastr.error(message, title);
            return;
          }
          toastr.error(message);
        }
      }
    },
  });
