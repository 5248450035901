import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Inject, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryQuery, CountryReference } from '@nexuzhealth/shared/reference-data/data-access';
import { I18NextPipe } from 'angular-i18next';
import { SelectReferenceDataBaseComponent } from '../shared/select-reference-data-base.component';

@Component({
  selector: 'nxh-select-country',
  templateUrl: '../shared/select-reference-data-base.component.html',
  styleUrls: ['../shared/select-reference-data-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCountryComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCountryComponent extends SelectReferenceDataBaseComponent<CountryReference> {
  @Input() clearable = true;
  @Input() virtualScroll = true;

  constructor(query: CountryQuery, i18next: I18NextPipe) {
    super(query, i18next);
  }
}
