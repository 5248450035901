import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, GetTokenSilentlyOptions, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormStore } from '@nexuzhealth/shared/ui-toolkit/dirty-check/data-access';
import { LogService } from '@nexuzhealth/shared/util';
import { DEFAULT_INTERRUPTSOURCES, Idle, IdleExpiry } from '@ng-idle/core';
import {
  BehaviorSubject,
  defer,
  EMPTY,
  from,
  iif,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ThemeQuery } from '@nexuzhealth/shared/toolkit/feature-theming';
import { AuthQuery } from '../state/auth-query.service';
import { EventChannelService } from './eventChannel.service';
import { SessionService } from './abstract-session.service';
import { AuthService } from './abstract-auth.service';

@Injectable()
export class MoaprAuthService extends AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private isLoadingSubject$ = new BehaviorSubject<boolean>(true);
  private errorSubject$ = new ReplaySubject<Error>(1);
  private refreshState$ = new Subject<void>();
  private isAuthenticatedTrigger$: Observable<any>;
  private auth0Client: Auth0Client;
  readonly error$: Observable<Error> = this.errorSubject$.asObservable();
  private readonly isLoading$ = this.isLoadingSubject$.asObservable();

  readonly isAuthenticated$: Observable<boolean>;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private settings: SettingsService,
    private logService: LogService,
    protected authQuery: AuthQuery,
    private eventChannel: EventChannelService,
    private idle: Idle,
    private idleExpiry: IdleExpiry,
    private formStore: FormStore,
    private location: Location,
    private themeQuery: ThemeQuery
  ) {
    super(authQuery);
    this.auth0Client = new Auth0Client({
      domain: this.settings.auth0config.domain,
      client_id: this.settings.auth0config.clientId,
      redirect_uri: `${window.location.origin}/callback`,
      audience: this.settings.auth0config.audience,
      useRefreshTokens: true,
      cacheLocation: 'memory',
    });
    this.isAuthenticatedTrigger$ = this.isLoading$.pipe(
      filter((loading) => !loading),
      distinctUntilChanged(),
      switchMap(() =>
        // To track the value of isAuthenticated over time, we need to merge:
        //  - the current value
        //  - the value whenever refreshState$ emits
        merge(
          defer(() => this.auth0Client.isAuthenticated()),
          this.refreshState$.pipe(mergeMap(() => this.auth0Client.isAuthenticated()))
        )
      ),
      map((authenticated) => authenticated && this.authQuery.getUserContextName())
    );

    this.isAuthenticated$ = this.isAuthenticatedTrigger$.pipe(distinctUntilChanged());

    if (this.idleExpiry.isExpired()) {
      this.logout();
    } else {
      this.localAuthSetup();
      this.setupListeners();
    }
  }

  login(redirectPath: string = '/') {
    if (!this.authQuery.getUserContextName()) {
      this.sessionService.setRedirectAfterLogin(redirectPath);
      this.logout(true);
      return;
    }

    from(
      this.auth0Client.loginWithRedirect({
        appState: { target: redirectPath },
        prompt: 'none',
      })
    ).subscribe();
  }

  switchUserContext(userContextName: string) {
    if (this.authQuery.getUserContextName() !== userContextName) {
      this.useUserContextName(userContextName);
      window.location.assign('/home');
    }
  }

  logout(onlyLocal = false, reason?: string) {
    this.idle.stop();
    this.formStore.destroy();
    this.logService.debug('[AuthService] logout');
    this.auth0Client.logout({ localOnly: true });
    this.sessionService.clear();

    const client = this.themeQuery.getValue().isDefault
      ? new URL(window.location.href).searchParams.get('client')
      : this.themeQuery.getValue().shortCode;
    const clientParam = client ? `&client=${client}` : '';

    if (onlyLocal) {
      const reasonParam = reason ? `&reason=${reason}` : '';
      window.location.assign(
        `${this.settings.loginEndpoint}?app=${encodeURIComponent(window.location.origin)}${reasonParam}${clientParam}`
      );
    } else {
      window.location.assign(
        `${this.settings.logOutEndpoint}?logout=${encodeURIComponent(window.location.origin)}${clientParam}`
      );
    }
  }

  refreshToken() {
    this.refreshState$.next();
  }

  getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
    return of(this.auth0Client).pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      tap(() => this.refreshToken()),
      catchError((error) => {
        this.errorSubject$.next(error);
        this.refreshToken();
        return throwError(error);
      })
    );
  }

  private localAuthSetup() {
    const path = this.location.path();
    const userContextName = this.authQuery.getUserContextName();
    if (!path.includes('/login') && path.includes('userContextName=')) {
      const urlTree = this.router.parseUrl(path);
      const newUserContextName = urlTree.queryParamMap.get('userContextName');
      if (newUserContextName && newUserContextName !== userContextName) {
        this.useUserContextName(newUserContextName);
      }
      delete urlTree.queryParams['userContextName'];
      this.router.navigateByUrl(urlTree, { replaceUrl: true });
    }

    const checkSessionOrCallback$ = (isCallback: boolean) =>
      iif(
        () => isCallback,
        this.handleRedirectCallback(),
        defer(() => {
          return this.auth0Client.checkSession();
        })
      );

    this.shouldHandleCallback()
      .pipe(
        switchMap((isCallback) =>
          checkSessionOrCallback$(isCallback).pipe(
            catchError(() => {
              this.logout(true);
              return EMPTY;
            })
          )
        ),
        switchMap(() => this.auth0Client.isAuthenticated()),
        switchMap((authenticated) => (authenticated ? this.sessionService.retrieveUserInformation() : of(undefined))),
        switchMap((authenticated) =>
          authenticated ? this.sessionService.retrieveOrganisationInformation() : of(undefined)
        ),
        tap(() => {
          this.isLoadingSubject$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (user) => {
          if (user) {
            this.startIdleTimeout();
          }
        },
        error: (e) => {
          this.isLoadingSubject$.next(false);
          if (e.kind === 'iam_authentication_invalid-licence')
            this.router.navigate(['/fatal-error'], { queryParams: { 'fatal-error': 'invalid-licence' } });
          else this.router.navigate(['/fatal-error']);
        },
      });
  }

  private shouldHandleCallback(): Observable<boolean> {
    return of(this.location.path()).pipe(
      map((search) => {
        return (
          search.includes('/callback') &&
          (search.includes('code=') || search.includes('error=')) &&
          search.includes('state=')
        );
      })
    );
  }

  private handleRedirectCallback(): Observable<RedirectLoginResult> {
    return defer(() => this.auth0Client.handleRedirectCallback()).pipe(
      tap((result) => {
        const target = result?.appState?.target ?? '/';
        const urlTree = this.router.parseUrl(target);
        this.router.navigateByUrl(urlTree);
      })
    );
  }

  private useUserContextName(userContextName) {
    this.sessionService.useUserContextName(userContextName);
    this.eventChannel.sendUserContextSwitch();
  }

  private setupListeners() {
    this.eventChannel.logout$.subscribe(() => {
      this.logout(true, 'session_expired');
    });
    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });
    this.eventChannel.switchUserContext$.subscribe(() => {
      window.location.assign('/home');
    });
  }

  private startIdleTimeout() {
    if (this.settings.idleTimeout.enabled !== true) {
      return;
    }
    this.idle.setIdle(this.settings.idleTimeout.idleAfter);
    this.idle.setTimeout(this.settings.idleTimeout.timeoutAfter);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.watch();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.isLoadingSubject$.complete();
    this.errorSubject$.complete();
    this.refreshState$.complete();
  }
}
