import { Component, OnInit } from '@angular/core';
import { EventChannelService, SessionService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { SettingsService } from '@nexuzhealth/shared/settings/data-access-settings';
import { Idle } from '@ng-idle/core';

/**
 * After logout,
 * the LoginPortal will open this route in an iframe to force a logout in all open tabs.
 * We do this by sending a message to all tabs with the command to logout and confirm the logout back to the LoginPortal.
 * Because of the use of refresh tokens, open tabs and other applications don't know if a logout was initiated.
 */
@Component({
  template: ``,
})
export class SingleLogoutComponent implements OnInit {
  constructor(
    private session: SessionService,
    private eventChannel: EventChannelService,
    private settings: SettingsService,
    private idle: Idle
  ) {}

  ngOnInit() {
    this.idle.stop();
    this.session.clear();
    this.eventChannel.sendLogout();

    if (window.parent) {
      window.parent.postMessage({ app: this.settings.application }, this.settings.loginEndpoint);
    }
  }
}
