import { Injectable } from '@angular/core';
import { UserApiService } from '../api/user-api.service';
import { AuthQuery } from '../state/auth-query.service';
import { AuthStore } from '../state/auth-store.service';
import { SessionService } from './abstract-session.service';

@Injectable()
export class MoaprSessionService extends SessionService {
  constructor(protected userApi: UserApiService, protected authStore: AuthStore, protected authQuery: AuthQuery) {
    super(authStore, userApi, authQuery);
  }

  clear() {
    this.authStore.logout();
  }

  setRedirectAfterLogin(path = '/') {
    const pathWithoutClientQueryParam = path.replace(/[?|&]client=(\w+-?)*/g, '');
    sessionStorage.setItem('nh.auth.redirectTo', pathWithoutClientQueryParam);
  }

  getAndClearRedirectAfterLogin(): string {
    const redirectTo = sessionStorage.getItem('nh.auth.redirectTo');
    sessionStorage.removeItem('nh.auth.redirectTo');
    return redirectTo || '/';
  }
}
